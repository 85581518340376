import React, { FunctionComponent, useState } from 'react';
import { Col, Select, Typography } from 'antd';
import { SelectDropdownOption } from '../../../components/propertyEditors/util/DropdownUtil';

const { Option, OptGroup } = Select;

export interface SelectDropdownProps {
  label: string;
  placeholder: string;
  dataKey: string;
  handleChange: (key: string, value: string) => void;
  disabled?: boolean;
  options: SelectDropdownOption[];
  value?: SelectDropdownOption;
}

export const SelectDropdownEditor: FunctionComponent<SelectDropdownProps> = ({
  label,
  placeholder,
  dataKey,
  handleChange,
  disabled = false,
  value,
  options,
}) => {
  const { Text } = Typography;

  return (
    <div>
      {label && <label className="stringEditor">{label}</label>}
      <Select
        placeholder={placeholder}
        style={{ width: '100%', height: '32px' }}
        onChange={(input) => handleChange(dataKey, input)}
        value={value?.value}
      >
        <OptGroup>
          {options.map((option) => (
            <Option
              title="select-dependent"
              key={option.key}
              value={option.value}
              disabled={disabled}
            >
              {option.key} <Text type="secondary"> » {option.value}</Text>
            </Option>
          ))}
        </OptGroup>{' '}
      </Select>
    </div>
  );
};

export default SelectDropdownEditor;
