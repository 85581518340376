import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { WarningOutlined } from '@ant-design/icons';
import { CanvasContext } from '../canvasContext';
import { Popconfirm, Button, Checkbox, Popover } from 'antd';
import '../styles/ComponentPropertyEditor.css';
import { removeUploadPicture } from '../api/index';
import { DependencyProps } from '../core/IDependency';
import { IStrings } from '../core/IStrings';

export interface DeleteIconProps {
  id: string;
  parentId?: string;
  hasChildren: boolean;
  hasDependencies: boolean;
  activeField?: any;
}

export const DeleteIcon: FunctionComponent<DeleteIconProps> = ({
  id,
  hasChildren,
  hasDependencies = false, //set default value to false
  activeField,
}) => {
  const {
    removeField,
    deleteDependencyByID,
    dependenciesOfActivePage,
    editDependency,
  } = useContext(CanvasContext);

  const [removeDependencies, setRemoveDependencies] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const confirm = () => {
    removePictureFromBackend(activeField);
    if (removeDependencies === true) {
      dependenciesOfActivePage &&
        deleteDependenciesOfField(dependenciesOfActivePage);
    } else {
      dependenciesOfActivePage &&
        removeDependentFromDependency(dependenciesOfActivePage);
    }
    removeField(id);
  };

  function deleteDependenciesOfField(dependencies: DependencyProps[]) {
    let dependenciesToDelete = findDependenciesOfField(id, dependencies);
    dependenciesToDelete &&
      dependenciesToDelete.forEach((dependency) =>
        deleteDependencyByID(dependency.dependencyId)
      );
  }

  function removeDependentFromDependency(dependencies: DependencyProps[]) {
    let dependenciesToRemoveDependent = findDependenciesOfField(
      id,
      dependencies
    );

    dependenciesToRemoveDependent &&
      dependenciesToRemoveDependent.forEach((dependency) => {
        editDependency(
          dependency.dependencyId,
          dependency.superiorId,
          'dependentId',
          ''
        );
      });
  }

  function findDependenciesOfField(
    fieldId: string,
    dependencies: DependencyProps[]
  ): DependencyProps[] | undefined {
    return dependencies.filter(
      (dependency) => dependency.dependentId === fieldId
    );
  }

  function toggleCheckBox() {
    setRemoveDependencies(!removeDependencies);
  }

  const handleOk = () => {
    setIsModalVisible(false);
    confirm();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleVisibleChange = () => {
    setIsModalVisible(!isModalVisible);
  };

  useEffect(() => {
    setRemoveDependencies(false);
  }, [activeField]);

  const popoverContent = (
    <>
      <div
        style={{ display: 'grid', textAlign: 'center', marginBottom: '10px' }}
      >
        <Checkbox
          onChange={() => toggleCheckBox()}
          checked={removeDependencies}
        >
          {' '}
          Remove dependencies of Field
        </Checkbox>
      </div>
      <div style={{ display: 'block', textAlign: 'center' }}>
        <Button className="deleteButton" onClick={() => handleCancel()}>
          <span className="deleteButtonSpan">{IStrings.Cancel}</span>
        </Button>
        <Button className="deleteButton" onClick={() => handleOk()}>
          <span className="deleteButtonSpan">{IStrings.Delete}</span>
        </Button>
      </div>
    </>
  );

  return hasChildren ? (
    <Popconfirm
      title={IStrings.msg_popup_delete_container}
      onConfirm={confirm}
      icon={<WarningOutlined style={{ color: 'red' }} />}
      okText={IStrings.Delete}
      cancelText={IStrings.Cancel}
    >
      <Button className="deleteButton" type="default">
        <span className="deleteButtonSpan">{IStrings.Delete}</span>
      </Button>
    </Popconfirm>
  ) : hasDependencies ? (
    <>
      <Popover
        visible={isModalVisible}
        content={popoverContent}
        trigger="click"
        title={
          <>
            <WarningOutlined style={{ color: 'red', marginRight: '5px' }} />{' '}
            {IStrings.Delete_Text}
          </>
        }
        onVisibleChange={() => {
          handleVisibleChange();
        }}
      >
        <Button className="deleteButton" disabled={isModalVisible}>
          <span className="deleteButtonSpan">{IStrings.Delete}</span>
        </Button>
      </Popover>
    </>
  ) : (
    <Popconfirm
      title={IStrings.msg_popup_delete_element}
      onConfirm={confirm}
      icon={<WarningOutlined style={{ color: 'red' }} />}
      okText={IStrings.Delete}
      cancelText={IStrings.Cancel}
    >
      <Button className="deleteButton" type="default">
        <span className="deleteButtonSpan">{IStrings.Delete}</span>
      </Button>
    </Popconfirm>
  );
};

export const removePictureFromBackend = (field: any) => {
  // if picture is been uploaded and component is deleted
  // then this will delete picture file from backend
  if (field.title === 'CONTAINER') {
    if (field.elements) {
      field.elements.forEach((item: any) => {
        if (item.title === 'PICTURE') {
          removePicture(item);
        }
      });
    }
  } else if (field.title === 'PICTURE') {
    removePicture(field);
  }
};

const removePicture = (activeField: any) => {
  if (activeField.propConfig[0].value) {
    let newValue = JSON.parse(activeField.propConfig[0].value);
    let imageUrl = `${process.env.REACT_APP_BACKEND_URL}/${newValue[0].name}`;
    imageExists(imageUrl, function (exists: any) {
      if (exists) {
        removeUploadPicture(newValue[0].name);
      }
    });
  }
};

function imageExists(url: any, callback: any) {
  var img = new Image();
  img.onload = function () {
    callback(true);
  };
  img.onerror = function () {
    callback(false);
  };
  img.src = url;
}

export default DeleteIcon;
