import { Col, Row } from 'antd';
import React, { FunctionComponent, useContext } from 'react';
import { CanvasContext } from '../canvasContext';
import { DependenciesModeContext } from '../core/dependenicesModeContext';
import DraggableWrapper from '../core/draggableWrapper';
import DropableAreaVertical from '../core/dropableAreaVertical';
import { DragItemProps } from '../core/IDragItem';
import { ItemTypes } from '../core/itemTypes';

export const GridRow: FunctionComponent<DragItemProps> = ({ ...props }) => {
  const { activeField, setActiveField } = useContext(CanvasContext);
  const { dependenciesMode } = useContext(DependenciesModeContext);
  const { children, ...row } = props;
  function handleClick(e: any) {
    setActiveField(row);
    e.stopPropagation();
  }

  return (
    <DraggableWrapper {...row}>
      <Row
        onClick={handleClick}
        align="middle"
        justify="center"
        style={{
          backgroundColor: row.id === activeField?.id ? '#93F100' : '#ffffff',
        }}
      >
        <Col span={2}>
          {dependenciesMode === true ? (
            <></>
          ) : (
            <DropableAreaVertical
              parentId={row.id}
              index={0}
              acceptedItemTypes={[ItemTypes.ITEM]}
            />
          )}
        </Col>

        {children !== 0 && children}
      </Row>
    </DraggableWrapper>
  );
};

export default GridRow;
