export let ClxStrings = {
  en: {
    LogIn: 'Login',
    LogOut: 'Log Out',
    Drop_Option_here: 'Drop option here',
    value: 'value',
    optionpaneOption: 'Optionpane option',
    Add_Options_Success: ' has been added to options',
    Add_Options_Success_Multiple: 'Roles have been added to options',
    Add_Options_Failure: 'Error trying to add new option',
    input_writable: 'Input field writable',
    space_Distribution: 'Space Distribution',
    help_placeholder: 'A short info message displayed below the input',
    helpDetails_placeholder:
      'An longer help text which is shown as separate PopUp for the form field',
    Text_Block: 'Textblock',
    hour: 'Hour',
    year: 'Year',
    email: 'Email',
    iban: 'IBAN',
    daterange: 'Daterange',
    target: 'Target',
    row_label_hint: 'label is only shown for rows with more than one element',
    Space_Distribution: 'Space Distribution',
    crealogix: 'Crealogix',
    plain: 'Plain',
    markdown: 'Markdown',
    form: 'Form',
    accordion: 'Accordion',
    date: 'Date',
    number: 'Number',
    text: 'Text',
    textarea: 'Text Area',
    output: 'Output',
    checkbox: 'Checkbox',
    toggleswitch: 'Toggleswitch',
    count: 'Count',
    amount: 'Amount',
    select: 'Select',
    multiselect: 'Multiselect',
    checkboxgroup: 'Checkboxgroup',
    autocomplete: 'Autocomplete',
    radiogroup: 'Radiogroup',
    slider: 'Slider',
    row: 'Row',
    link: 'Link',
    fieldset: 'Fieldset',
    optionpane: 'Optionpane',
    textblock: 'Textblock',
    collapsible: 'Collapsible',
    conditional: 'Conditional',
    list: 'List',
    help: 'Help',
    helpDetails: 'Help details',
    useInfoIcon: 'use info icon',
    confidential: 'Confidential',
    confidentialCharactersCount: 'Number of confidential characters',
    className: 'Html classname',
    placeholder: 'Placeholder',
    displayIf: 'Display if',
    visibleFor: 'Visible for',
    hiddenFor: 'Hidden for',
    disabledFor: 'Disabled for',
    editableFor: 'Editable for',
    validateIf: 'Validate if',
    disabled: 'Disabled',
    readOnly: 'Read only',
    refExternalData: 'Reference External Data',
    showInput: 'Show input',
    valueFormat: 'Value format',
    step: 'Step',
    scale: 'Scale',
    validation: 'Validation',
    backendValidation: 'backend Validation',
    useTime: 'use Time',
    unit: 'Unit',
    decimalPlaces: 'Decimal Places',
    expression: 'Expression',
    format: 'Format',
    minLength: 'Min length',
    maxLength: 'Max length',
    checkboxLabel: 'Checkbox label',
    min: 'Min',
    max: 'Max',
    writable: 'Wriatable',
    currencyOptions: 'Currency options',
    options: 'Options',
    backendOptionsType: 'Backend options type',
    backendSuggestionsType: 'Backend suggestion type',
    maxSuggestions: 'Max suggestion',
    minQueryLength: 'Min query length',
    horizontal: 'Horizontal',
    fields: 'Fields',
    spaceDistribution: 'Space distribution',
    sameWindow: 'Same Window',
    type: 'Type',
    titleExpression: 'Title Expression',
    titleFormat: 'Title Format',
    initiallyOpened: 'Initally opened',
    listLabel: 'List label',
    entryLabel: 'Entry label',
    removable: 'Removable',
    extensible: 'Extensible',
    defaultValue: 'Default value',
    Conditional_Dropdown_Menu: 'Conditional Dropdown Menu',
    Superior_Label: 'superior dropdown label',
    Superior_FieldName: 'superior dropdown fieldname',
    Dependent_Label: 'dependent dropdown label',
    Dependent_FieldName: 'dependent dropdown fieldname',
    multiline: 'multiline input',
    Page_content_is_shown_in_summary: 'Page content is shown in summary',
    Thank_You_DefaultValue: 'Thank you! We received your data.',
    Left: 'Left',
    Center: 'Center',
    Right: 'Right',
    S: 'S',
    M: 'M',
    L: 'L',
    Mr: 'Mr',
    Ms: 'Ms',
    other: 'other',
    'no specification': 'no specification',
    Input_Mark: 'Input Mark',
    Select_Unit: 'Select Unit',
    Select_Type: 'Select Type',
    Input_Step: 'Input Step',
    Page: 'Page',
    Option_Label: 'Option label',
    Option_Value: 'Option value',
    Option_Info: 'Option info',
    Input_Min_Value: 'Input Min Value',
    Input_Max_Value: 'Input Max Value',
    Subheader: 'Subheader',
    Empty_Page: 'This page is empty. Add Fields before creating dependencies.',
    Label: 'Label',
    Choose_Project: 'Choose an existing project or create a new project',
    Last_modified: 'Last Modified',
    Actions: 'Actions',
    Edit: 'Edit',
    Delete: 'Delete',
    Duplicate: 'Duplicate',
    NewForm: 'Create new Form',
    Description: 'Description',
    Name: 'Name',
    Return: 'Return',
    Save: 'Save',
    Download: 'Download',
    Components: 'Components',
    Layout: 'Layout',
    Predefined: 'Predefined',
    Input: 'Input',
    Confirmation: 'Confirmation',
    Container: 'Container',
    Text_Area: 'Text Area',
    Header: 'Header',
    Salutation: 'Salutation',
    Firstname: 'Firstname',
    Lastname: 'Lastname',
    Place_Of_Birth: 'Place Of Birth',
    Birthday: 'Birthday',
    Street: 'Street',
    House_Number: 'House Number',
    Postal_Code: 'Postal Code',
    Town: 'Town',
    Email: 'Email',
    Phone: 'Phone',
    Picture: 'Picture',
    Currency: 'Currency',
    Calendar: 'Calendar',
    CalendarFormat: 'Format',
    Slider: 'Slider',
    IBAN: 'IBAN',
    IbanWithBicAndBankname: 'IBAN with BIC and bankname',
    YearDropdown: 'Year dropdown',
    YearAmount: 'Total years',
    YearShowPre: 'Show "pre year x" option',
    Radio_Button: 'Radio button',
    Radio_Buttons: 'Radio buttons',
    CheckboxGroup: 'CheckboxGroup',
    Dropdown_Menu: 'Dropdown Menu',
    CountField: 'CountField',
    Checkbox: 'Checkbox',
    CheckboxLabel: 'Checkbox label',
    ToggleSwitch: 'ToggleSwitch',
    FileUpload: 'FileUpload',
    JPEG: 'JPEG',
    PNG: 'PNG',
    BMP: 'BMP',
    PDF: 'PDF',
    AGB: 'T&C',
    Summary: 'Summary',
    Thank_You: 'Thank You',
    PDF_Download: 'PDF-Download',
    Redirect_Button: 'Redirect Button',
    Timed_Redirect: 'Timed Redirect',
    Confirm_Mail: 'Confirm Mail',
    Properties: 'Properties',
    Placeholder: 'Placeholder',
    FieldName: 'FieldName',
    FieldType: 'FieldType',
    Text: 'Text',
    Multipleselect: 'Multiple select',
    IsClearable: 'Value clearable',
    Additional_Information_Text: 'Additional Information Text',
    Additional_Information_Text_Detail: 'Additional Information Text Detail',
    Editor_Text: 'Editor Text',
    Header_Text: 'Header Text',
    Salutation_Type: 'Salutation Type',
    Display_Type: 'Display Type',
    Required: 'Required',
    Hide_ContainerName: 'Hide Container Name',
    Toggleable: 'Container Content Toggleable',
    Validationrule: 'Validationrule',
    Position: 'Position',
    Size: 'Size',
    Decimal_Separator: 'Decimal Separator',
    Thousand_Separator: 'Thousand Separator',
    label: 'label',
    Min_Value: 'Min Value',
    Max_Value: 'Max Value',
    Default_Value: 'Default Value',
    Marks: 'Marks',
    Select_Currency: 'Select Currency',
    Show_value_label: 'Show value label',
    Display: 'Display',
    Read_Only: 'Read Only',
    Options: 'Options',
    Row: 'Row',
    ButtonLabel: 'Button Label',
    filetype: 'filetype',
    filesize: 'filesize (MegaByte)',
    url: 'url',
    timer: 'timer',
    Sender_Email: 'Sender Email',
    Sender_Password: 'Sender Password',
    SMTP_Host: 'SMTP Host',
    Port: 'Port',
    Send_mail: 'Send E-Mail',
    Mail_Header: 'E-Mail Header',
    Mail_Body: 'E-Mail Body',
    Delete_Text: 'Field has dependencies. Do you want to delete it anyway?',
    Footer: 'Footer',
    German: 'German',
    English: 'English',
    warning_unsaved_changes_title: 'Do you really want to leave the project?',
    warning_unsaved_changes_content:
      'You have unsaved changes. If you leave the project, they will be lost.',
    error_parseCSV: 'The uploaded CSV file does not match the required format.',
    error_enterName: 'Please enter a name',
    error_uniqueName: 'Please enter a unique fieldname',
    error_name_exists_already:
      'There are fields with the same names. The following names are used more than once:',
    error_missing_email: 'Please fill atleast one Email Component',
    error_Database_unavailable:
      'Database cannot be reached. Please reload the page',
    error_Database_check_connection:
      'Please make sure that a database is connected',
    error_confirmationPage_notConfigured_1:
      'You have not yet configured the confirmation page for this application form.',
    error_confirmationPage_notConfigured_2:
      'This is mandatory, please configure it now.',
    discard_project_modal_title: 'Do you want to discard the form?',
    discard_project_modal_description: 'All progress will be deleted.',
    discard_project_confirm_message: 'Project successfully deleted.',
    discard_project_error_message:
      'An error has occured, project could not be deleted!',
    Reload: 'Reload',
    Cancel: 'Cancel',
    Test: 'Test',
    ConfirmationPage_title: 'Confirmation Page',
    was_successful: 'was successful',
    failed: 'failed',
    msg_popup_delete_container:
      'When the group is deleted, all components contained in it are also deleted.',
    msg_popup_delete_element: 'Do you want to delete the element?',
    Continuous: 'Continuous',
    Restricted: 'Restricted',
    Discrete: 'Discrete',
    Minimum: 'minimum',
    Maximum: 'maximum',
    Minimum_Abbrev: 'min',
    Maximum_Abbrev: 'max',
    Default: 'Default',
    Step: 'Step',
    Add: 'Add',
    on: 'on',
    off: 'off',
    auto: 'auto',
    On: 'On',
    Off: 'Off',
    msgNotValidJson_Header: 'Not a valid JSON',
    msgNotValidJson_Body: 'Please type in a valid Json!',
    UploadCSV: 'Upload from CSV',
    Automatically: 'Automatically',
    Dependency: 'Dependency',
    Dependencies: 'Dependencies',
    Dependency_Card_Name_Label: 'Dependency Name',
    Dependency_Condition_Text: 'If',
    Dependency_Action_Text: 'Then',
    Dependency_Choose_Action: 'choose Action',
    Dependency_Action_Specify_Text: 'Else (Optional)',
    Dependency_Start_Placeholder: 'Start-Placeholder',
    Dependency_End_Placeholder: 'End-Placeholder',
    Dependency_Positive_Effect: 'Positive Effect',
    Dependency_Positive_Effect_Value: 'Positive Effect Value',
    Dependency_Negative_Effect: 'Negative Effect',
    Dependency_Negative_Effect_Value: 'Negative Effect Value',
    Dependency_Description: 'Description',
    Dependency_Delete_Button_Label: 'Delete Dependency',
    Dependency_Threshold: 'Threshold',
    Dependency_Superior: 'Superior',
    Dependency_Superior_Name: 'Superior Name',
    Dependency_Superior_Field: 'Superior Field',
    Dependency_Dependent: 'Dependent',
    Dependency_Dependent_Name: 'Dependent Name',
    Dependency_Dependent_Field: 'Dependent Field',
    Dependency_Operator: 'Operator',
    Dependency_Delete_Button_Popup_Text:
      'Are your sure that you want to delete the Dependency?',

    Dependency_Warning_Tag_Missing_Name: 'Dependency name is missing.',
    Dependency_Warning_Tag_Missing_Action:
      'Action is missing. Dependency may not work as expected',
    Dependency_Warning_Tag_Missing_Operator:
      'Operator is missing. Dependency may not work as expected',
    Dependency_Error_Tag_Missing_Dependent:
      'Dependent is missing. Connect a new Dependent.',
    Dependency_Error_Tag_Missing_Superior:
      'Superior is missing. Connect a new Superior.',
    Dependency_Delete_Successfull_Title: 'Dependency deleted sucessfully!',
    Dependencies_Description_Placeholder:
      'Add a long description of the dependency to tell others why this dependency is necessary. Or just leave some kind words for your colleagues. ',
    Was_Removed: 'was removed',
    Dependencies_Actions: [
      { value: 'show', key: 'Show', specifiable: false },
      // { value: 'hide', key: 'Hide', specifiable: false },
      // { value: 'fill', key: 'Fill value', specifiable: true },
      // { value: 'delete', key: 'Delete value', specifiable: false },
      // { value: 'select', key: 'Select', specifiable: false },
      // { value: 'deselect', key: 'Deselect', specifiable: false },
      // { value: 'enable', key: 'Enable', specifiable: false },
      // { value: 'disable', key: 'Disable', specifiable: false },
    ],
    Dependencies_Operators: [
      { value: 'Contains', key: 'contains', type: 'string' },
      { value: 'ContainsNot', key: 'contains not', type: 'string' },
      { value: '=', key: '=', type: 'numeric' },
      { value: '!=', key: '!=', type: 'numeric' },
      { value: '>', key: '>', type: 'numeric' },
      { value: '>=', key: '>=', type: 'numeric ' },
      { value: '<', key: '<', type: 'numeric' },
      { value: '<=', key: '<=', type: 'numeric' },
      { value: 'True', key: 'true', type: 'boolean' },
      { value: 'False', key: 'false', type: 'boolean' },
      { value: 'Checked', key: 'checked', type: 'checkbox' },
      { value: 'Unchecked', key: 'unchecked', type: 'checkbox' },
    ],
    Dependency_unnamed: 'Unnamed Dependency',
    bnf_error_1: 'At the property ',
    bnf_error_2: ' in line ',
    bnf_error_3: ', column ',
    bnf_error_4: " the expression's syntax was violated: \n",
    bnf_error_5: ' is not expected at this position.',
    bnf_error_6: 'Name(s) ',
    bnf_error_7: ' not found.',
    wrong_value_error: 'Invalid input for this field',
  },
  de: {
    LogIn: 'Login',
    LogOut: 'Abmelden',
    Drop_Option_here: 'Option hierher ziehen',
    value: 'Wert',
    optionpaneOption: 'Optionsfeld Option',
    Add_Options_Success: ' wurde zu den Optionen hinzugefügt',
    Add_Options_Success_Multiple: 'Rollen wurden zu den Optionen hinzugefügt',
    Add_Options_Failure: 'Fehler beim Versuch die Optionen hinzuzufügen',
    input_writable: 'Eingabefeld schreibbar',
    hour: 'Stunde',
    year: 'Jahr',
    email: 'Email',
    iban: 'IBAN',
    daterange: 'Datumsbereich',
    target: 'Ziel',
    row_label_hint:
      'Beschreibung wird nur für Reihen mit mehr als 2 Elementen angezeigt',
    Space_Distribution: 'Reihenaufteilung',
    crealogix: 'Crealogix',
    plain: 'Plain',
    markdown: 'Markdown',
    form: 'Form',
    accordion: 'Accordion',
    date: 'Datum',
    number: 'Zahl',
    text: 'Text',
    textarea: 'Text Fläche',
    output: 'Ausgabe',
    checkbox: 'Kontrollkasten',
    toggleswitch: 'Toggleswitch',
    count: 'Anzahl',
    amount: 'Menge',
    select: 'Auswahl',
    multiselect: 'Mehrfach Auswahl',
    checkboxgroup: 'Kontrollkasten Gruppe',
    autocomplete: 'Autovervollständigen',
    radiogroup: 'Optionsschaltflächen',
    slider: 'Schieberegler',
    row: 'Reihe',
    link: 'Link',
    fieldset: 'Feldgruppe',
    optionpane: 'Optionsfeld',
    textblock: 'Textblock',
    collapsible: 'Zusammenklappbar',
    conditional: 'Bedingung',
    list: 'Liste',
    help: 'Hilfe',
    helpDetails: 'Hilfe Details',
    useInfoIcon: 'Info Icon verwenden',
    confidential: 'Vertraulich',
    confidentialCharactersCount: 'Anzahl der vertraulichen Zeichen',
    className: 'Html-Klassenname',
    placeholder: 'PLatzhalter',
    displayIf: 'Anzeigen wenn',
    visibleFor: 'Sichtbar für',
    disabledFor: 'Deaktiviert für',
    hiddenFor: 'Versteckt für',
    editableFor: 'Bearbeitbar für',
    validateIf: 'Validieren wenn',
    disabled: 'Deaktiviert',
    readOnly: 'Schreibgeschützt',
    refExternalData: 'Externe Referenzdaten',
    showInput: 'Zeige Eingabe',
    valueFormat: 'Wert Format',
    step: 'Schritt',
    scale: 'Skalierung',
    validation: 'Validierung',
    backendValidation: 'Backend Validierung',
    useTime: 'benutze Zeit',
    unit: 'Einheit',
    decimalPlaces: 'Dezimal Stellen',
    expression: 'Ausdruck',
    format: 'Format',
    minLength: 'Minimale Länge',
    maxLength: 'Maximale Länge',
    checkboxLabel: 'Kontrollkästchen Beschreibung',
    min: 'Minimum',
    max: 'Maximum',
    writable: 'Schreibbar',
    currencyOptions: 'Währungsoptionen',
    options: 'Optionen',
    backendOptionsType: 'Backend Optionentyp',
    backendSuggestionsType: 'Backend Voschlagstyp',
    maxSuggestions: 'Maximale Voschlänge',
    minQueryLength: 'Minimale Abfragelänge',
    horizontal: 'Horizontal',
    fields: 'Felder',
    spaceDistribution: 'Platz Aufteilung',
    sameWindow: 'Selbes Fenster',
    type: 'Typ',
    titleExpression: 'Titel Ausdruck',
    titleFormat: 'Titel Format',
    initiallyOpened: 'Anfänglich geöffnet',
    listLabel: 'Listen Beschreibung',
    entryLabel: 'Eintragsbeschreibung',
    removable: 'Entfernbar',
    extensible: 'Erweiterbar',
    defaultValue: 'Standard Wert',
    space_Distribution: 'Reihenaufteilung',
    help_placeholder:
      'Ein kurzer Hifletext, welcher direkt unterhalb der Komponente angezeigt wird',
    helpDetails_placeholder:
      'Ein langer Hilfetext, welcher in einem Separaten Popup gezeigt wird',
    Text_Block: 'Textblock',
    //
    Conditional_Dropdown_Menu: 'Bedingtes Auswahlmenü',
    Superior_Label: 'Überlegenes Auswahlmenü Anzeigename',
    Superior_FieldName: 'Überlegenes Auswahlmenü Feldname',
    Dependent_Label: 'Abhängiges Auswahlmenü Anzeigename',
    Dependent_FieldName: 'Abhängiges Auswahlmenü Feldname',
    multiline: 'mehrzeiliger input',
    Page_content_is_shown_in_summary: 'Zeige Seiteninhalt in Zusammenfassung',
    Thank_You_DefaultValue: 'Vielen Dank! Wir haben ihre Daten erhalten.',
    Left: 'Links',
    Center: 'Mitte',
    Right: 'Rechts',
    S: 'S',
    M: 'M',
    L: 'L',
    Mr: 'Herr',
    Ms: 'Frau',
    other: 'andere',
    'no specification': 'keine Angabe',
    Input_Mark: 'Eingabe Markierung',
    Select_Type: 'Einheit auswählen',
    Select_Unit: 'Typ auswählen Unit',
    Input_Step: 'Eingabeschritte',
    Page: 'Seite',
    Option_Label: 'Option Titel',
    Option_Value: 'Option Value',
    Option_Info: 'Option Info',
    Input_Min_Value: 'minimaler Eingabewert',
    Input_Max_Value: 'maximaler Eingabewert',
    Subheader: 'Zwischenüberschrift',
    Empty_Page:
      'Diese Seite ist noch leer. Füge zuerst Felder hinzu, um Abhängigkeiten erstellen zu können',
    Label: 'Beschriftung',
    Choose_Project:
      'Bitte wählen Sie ein existierendes Projekt aus oder erstellen Sie ein neues Projekt',
    Last_modified: 'Zuletzt Bearbeitet',
    Actions: 'Aktionen',
    Edit: 'Bearbeiten',
    Delete: 'Löschen',
    Duplicate: 'Duplizieren',
    NewForm: 'Neues Formular erstellen',
    Name: 'Name',
    Description: 'Beschreibung',
    Return: 'Zurück',
    Save: 'Speichern',
    Download: 'Herunterladen',
    Components: 'Komponenten',
    Layout: 'Layout',
    Predefined: 'Vordefiniert',
    Confirmation: 'Bestätigung',
    Input: 'Input',
    Container: 'Container',
    Text_Area: 'Textfeld',
    Header: 'Überschrift',
    Salutation: 'Anrede',
    Firstname: 'Vorname',
    Lastname: 'Nachname',
    Place_Of_Birth: 'Geburtsort',
    Birthday: 'Geburtsdatum',
    Street: 'Straße',
    House_Number: 'Hausnummer',
    Postal_Code: 'Postleitzahl',
    Town: 'Stadt',
    Email: 'E-Mail',
    Phone: 'Telefon',
    Picture: 'Bild',
    Currency: 'Währung',
    Calendar: 'Kalender',
    CalendarFormat: 'Format',
    Slider: 'Schieberegler',
    IBAN: 'IBAN',
    IbanWithBicAndBankname: 'IBAN mit BIC und Bankname',
    YearDropdown: 'Jahreszahl Dropdown',
    YearAmount: 'Anzahl Jahre',
    YearShowPre: 'Zeige "vor Jahr X" Option',
    Radio_Button: 'Optionsschaltfläche',
    Radio_Buttons: 'Optionsschaltflächen',
    CheckboxGroup: 'Kontrollkastengruppe',
    Dropdown_Menu: 'Auswahlmenü',
    CountField: 'Zählfeld',
    Checkbox: 'Kontrollkasten',
    CheckboxLabel: 'Kontrollkasten Beschreibung',
    ToggleSwitch: 'Knopfschalter',
    FileUpload: 'Datei Hochladen',
    JPEG: 'JPEG',
    PNG: 'PNG',
    BMP: 'BMP',
    PDF: 'PDF',
    AGB: 'AGB',
    Summary: 'Zusammenfassung',
    Thank_You: 'Dankeschön',
    PDF_Download: 'PDF-Download',
    Redirect_Button: 'Weiterleitungsknopf',
    Timed_Redirect: 'Zeitliche Weiterleiten',
    Confirm_Mail: 'Bestättigungsemail',
    Properties: 'Eigenschaften',
    Placeholder: 'Platzhalter',
    FieldName: 'Feld Name',
    FieldType: 'Feld Typ',
    Text: 'Text',
    Multipleselect: 'Mehrfachauswahl',
    IsClearable: 'Wert löschbar',
    Additional_Information_Text: 'Zusätzliche Informationen',
    Additional_Information_Text_Detail: 'Zusätzliche Informationen Details',
    Editor_Text: 'Editor Text',
    Header_Text: 'Überschrift',
    Salutation_Type: 'Begrüßung',
    Display_Type: 'Darstellungsweise',
    Required: 'Verpflichtend',
    Hide_ContainerName: 'Kontainername verstecken',
    Toggleable: 'Kontainerinhalt Ein- und Ausklappbar',
    Validationrule: 'Überprüfungsregel',
    Position: 'Position',
    Size: 'Größe',
    Decimal_Separator: 'Dezimal Trennzeichen',
    Thousand_Separator: 'Tausender Trennzeichen',
    label: 'Text',
    Min_Value: 'Min Wert',
    Max_Value: 'Max Wert',
    Default_Value: 'Standardwert',
    Marks: 'Markierungen',
    Select_Currency: 'Währungsauswahl',
    Show_value_label: 'Zeige Werte',
    Display: 'Anzeigen',
    Read_Only: 'Schreibgeschützt',
    Options: 'Optionen',
    Row: 'Reihe',
    ButtonLabel: 'Knopfbeschriftung',
    filetype: 'Datei Typ',
    filesize: 'Datei Größe (MegaByte)',
    url: 'url',
    timer: 'Timer',
    Sender_Email: 'Sender E-Mail',
    Sender_Password: 'Passwort',
    SMTP_Host: 'SMTP Host',
    Port: 'Port',
    Send_mail: 'E-Mail senden',
    Mail_Header: 'E-Mail Überschrift',
    Mail_Body: 'E-Mail Text',
    Delete_Text: 'Feld hat Abhängigkeiten. Wollen Sie es trotzdem löschen?',
    Footer: 'Fußnote',
    German: 'Deutsch',
    English: 'Englisch',
    warning_unsaved_changes_title: 'Wollen sie das Projekt wirklich Verlassen?',
    warning_unsaved_changes_content:
      'Sie haben nicht gespeicherte Änderungen. Falls sie das Projekt verlassen gehen diese verloren.',
    error_parseCSV: 'CSV entspricht nicht dem erwarteten Format.',
    error_enterName: 'Bitte geben Sie einen Namen an.',
    error_uniqueName: 'Bitte geben Sie einen einzigartigen Feldnamen an.',
    error_name_exists_already:
      'Es existieren Felder mit gleichen Namen. Folgende Namen werden mehr als einmal genutzt:',
    error_two_fields_with_no_name:
      'Es existieren mehrere Felder ohne Feldnamen.',
    error_missing_email:
      'Bitte geben Sie mindestens eine gültige Email Adresse an.',
    error_Database_unavailable:
      'Die Datenbank kann nicht erreicht werden. Bitte laden Sie die Seite neu.',
    error_Database_check_connection:
      'Bitte überprüfen Sie ob die Datenbank verbunden ist.',
    error_confirmationPage_notConfigured_1:
      'Sie haben die Bestätigungsseite zu dieser Antragsstrecke noch nicht konfiguriert. ',
    error_confirmationPage_notConfigured_2:
      'Diese ist zwingend erforderlich, bitte konfigurieren sie sie jetzt.',
    discard_project_modal_title: 'Wollen Sie die Form verwerfen?',
    discard_project_modal_description: 'Der gesamte Fortschritt geht verloren',
    discard_project_confirm_message: 'Projekt wurde erfolgreich gelöscht.',
    discard_project_error_message:
      'Ein Fehler ist aufgetreten, Projekt konnte nicht gelöscht werden.',
    Reload: 'Neuladen',
    Cancel: 'Abbrechen',
    Test: 'Test',
    ConfirmationPage_title: 'Bestätigungsseite',
    was_successful: 'war erfolgreich',
    failed: 'ist fehlgeschlagen',
    msg_popup_delete_container:
      'Wenn die Gruppe gelöscht wird, werden alle enthaltenen Felder mit gelöscht.',
    msg_popup_delete_element:
      'Sind Sie sicher, dass Sie das Element löschen wollen',
    Continuous: 'Kontinuierlich',
    Restricted: 'Beschränkt',
    Discrete: 'Diskret',
    Minimum: 'minimum',
    Maximum: 'maximum',
    Minimum_Abbrev: 'min',
    Maximum_Abbrev: 'max',
    Default: 'Standard',
    Step: 'Schritt',
    Add: 'Hinzufügen',
    on: 'an',
    off: 'aus',
    auto: 'auto',
    On: 'An',
    Off: 'Aus',
    UploadCSV: 'Upload aus CSV',
    Automatically: 'Automatisch',
    msgNotValidJson_Header: 'Keine gültige Json',
    msgNotValidJson_Body: 'Bitte geben Sie eine gültige JSON ein!',
    Dependency: 'Abhängigkeit',
    Dependencies: 'Abhängigkeiten',
    Dependency_Card_Name_Label: 'Abhängigkeit Name',
    Dependency_Condition_Text: 'Wenn',
    Dependency_Action_Text: 'Dann',
    Dependency_Choose_Action: 'wähle Aktion',
    Dependency_Action_Specify_Text: 'Sonst (Optional)',
    Dependency_Start_Placeholder: 'Start-Platzhalter',
    Dependency_End_Placeholder: 'Ende-Platzhalter',
    Dependency_Positive_Effect: 'Positiver Effekt',
    Dependency_Positive_Effect_Value: 'Positiver Effekt Wert',
    Dependency_Negative_Effect: 'Negativer Effekt',
    Dependency_Negative_Effect_Value: 'Negativer Effekt Wert',
    Dependency_Description: 'Beschreibung',
    Dependency_Delete_Button_Label: 'Abhängigkeit löschen',
    Dependency_Threshold: 'Grenzwert',
    Dependency_Superior: 'Überlegener',
    Dependency_Superior_Name: 'Überlegener Name',
    Dependency_Superior_Field: 'Überlegenes Feld',
    Dependency_Dependent: 'Abhängiger',
    Dependency_Dependent_Name: 'Abhängiger Name',
    Dependency_Dependent_Field: 'Abhängiges Feld',
    Dependency_Operator: 'Operator',
    Dependency_Delete_Successfull_Title:
      'Abhängigkeit wurde erfolgreich gelöscht!',
    Dependency_Delete_Button_Popup_Text:
      'Sind Sie sicher, dass sie die ausgewählte Abhängigkeit löschen wollen?',
    Dependency_Warning_Tag_Missing_Name: 'Name der Abhängigkeit benötigt.',
    Dependency_Warning_Tag_Missing_Action:
      'Aktion fehlt. Die Abhängigkeit verhält sich vermutlich nicht wie erwartet.',
    Dependency_Warning_Tag_Missing_Operator:
      'Operator fehlt. Die Abhängigkeit verhält sich vermutlich nicht wie erwartet.',
    Dependency_Error_Tag_Missing_Dependent:
      'Abhängiges Feld fehlt. Ein neues abhängiges Feld muss gewählt werden.',
    Dependency_Error_Tag_Missing_Superior:
      'Überlegenes Feld fehlt. Ein neues überlegenes Feld muss gewählt werden.',

    Dependencies_Description_Placeholder:
      'Füge eine Beschreibung hinzu, um andere von der Notwendigkeit dieser Abhängigkeit zu überzeugen. Oder hinterlasse einfach ein paar nette Worte für deine Kollegen. ',
    Was_Removed: 'wurde entfernt',
    Dependencies_Actions: [
      { value: 'show', key: 'anzeigen', specifiable: false },
      // { value: 'hide', key: 'ausblenden', specifiable: false },
      // { value: 'fill', key: 'füllen', specifiable: true },
      // { value: 'delete', key: 'löschen', specifiable: false },
      // { value: 'select', key: 'auswählen', specifiable: false },
      // { value: 'deselect', key: 'abwählen', specifiable: false },
      // { value: 'enable', key: 'aktivieren', specifiable: false },
      // { value: 'disable', key: 'deaktivieren', specifiable: false },
    ],
    Dependencies_Operators: [
      { value: 'Contains', key: 'enthält', type: 'string' },
      { value: 'ContainsNot', key: 'enthält nicht', type: 'string' },
      { value: '=', key: '=', type: 'numeric' },
      { value: '!=', key: '!=', type: 'numeric' },
      { value: '>', key: '>', type: 'numeric' },
      { value: '>=', key: '>=', type: 'numeric' },
      { value: '<', key: '<', type: 'numeric' },
      { value: '<=', key: '<=', type: 'numeric' },
      { value: 'True', key: 'wahr', type: 'boolean' },
      { value: 'False', key: 'falsch', type: 'boolean' },
      { value: 'Checked', key: 'ausgewählt', type: 'checkbox' },
      { value: 'Unchecked', key: 'unausgewählt', type: 'checkbox' },
    ],
    Dependency_unnamed: 'Unbenannte Abhängigkeit',
    bnf_error_1: 'Bei der Eigenschaft ',
    bnf_error_2: ' in Zeile ',
    bnf_error_3: ', Spalte ',
    bnf_error_4: ' wurde die Regelsyntax verletzt: \n',
    bnf_error_5: ' ist an dieser Stelle nicht erwartet.',
    bnf_error_6: 'Name(n) ',
    bnf_error_7: ' nicht gefunden.',
    wrong_value_error: 'Falscher Eingabewert für dieses Feld',
  },
};
