import * as React from 'react';
import NumberEditor from './numberEditor';
import BooleanEditor from './booleanEditor';
import CheckboxComponent from './checkboxComponentEditor';
import CheckboxEditor from './checkboxEditor';
import ConditionalDropdown from './ConditionalDropdownEditor';
import DescriptionEditor from './descriptionEditor';
import InputandDropdown from './InputandDropdown';
import InputDropdownEditor from './InputDropdownEditor';
import InputMappingsEditor from './InputMappingsEditor';
import InputRadioEditor from './InputRadioEditor';
import ListEditor from './listEditor';
import PictureUploadEditor from './pictureUploadEditor';
import RadioEditor from './radioEditor';
import SelectMenuEditor from './selectMenuEditor';
import SliderEditor from './sliderEditor';
import StringEditor from './stringEditor';
import TextEditor from './textEditor';
import { PropConfig } from '../../../core/IDragItem';
import { IStrings } from '../../../core/IStrings';
import ButtonEditor from './buttonEditor/ButtonEditor';

export const getPropertyEditor = (entry: PropConfig, contextValue: any) => {
  switch (entry.editorType) {
    case 'String':
      return (
        <StringEditor
          value={entry.value}
          dataKey={entry.key}
          placeholder={entry.placeholder}
          label={IStrings[entry.label]}
          disabled={entry.disabled || false}
          // maxLength={entry.maxLength}
        />
      );
    case 'Slider':
      return (
        <SliderEditor
          value={entry.value}
          dataKey={entry.key}
          label={IStrings[entry.label]}
          options={entry.options}
        />
      );
    case 'Number':
      return (
        <NumberEditor
          value={entry.value}
          dataKey={entry.key}
          placeholder={entry.placeholder}
          label={IStrings[entry.label]}
          disabled={entry.disabled || false}
        />
      );
    case 'Description':
      return (
        <DescriptionEditor
          value={entry.value}
          dataKey={entry.key}
          label={IStrings[entry.label]}
          placeholder={entry.placeholder}
          disabled={entry.disabled || false}
        />
      );
    case 'Text':
      return (
        <TextEditor
          value={entry.value}
          dataKey={entry.key}
          label={IStrings[entry.label]}
          styleType={entry.styleType}
        />
      );
    case 'Boolean':
      return (
        <BooleanEditor
          value={/true/i.test(entry.value || '')}
          dataKey={entry.key}
          label={IStrings[entry.label]}
          disabled={entry.disabled || false}
        />
      );
    case 'Checkbox':
      return (
        <CheckboxEditor
          value={entry.value}
          dataKey={entry.key}
          label={IStrings[entry.label]}
          disabled={entry.disabled || false}
          options={entry.options}
        />
      );
    case 'List':
      return (
        <ListEditor
          value={entry.value}
          dataKey={entry.key}
          label={IStrings[entry.label]}
          disabled={entry.disabled || false}
        />
      );
    case 'InputRadio':
      return (
        <InputRadioEditor
          value={entry.value}
          dataKey={entry.key}
          label={IStrings[entry.label]}
        />
      );
    case 'ToggleSwitch':
      return (
        <DescriptionEditor
          value={entry.value}
          dataKey={entry.key}
          label={IStrings[entry.label]}
          disabled={entry.disabled || false}
        />
      );
    case 'InputDropdown':
      return (
        <InputDropdownEditor
          id={
            contextValue &&
            contextValue.activeField &&
            contextValue.activeField.id
          }
          value={entry.value}
          handleChange={contextValue.setPropOfActiveField}
          dataKey={entry.key}
          label={IStrings[entry.label]}
          options={entry.options}
        />
      );
    case 'ConditionalDropdown':
      return (
        <ConditionalDropdown
          id={
            contextValue &&
            contextValue.activeField &&
            contextValue.activeField.id
          }
          handleChange={contextValue.setPropOfActiveField}
          dataKey={entry.key}
          label={IStrings[entry.label]}
          value={entry.value}
        />
      );
    case 'InputandDropdown':
      return (
        <InputandDropdown
          value={entry.value}
          dataKey={entry.key}
          label={IStrings[entry.label]}
          options={entry.options}
        />
      );
    case 'SelectMenu':
      return (
        <SelectMenuEditor
          value={entry.value}
          placeholder={entry.placeholder}
          dataKey={entry.key}
          label={IStrings[entry.label]}
          options={entry.options}
        />
      );
    case 'Picture':
      return (
        <PictureUploadEditor
          value={entry.value}
          dataKey={entry.key}
          label={IStrings[entry.label]}
        />
      );
    case 'Radio':
      return (
        <RadioEditor
          value={entry.value}
          dataKey={entry.key}
          label={IStrings[entry.label]}
          options={entry.options}
        />
      );
    case 'CheckboxComponent':
      return (
        <CheckboxComponent
          value={entry.value}
          dataKey={entry.key}
          label={IStrings[entry.label]}
        />
      );
    case 'InputMappings':
      return (
        <InputMappingsEditor
          id={
            contextValue &&
            contextValue.activeField &&
            contextValue.activeField.id
          }
          value={entry.value}
          handleChange={contextValue.setPropOfActiveField}
          dataKey={entry.key}
          label={IStrings[entry.label]}
          mappingKeyLabel={IStrings[entry.mappingKeyLabel ?? 'Mapping_Client']}
          mappingValueLabel={
            IStrings[entry.mappingValueLabel ?? 'Mapping_Endpoint']
          }
          validateKey={
            entry.validateKeyAsFieldname
              ? contextValue.containsItemWithFieldName
              : () => true
          }
          disabled={entry.disabled || false}
        />
      );
    case 'Button':
      return (
        <ButtonEditor
          buttons={entry.value}
          dataKey={entry.key}
          label={IStrings[entry.label]}
        />
      );
  }
};

export default getPropertyEditor;
