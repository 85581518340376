import LocalizedStrings from 'react-localization';
import merge from '../components/utils/merge';
import { AsbStrings } from './Internalization/AsbStrings';

declare var __TENANT__: string;

const MergeStrings = () => {
  if (__TENANT__ === 'payback') {
    const PaybackStrings =
      require('./Internalization/PaybackStrings').PaybackStrings;
    const MergedStringsEN = merge(AsbStrings.en, PaybackStrings.en);
    const MergedStringsDE = merge(AsbStrings.de, PaybackStrings.de);
    return new LocalizedStrings({ en: MergedStringsEN, de: MergedStringsDE });
  } else if (__TENANT__ === 'clx') {
    const ClxStrings = require('./Internalization/ClxStrings').ClxStrings;
    const MergedStringsEN = merge(AsbStrings.en, ClxStrings.en);
    const MergedStringsDE = merge(AsbStrings.de, ClxStrings.de);
    return new LocalizedStrings({ en: MergedStringsEN, de: MergedStringsDE });
  } else {
    return new LocalizedStrings(AsbStrings);
  }
};

export let IStrings = MergeStrings();
