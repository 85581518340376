import React, { FunctionComponent, useContext, useEffect } from 'react';
import { Input } from 'antd';
import '../../../styles/ComponentPropertyEditor.css';
import { CanvasContext } from '../../../canvasContext';

const { TextArea } = Input;

interface DescriptionEditorProps {
  label: string;
  placeholder?: string;
  value?: string;
  handleChange: (key: string, value: string) => void;
  dataKey: string;
  disabled: boolean;
  validationError?: boolean;
  dependencyError?: boolean;
}

export const DescriptionEditor: FunctionComponent<DescriptionEditorProps> = ({
  label,
  value,
  handleChange,
  dataKey,
  placeholder,
  disabled,
  validationError,
  dependencyError,
}) => {
  const { validatePropertyOfActiveField } = useContext(CanvasContext);

  return (
    <>
      <label
        className="textEditor"
        style={{ color: validationError || dependencyError ? '#c92828' : '' }}
      >
        {label}
      </label>
      <br />
      <TextArea
        className="check"
        style={{
          width: '100%',
          color: validationError || dependencyError ? '#c92828' : '',
        }}
        value={value}
        placeholder={placeholder}
        autoSize={{ minRows: 2, maxRows: 5 }}
        onChange={(e: any) => {
          handleChange(dataKey, e.target.value);
        }}
        onBlur={() => validatePropertyOfActiveField(dataKey, value ?? '')}
      />
    </>
  );
};

export default DescriptionEditor;
