import { Radio } from 'antd';
import React, { FunctionComponent, useContext } from 'react';
import { CanvasContext } from '../../../canvasContext';
import { IStrings } from '../../../core/IStrings';
import '../../../styles/ComponentPropertyEditor.css';

interface RadioEditorProps {
  label: string;
  value?: any;
  dataKey: string;
  options?: { key: string; value: string }[];
}

export const RadioEditor: FunctionComponent<RadioEditorProps> = ({
  label,
  value,
  dataKey,
  options,
}) => {
  const { setPropOfActiveField } = useContext(CanvasContext);
  return (
    <>
      <label className="checkboxEditor">{label}</label>
      <Radio.Group
        onChange={(e: any) => {
          setPropOfActiveField(dataKey, e.target.value);
        }}
        value={value}
      >
        {options?.map((option) => (
          <Radio key={option.key} value={option.value}>
            {IStrings[option.value]}
          </Radio>
        ))}
      </Radio.Group>
    </>
  );
};

export default RadioEditor;
