import TextArea from 'antd/lib/input/TextArea';
import React, { FunctionComponent, useContext } from 'react';
import { CanvasContext } from '../../../../canvasContext';
import { IStrings } from '../../../../core/IStrings';
import { VALIDATIONERROR } from './constants';

interface ValidationErrorMessageInputProps {
  value?: string;
  validationKey: string;
}

export const ValidationErrorMessageInput: FunctionComponent<ValidationErrorMessageInputProps> = ({
  value,
  validationKey,
}) => {
  const { setValidationPropOfActiveField } = useContext(CanvasContext);
  return (
    <>
      <label className="textEditor">{IStrings['ErrorMessage']}</label>
      <TextArea
        className="check"
        value={value}
        style={{ width: '100%' }}
        placeholder={IStrings[validationKey]}
        autoSize={{ minRows: 3 }}
        onChange={(e: any) => {
          setValidationPropOfActiveField(
            validationKey,
            e.target.value,
            VALIDATIONERROR
          );
        }}
      />
    </>
  );
};
