import UserService from '../auth/UserService';
import AlertModal from '../../components/alertModal';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function getHeaders() {
  let headers = new Headers();
  headers.append('Authorization', `Bearer ${UserService.getToken()}`);
  headers.append('Content-Type', 'application/json');
  return headers;
}

function showAlertModal(response) {
  if (response.status === 401) {
    AlertModal();
  }
  return response;
}

export default function downloadZip(jsonConfig, projectId, projectname) {
  return fetch(
    `${BACKEND_URL}/config/postData?id=${projectId}&deployment=true`,
    {
      method: 'POST',
      headers: getHeaders(),
      body: jsonConfig,
    }
  )
    .then(async (response) => {
      if (projectname) {
        const link = document.createElement('a');
        link.href = BACKEND_URL + '/zip/' + projectname + '.zip';
        link.target = '_blank';
        link.setAttribute('download', projectname.concat('.zip'));
        document.body.appendChild(link);
        setTimeout(() => {
          link.click();
        }, 1000);

        setTimeout(() => {
          window.URL.revokeObjectURL(link);
        }, 5000);
      }
      return showAlertModal(response);
    })
    .catch((error) => {
      return error;
    });
}

export function getPageFromConfig(projectId, pageIndex, fileName) {
  return fetch(`${BACKEND_URL}/config/project/${projectId}/page/${pageIndex}`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        const dataStr =
          'data:text/json;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(data, null, 2));
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute('href', dataStr);
        downloadAnchorNode.setAttribute('download', fileName + '.df');
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      } else {
        throw new Error('NETWORK RESPONSE NOT OK');
      }
    })
    .catch((error) => {
      console.log(error);
      throw new Error('FETCH ERROR:', error);
    });
}

export function getRolesFromBackend() {
  return fetch(`${BACKEND_URL}/role`, {
    method: 'GET',
    headers: getHeaders(),
  });
}

export function addRoleToBackend(role) {
  return fetch(`${BACKEND_URL}/role`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ roles: [{ name: role }] }),
  });
}

export function addRoleListToBackend(roleList) {
  return fetch(`${BACKEND_URL}/role`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      roles: roleList.map((role) => {
        return { name: role };
      }),
    }),
  });
}
