import React, { FunctionComponent, useContext } from 'react';
import DraggableWrapper from '../core/draggableWrapper';
import { DragItemProps } from '../core/IDragItem';
import DropableAreaHorizontal from '../core/dropableAreaHorizontal';
import { ItemTypes } from '../core/itemTypes';
import { CanvasContext } from '../canvasContext';
import { DependenciesModeContext } from '../core/dependenicesModeContext';
import { IStrings } from '../core/IStrings';

export const Page: FunctionComponent<DragItemProps> = ({ ...props }) => {
  const { activeField, setActiveField } = useContext(CanvasContext);
  const { dependenciesMode } = useContext(DependenciesModeContext);
  const { children, ...page } = props;

  return (
    <DraggableWrapper {...page}>
      <div
        onClick={() => {
          setActiveField(page);
        }}
        style={{
          marginBottom: '10px',
          marginTop: '10px',
          marginLeft: '10px',
          marginRight: '10px',
          padding: '10px',
          backgroundColor: '#ffffff',
          border: '1px',
          borderStyle: 'solid',
          borderColor: page.id === activeField?.id ? '#93F100' : '#B1B0AF',
        }}
      >
        <DropableAreaHorizontal
          parentId={page.id}
          index={0}
          acceptedItemTypes={[
            ItemTypes.ITEM,
            ItemTypes.CONTAINER,
            ItemTypes.ROW,
          ]}
        />
        {dependenciesMode === true && children === undefined ? (
          <div style={{ textAlign: 'center', alignContent: 'center' }}>
            {IStrings.Empty_Page}
          </div>
        ) : (
          children !== 0 && children
        )}
      </div>
    </DraggableWrapper>
  );
};

export default Page;
