import { MinusOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { CanvasContext } from '../../../../../canvasContext';
import { IStrings } from '../../../../../core/IStrings';
import '../../../../../styles/ComponentPropertyEditor.css';
import { VALIDATIONVALUE } from '../constants';

interface ListInputEditorProps {
  value?: Array<string>;
  validationKey: string;
  placeholder: string;
  disabled?: boolean;
}

export const ListInputEditor: FunctionComponent<ListInputEditorProps> = ({
  value,
  validationKey,
  placeholder,
  disabled,
}) => {
  const { setValidationPropOfActiveField } = useContext(CanvasContext);
  let newValue: Array<string> = value ? value : new Array<string>();
  const [inputOne, setInputOne] = useState<string>('');

  const addOnClick = (): void => {
    newValue.push(inputOne);
    setInputOne('');
    setValidationPropOfActiveField(validationKey, newValue, VALIDATIONVALUE);
  };

  const removeOnClick = (index: number): void => {
    newValue.splice(index, 1);
    setValidationPropOfActiveField(validationKey, newValue, VALIDATIONVALUE);
  };

  return (
    <>
      {newValue.length !== 0 && (
        <ul className="radioEditorUl">
          {newValue.map((el: string, index: number) => (
            <li key={index} className="radioEditorLi">
              {el}
              <Button
                className="listRemoveButton"
                size="small"
                shape="circle"
                onClick={() => {
                  removeOnClick(index);
                }}
                icon={<MinusOutlined style={{ margin: '0px' }} />}
                data-testid="inputDropdownDelBtn"
              />
            </li>
          ))}
        </ul>
      )}

      <Input
        placeholder={placeholder}
        value={inputOne}
        disabled={disabled}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setInputOne(e.target.value);
        }}
        data-testid={'stringEditor' + validationKey}
      />
      <br />
      <Button
        className="deleteButton"
        disabled={!inputOne.trim()}
        onClick={() => addOnClick()}
      >
        <span className="deleteButtonSpan">{IStrings.Add}</span>
      </Button>
    </>
  );
};

export default ListInputEditor;
