import { Input } from 'antd';
import React, { FunctionComponent, useContext } from 'react';
import { CanvasContext } from '../../../canvasContext';
import '../../../styles/ComponentPropertyEditor.css';

const { TextArea } = Input;

interface DescriptionEditorProps {
  label: string;
  placeholder?: string;
  value?: string;
  dataKey: string;
  disabled: boolean;
}

export const DescriptionEditor: FunctionComponent<DescriptionEditorProps> = ({
  label,
  value,
  dataKey,
  placeholder,
  disabled,
}) => {
  const { setPropOfActiveField } = useContext(CanvasContext);
  return (
    <>
      <label className="textEditor">{label}</label>
      <TextArea
        className="check"
        value={value}
        style={{ width: '100%' }}
        placeholder={placeholder}
        autoSize={{ minRows: 2, maxRows: 5 }}
        onChange={(e: any) => {
          setPropOfActiveField(dataKey, e.target.value);
        }}
      />
    </>
  );
};

export default DescriptionEditor;
