import { Menu } from 'antd';
import React, { useContext } from 'react';
import { CanvasContext } from '../canvasContext';
import { IStrings } from '../core/IStrings';
import {
  COMPONENTSCONFIG,
  ComponentType,
  COMPONENTTYPES,
} from '../core/readConfig';
import '../styles/componentsMenu.css';
import { MenuCard } from './menuCard';
const { SubMenu } = Menu;

export function ComponentsMenu() {
  const { confirmationDisplay } = useContext(CanvasContext);

  function subMenu(compType: ComponentType) {
    return (
      <SubMenu
        key={compType.key}
        title={IStrings[compType.name]}
        style={{
          color: compType.color,
          fontSize: '16px',
          marginRight: '10px',
        }}
      >
        <ul className="componentsMenu">
          {COMPONENTSCONFIG &&
            COMPONENTSCONFIG.map(
              (comp, index) =>
                comp.componentTypes?.includes(compType.key) && (
                  <li key={'card_' + comp.title}>
                    <MenuCard
                      refComponent={comp.refComponent}
                      key={index.toString()}
                      internalName={comp.internalName}
                      id={comp.id}
                      title={comp.title}
                      propConfig={comp.propConfig}
                      validationConfig={comp.validationConfig}
                      itemType={comp.itemType}
                      icon={comp.icon}
                      componentTypes={[compType.key]}
                    />
                  </li>
                )
            )}
        </ul>
      </SubMenu>
    );
  }

  return (
    <Menu
      mode="inline"
      data-testid="menuTest"
      key="componentsMenu"
      className="componentsMainmenu"
    >
      <p className={'headline'}>{IStrings['Components']}</p>
      {COMPONENTTYPES?.map(
        confirmationDisplay
          ? (compType) => compType.key === 'confirmation' && subMenu(compType)
          : (compType) => compType.key !== 'confirmation' && subMenu(compType)
      )}
    </Menu>
  );
}

export default ComponentsMenu;
