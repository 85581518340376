import React, { useState, useContext, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes } from './itemTypes';
import { DragItemProps } from './IDragItem';
import { CanvasContext } from '../canvasContext';
import { DependenciesModeContext } from './dependenicesModeContext';
import { Divider } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

interface DropableAreaHorizontalProps {
  index: number;
  parentId?: string;
  acceptedItemTypes: ItemTypes[];
}

export const DropableAreaHorizontal: React.FC<DropableAreaHorizontalProps> = ({
  children,
  index,
  parentId,
  acceptedItemTypes,
}) => {
  let createNewRow = true;
  const { dropField, fields } = useContext(CanvasContext);
  const { dependenciesMode } = useContext(DependenciesModeContext);
  const [displayDiv, setDisplayDiv] = useState(false);

  const [{ isOver }, drop] = useDrop({
    accept: acceptedItemTypes,
    drop(item: DragItemProps, monitor) {
      if (item.itemType === ItemTypes.CONTAINER) {
        createNewRow = false;
      }
      dropField(item, index, parentId, createNewRow);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  useEffect(() => {
    fields.forEach((field: any) => {
      if (field.itemType === 'footer' && field.id === parentId) {
        setDisplayDiv(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <div
      data-testid={'dropable_' + index + '_' + parentId}
      ref={drop}
      style={{
        display: displayDiv ? 'none' : 'flex',
        alignItems: 'center',
        height: '24px',
        marginLeft: '10px',
        marginRight: '10px',
      }}
    >
      {dependenciesMode === true ? (
        <></>
      ) : (
        <Divider
          style={{
            alignItems: 'center',
            height: '1px',
            color: '#B1B0AF',
            boxShadow: isOver
              ? '-3px 0px 3px #93F100, 3px 0px 3px #93F100'
              : 'none',
          }}
        >
          {
            <PlusCircleOutlined
              style={{ color: isOver ? '#93F100' : '#B1B0AF' }}
            />
          }
        </Divider>
      )}

      {children}
    </div>
  );
};

export default DropableAreaHorizontal;
