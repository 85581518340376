import React, { useState, useContext, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes } from './itemTypes';
import { DragItemProps } from './IDragItem';
import { CanvasContext } from '../canvasContext';
import { PlusCircleOutlined } from '@ant-design/icons';

interface DropableAreaVerticalProps {
  index: number;
  parentId?: string;
  acceptedItemTypes: ItemTypes[];
}

export const DropableAreaVertical: React.FC<DropableAreaVerticalProps> = ({
  index,
  children,
  parentId,
  acceptedItemTypes,
}) => {
  const { dropField, fields } = useContext(CanvasContext);
  const [displayDiv, setDisplayDiv] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const rowLimit = 6;

  const getItemByIDRecursive = (
    id: string,
    children: DragItemProps[] | undefined
  ): DragItemProps | null => {
    if (children === undefined) {
      return null;
    }

    let found = children.findIndex((item) => item.id === id);

    if (found !== -1) {
      return children[found];
    }

    let result;
    for (let i = 0; result == null && i < children.length; i++) {
      result = getItemByIDRecursive(id, children[i].elements);
    }
    return result;
  };

  const [{ isOver }, drop] = useDrop({
    accept: acceptedItemTypes,
    drop(item: DragItemProps, monitor) {
      if (disabled === false) {
        dropField(item, index, parentId);
      }
    },
    // canDrop: disabled,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  useEffect(() => {
    fields.forEach((field: any) => {
      if (field.itemType === 'footer' && field.id === parentId) {
        setDisplayDiv(true);
      }
    });
    if (parentId) {
      let parent = getItemByIDRecursive(parentId, fields);
      if (parent?.elements && parent?.elements.length < rowLimit) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return disabled ? (
    <></>
  ) : (
    <div
      data-testid={'dropable_' + index + '_' + parentId}
      ref={drop}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '68px',
        width: '32px',
        marginLeft: '10px',
        marginRight: '10px',
      }}
    >
      <PlusCircleOutlined
        style={{ color: disabled ? '#ffffff' : isOver ? '#93F100' : '#B1B0AF' }}
      />
      {/* </Divider> */}

      {children}
    </div>
  );
};

export default DropableAreaVertical;
