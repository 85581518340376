import { getItemByIDRecursive } from '../../components/utils/dataHelper';
import { useState } from 'react';

declare var __TENANT__: string;

export const useInputValidation = ({
  fields,
  setFields,
}): [
  (key: string, value: any, id: string) => void,
  (key: string, status: any, id: string) => void,
  (key: string, value: any, id: string) => void,
  number[]
] => {
  const [errorPages, setErrorPages] = useState<number[]>([]);
  const [validateBNFDependencyChange, checkBNF] =
    __TENANT__ === 'clx'
      ? require('./clx/useBnf').useBnf({
          fields,
        })
      : [];

  const validatePropertyOfFieldOnBlur = (
    key: string,
    value: any,
    id: string
  ) => {
    if (__TENANT__ === 'clx') {
      setValidationErrorOfField(key, checkBNF(key, value, id), id);
    }
  };

  const validatePropertyOfFieldOnChange = (
    key: string,
    value: any,
    id: string
  ) => {
    let validationViolation = false;

    setValidationErrorOfField(key, validationViolation, id);
  };

  const setValidationErrorOfField = (key: string, status: any, id: string) => {
    let newFields = [...fields];
    let fieldToEdit = getItemByIDRecursive(id, newFields);
    let prop = fieldToEdit?.propConfig.find((obj) => {
      return obj.key === key;
    });
    if (fieldToEdit !== null && prop !== undefined) {
      prop.validationError = status;
    }
    setFields(newFields);
  };

  return [
    validatePropertyOfFieldOnBlur,
    setValidationErrorOfField,
    validatePropertyOfFieldOnChange,
    errorPages,
  ];
};

export default useInputValidation;
