import { Input } from 'antd';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { CanvasContext } from '../../../canvasContext';
import { DragItemProps } from '../../../core/IDragItem';
import { IStrings } from '../../../core/IStrings';
import '../../../styles/ComponentPropertyEditor.css';

interface StringEditorProps {
  label: string;
  placeholder: string;
  value?: string;
  dataKey: string;
  disabled: boolean;
  fields?: DragItemProps[];
  maxLength?: number;
}

export const StringEditor: FunctionComponent<StringEditorProps> = ({
  label,
  value,
  dataKey,
  placeholder,
  disabled,
  maxLength,
  fields,
}) => {
  const { setPropOfActiveField } = useContext(CanvasContext);

  useEffect(() => {}, []);

  return (
    <>
      <label className="stringEditor">{label}</label>
      <Input
        placeholder={IStrings[placeholder]}
        value={value}
        disabled={disabled}
        onChange={(e: any) => {
          setPropOfActiveField(dataKey, e.target.value);
        }}
        data-testid={'stringEditor' + label}
        // maxLength={maxLength}
      />
      )
    </>
  );
};

export default StringEditor;
