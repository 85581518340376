import UserService from '../auth/UserService';
const BACKEND_VERSION_PATH =
  process.env.REACT_APP_BACKEND_VERSION_PATH ?? '/v1';
const BACKEND_URL =
  (process.env.REACT_APP_BACKEND_URL ?? 'api').concat(BACKEND_VERSION_PATH) ;

function getHeaders() {
  let headers = new Headers();
  headers.append('Authorization', `Bearer ${UserService.getToken()}`);
  headers.append('Content-Type', 'application/json');
  return headers;
}

export function getConfig(projectname, version) {
  return fetch(`${BACKEND_URL}/project/${projectname}/config/${version}`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then((response) => response.json())
    .catch((error) => {
      return error;
    });
}

export function putConfig(projectname, editorConfig, name, description) {
  return fetch(`${BACKEND_URL}/project/${projectname}/config`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify({
      editorConfig: editorConfig,
      name: name,
      description: description,
    }),
  })
}

export function patchConfig(projectname, version, editorConfig) {
  return fetch(`${BACKEND_URL}/project/${projectname}/config/${version}`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: editorConfig,
  })
    .then((response) => response)
    .catch((error) => {
      return error;
    });
}

export function getConfigList(projectname) {
  return fetch(`${BACKEND_URL}/project/${projectname}/config`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then((response) => response.json())
    .catch((error) => {
      return error;
    });
}

export function postDuplicate(
  projectname,
  version,
  newConfigName,
  newConfigDescription
) {
  return fetch(
    `${BACKEND_URL}/project/${projectname}/config/${version}/duplicate`,
    {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        newConfigName: newConfigName,
        newConfigDescription: newConfigDescription,
      }),
    }
  ).then((response) => response);
}

export function getExportConfig(projectname, version) {
  return fetch(
    `${BACKEND_URL}/project/${projectname}/config/${version}/export`,
    {
      method: 'GET',
      headers: getHeaders(),
    }
  )
    .then((response) => response)
    .catch((error) => {
      return error;
    });
}

export function postImportConfig(projectname, config) {
  return fetch(`${BACKEND_URL}/project/${projectname}/config/import`, {
    method: 'POST',
    headers: getHeaders(),
    body: config,
  })
    .then((response) => response)
    .catch((error) => {
      return error;
    });
}
export function deleteConfig(projectname, version) {
  return fetch(`${BACKEND_URL}/project/${projectname}/config/${version}`, {
    method: 'DELETE',
    headers: getHeaders(),
  })
    .then((response) => response)
    .catch((error) => {
      return error;
    });
}

export function postProject(projectname, status, description) {
  return fetch(`${BACKEND_URL}/project/${projectname}`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      status: status,
      description: description,
    }),
  })
    .then((response) => response)
    .catch((error) => {
      return error;
    });
}

export function deleteProject(projectname) {
  return fetch(`${BACKEND_URL}/project/${projectname}`, {
    method: 'DELETE',
    headers: getHeaders(),
  })
    .then((response) => response)
    .catch((error) => {
      return error;
    });
}

export function getProjects() {
  return fetch(`${BACKEND_URL}/project/`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then((response) => response.json())
    .catch((error) => {
      return error;
    });
}

export function approveConfig(projectname, version) {
  return fetch(
    `${BACKEND_URL}/project/${projectname}/config/${version}/approve`,
    {
      method: 'PATCH',
      headers: getHeaders(),
    })
    .then((response) => response)
}

export function rejectConfig(projectname, version) {
  return fetch(
    `${BACKEND_URL}/project/${projectname}/config/${version}/reject`,
    {
      method: 'PATCH',
      headers: getHeaders(),
    }
  )
    .then((response) => response)
    .catch((error) => {
      return error;
    });
}

export function activateConfig(projectname, version) {
  return fetch(
    `${BACKEND_URL}/project/${projectname}/config/${version}/activate`,
    {
      method: 'PATCH',
      headers: getHeaders(),
    }
  )
    .then((response) => response)
    .catch((error) => {
      return error;
    });
}

export function deactivateConfig(projectname, version) {
    return fetch(
        `${BACKEND_URL}/project/${projectname}/config/${version}/deactivate`,
        {
            method: 'PATCH',
            headers: getHeaders(),
        }
    )
        .then((response) => response)
        .catch((error) => {
            return error;
        });
}
