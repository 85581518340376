import React, { FunctionComponent } from 'react';
import { Button, Col, Row, Tooltip, Typography } from 'antd';
import {
  InfoCircleOutlined,
  MinusOutlined,
  MenuOutlined,
} from '@ant-design/icons';

interface DropdownItem {
  textInput: string;
  valueInput: string;
  infoInput: string;
  index?: number;
}

interface DropdownElement {
  key: string;
  label: string;
  value: string;
  info: string;
}

interface OptionCardProps {
  index: number;
  element: DropdownElement;
  handleClick: (el: object, id: number) => void;
  handleDelete: (id: number) => void;
  activeItem?: DropdownItem;
  draggable?: boolean;
}

export const OptionCard: FunctionComponent<OptionCardProps> = ({
  element,
  index,
  handleClick,
  handleDelete,
  activeItem,
  draggable,
}) => {
  const { Text } = Typography;

  return (
    <Row
      onClick={() => handleClick(element, index)}
      style={{ lineHeight: 1.8 }}
    >
      {draggable && (
        <Col span={1}>
          <MenuOutlined />
        </Col>
      )}
      <Col
        span={21}
        style={{
          fontWeight:
            activeItem && activeItem.index === index ? 'bold' : 'normal',
        }}
      >
        {element.label} <Text type="secondary"> » {element.key}</Text>
        {element.info && (
          <>
            <br />
            <Text type="secondary">
              <InfoCircleOutlined />
              {element.info}
            </Text>
          </>
        )}
      </Col>
      <Col span={2}>
        <Button
          className="listRemoveButton"
          size="small"
          shape="circle"
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(index);
          }}
          icon={<MinusOutlined style={{ margin: '0px' }} />}
          data-testid="inputDropdownDelBtn"
        />
      </Col>
    </Row>
  );
};
export default OptionCard;
