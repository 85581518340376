import React from 'react';
import { TabBar } from './tabBar';

interface FormCanvasProps {}

export const FormCanvas: React.FC<FormCanvasProps> = ({ children }) => {
  return (
    <div style={{ height: 300, width: '100%' }}>
      <TabBar></TabBar>
    </div>
  );
};

export default FormCanvas;
