const nearley = require('nearley');
const grammar = require('./grammar.js');

export function validateExpressionSyntax(textToValidate) {
  const parser = new nearley.Parser(nearley.Grammar.fromCompiled(grammar));
  try {
    parser.feed(textToValidate);
    return parser.results;
  } catch (parseError) {
    return parseError;
  }
}
