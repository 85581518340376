import { Input, Radio } from 'antd';
import React, { FunctionComponent, useContext } from 'react';
import { CanvasContext } from '../../../canvasContext';
import { IStrings } from '../../../core/IStrings';
import '../../../styles/ComponentPropertyEditor.css';

const SLIDERTYPES = {
  CONTINUOUS: 'Continuous',
  RESTRICTED: 'Restricted',
  DISCRETE: 'Discrete',
};

interface SliderEditorProps {
  label: string;
  value?: string;
  dataKey: string;
  options?: { key: string; value: string }[];
}

export const SliderEditor: FunctionComponent<SliderEditorProps> = ({
  label,
  value,
  dataKey,
  options,
}) => {
  const { setPropOfActiveField } = useContext(CanvasContext);
  let newValue = value ? JSON.parse(value) : '';
  let slider = SLIDERTYPES.CONTINUOUS; // default Value is the first option ('Continuous')
  let step = '';

  if (newValue.length > 0) {
    slider = newValue[0].sliderType;
    step = newValue[0].step;
  }

  const onChange = (key: any, newvalue: any) => {
    const specialCharRegex = new RegExp('^\\d+$');
    if (key === 'radio') slider = newvalue;
    if (newvalue && specialCharRegex.test(newvalue)) {
      step = newvalue.toString();
    } else {
      step = '';
    }
    let array: any[] = [];
    array.splice(0, 0, {
      sliderType: slider,
      step:
        slider === SLIDERTYPES.RESTRICTED
          ? null
          : slider === SLIDERTYPES.DISCRETE
          ? step
          : '',
    });

    setPropOfActiveField(dataKey, JSON.stringify(array));
  };

  return (
    <>
      <label className="stringEditor">{label}</label>
      <Radio.Group
        onChange={(e: any) => {
          onChange('radio', e.target.value);
        }}
        value={slider}
        defaultValue={options && options[0].value}
      >
        {options?.map((option) => (
          <Radio key={option.key} value={option.value}>
            {IStrings[option.value]}
          </Radio>
        ))}
      </Radio.Group>

      <br />
      <label className="stringEditor">{IStrings.Step}</label>
      <Input
        placeholder={IStrings.Input_Step}
        value={step}
        disabled={
          slider === SLIDERTYPES.RESTRICTED || slider === SLIDERTYPES.CONTINUOUS
        }
        onChange={(e: any) => {
          onChange('input', e.target.value);
        }}
        data-testid={'radioEditorInputOne' + label}
        // maxLength={30}
      />
      <br />
    </>
  );
};

export default SliderEditor;
