import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useDrop } from 'react-dnd';
import { v4 as uuidv4 } from 'uuid';
import { CanvasContext } from '../canvasContext';
import DependenciesDragSource from '../core/dependenciesDragSource';
import { DependenciesModeContext } from '../core/dependenicesModeContext';
import DraggableWrapper from '../core/draggableWrapper';
import { DropableAreaHorizontal } from '../core/dropableAreaHorizontal';
import { DependencyProps } from '../core/IDependency';
import { DragItemProps, PropConfig } from '../core/IDragItem';
import { IStrings } from '../core/IStrings';
import { ItemTypes } from '../core/itemTypes';
import DependencyArrow from './dependencyArrow';
import { DependencyIcon } from './dependencyIcon';

export const Container: FunctionComponent<DragItemProps> = ({ ...props }) => {
  const {
    activeField,
    setActiveField,
    dropDependency,
    dependenciesOfActivePage,
  } = useContext(CanvasContext);
  const { dependenciesMode, activeDependency } = useContext(
    DependenciesModeContext
  );
  const { children, ...container } = props;
  const [dependenciesOfContainer, setDependenciesOfContainer] = useState<
    DependencyProps[]
  >([]);

  const [, drop] = useDrop({
    accept: ItemTypes.DEPENDENCY,
    drop(dropItem: DependencyProps, monitor) {
      // returns if drop already happend on child; ensures that only one dependency is created
      // https://codesandbox.io/s/github/react-dnd/react-dnd/tree/gh-pages/examples_decorators_ts/03-nesting/drop-targets?from-embed=&file=/src/Dustbin.tsx:2032-2049
      const hasDroppedOnChild = monitor.didDrop();
      if (hasDroppedOnChild) {
        return;
      }
      activeField && dropDependency(activeField, dropItem, container.id);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  function renderDependencyArrows() {
    let dependencyCounter = 0;
    return dependenciesMode ? (
      dependenciesOfContainer.map((dependency) => {
        let calculatedOffset = dependencyCounter * 10; // use different offsets (x * 10) for each dependency to avoid the arrows to lay above each other
        dependencyCounter = dependencyCounter + 1; // count the depedency to calculate the offset
        return (
          <DependencyArrow
            dependency={dependency}
            // item={item}
            verticalOffset={calculatedOffset}
          ></DependencyArrow>
        );
      })
    ) : (
      <></>
    );
  }

  //TODO move to util module
  function renderDependencyIcon() {
    return dependenciesOfActivePage &&
      dependenciesOfActivePage.find(
        (dependency) =>
          dependency.dependentId === props.id ||
          dependenciesOfActivePage.find(
            (dependency) => dependency.superiorId === props.id
          )
      ) ? (
      <DependencyIcon
        itemId={props.id}
        dependenciesMode={dependenciesMode}
      ></DependencyIcon>
    ) : null;
  }
  //TODO move to util module
  function getBorderColor() {
    if (props.id === activeField?.id) {
      return '#93F100';
    } else if (dependenciesMode) {
      return dependenciesOfActivePage?.find(
        (dependency) =>
          dependency.superiorId === activeField?.id &&
          dependency.dependentId === props.id
      ) ||
        dependenciesOfActivePage?.find(
          (dependency) =>
            dependency.dependentId === activeField?.id &&
            dependency.superiorId === props.id
        )
        ? 'blue'
        : '#B1B0AF';
    }
    return '#B1B0AF';
  }

  function handleClick(e: any) {
    setActiveField(container);
    e.stopPropagation();
  }

  function findHideContainerName(propsConfig) {
    return propsConfig.key === 'hideContainerName';
  }

  function showContainerName() {
    return !container?.propConfig.find(findHideContainerName)?.value;
  }

  const containerName = (container: DragItemProps): string => {
    const labelProp: PropConfig | undefined = container?.propConfig.find(
      (obj) => obj.key === 'label'
    );
    const defaultContainerName: string = labelProp?.label
      ? IStrings[labelProp?.label]
      : labelProp?.label;

    return labelProp?.value
      ? labelProp?.value
      : defaultContainerName;
  };

  useEffect(() => {
    let newDependenciesOfContainer: DependencyProps[] = [];

    dependenciesOfActivePage?.forEach((dependency) => {
      if (dependency.superiorId === container.id) {
        newDependenciesOfContainer.push(dependency);
      }
    });
    setDependenciesOfContainer(newDependenciesOfContainer);
  }, [dependenciesOfActivePage, activeField, activeDependency]);

  return (
    <DraggableWrapper {...container}>
      <div
        id={container.id}
        ref={drop}
        onClick={handleClick}
        style={{
          marginBottom: '10px',
          marginTop: '10px',
          marginLeft: '10px',
          marginRight: '10px',
          padding: '10px',
          backgroundColor: '#ffffff',
          border: '1px',
          borderStyle: 'solid',
          borderColor: container.id === activeField?.id ? '#93F100' : '#B1B0AF',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            maxWidth: '70%',
          }}
        >
          {dependenciesMode && container.id === activeField?.id ? (
            <DependenciesDragSource
              superiorId={activeField && activeField.id}
              type={ItemTypes.DEPENDENCY}
              dependentId={container.id}
              dependencyId={uuidv4()}
              dependencyName=""
              operator=""
              threshold=""
              dependencyColor=""
            ></DependenciesDragSource>
          ) : (
            <></>
          )}
          {showContainerName() ? <div>{containerName(container)}</div> : <></>}
        </div>

        {renderDependencyIcon()}

        <DropableAreaHorizontal
          parentId={container.id}
          index={0}
          acceptedItemTypes={[
            ItemTypes.ITEM,
            ItemTypes.CONTAINER,
            ItemTypes.ROW,
          ]}
        />
        {children !== 0 && children}
        {renderDependencyArrows()}
      </div>
    </DraggableWrapper>
  );
};

export default Container;
