import React, { FunctionComponent, useContext } from 'react';
import { BorderOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { CanvasContext } from '../canvasContext';
import { IStrings } from '../core/IStrings';
import { getItemByIDRecursive } from './utils/dataHelper';

export interface DependencyIconProps {
  itemId: string;
  dependenciesMode: boolean;
}

export const DependencyIcon: FunctionComponent<DependencyIconProps> = (
  props
) => {
  const { dependenciesOfActivePage, fields } = useContext(CanvasContext);
  function getText(typOfId) {
    let occurence;
    if (typOfId === 'superiorId') {
      occurence =
        dependenciesOfActivePage &&
        dependenciesOfActivePage.filter(
          (dependency) => dependency.superiorId === props.itemId
        );
    } else if (typOfId === 'dependentId') {
      occurence =
        dependenciesOfActivePage &&
        dependenciesOfActivePage.filter(
          (dependency) => dependency.dependentId === props.itemId
        );
    }

    if (!occurence || occurence.length === 0) {
      return null;
    }

    return (
      <>
        <hr />
        {occurence?.map((dependency) => (
          <>
            <span style={{ fontWeight: 'bold' }}>
              {dependency.dependencyName}
            </span>
            <br />
            <span>
              {(getItemByIDRecursive(
                dependency.superiorId,
                fields
              )?.propConfig.find((obj) => {
                return obj.key === 'placeholder';
              })?.value ||
                getItemByIDRecursive(dependency.superiorId, fields)?.title) +
                ' '}
            </span>
            <ArrowRightOutlined />
            <span>
              {' ' +
                (getItemByIDRecursive(
                  dependency.dependentId,
                  fields
                )?.propConfig.find((obj) => {
                  return obj.key === 'placeholder';
                })?.value ||
                  getItemByIDRecursive(dependency.dependentId, fields)?.title)}
            </span>
            <br />
            {dependency.operator && (
              <span
                style={{
                  display: 'block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <b>{IStrings.Dependency_Condition_Text + ' '}</b>
                <i>{dependency.operator + ' '}</i>
                {dependency.threshold && <i>{dependency.threshold}</i>}
                <br />
                {dependency.action && (
                  <>
                    <b>{IStrings.Dependency_Action_Text + ' '}</b>
                    <i>{dependency.action + ' '}</i>
                    {dependency.positiveEffectValue && (
                      <i>{dependency.positiveEffectValue}</i>
                    )}
                  </>
                )}
              </span>
            )}
            <hr />
          </>
        ))}
      </>
    );
  }

  function isStart() {
    return (
      dependenciesOfActivePage &&
      dependenciesOfActivePage.find(
        (dependency) => dependency.superiorId === props.itemId
      )
    );
  }
  function isEnd() {
    return (
      dependenciesOfActivePage &&
      dependenciesOfActivePage.find(
        (dependency) => dependency.dependentId === props.itemId
      )
    );
  }

  function getBoxWithOptionalTooltip(typOfId) {
    if (getText(typOfId) !== null) {
      return (
        <Tooltip
          placement="left"
          arrowPointAtCenter={false}
          title={getText('superiorId')}
        >
          <BorderOutlined
            style={{ backgroundColor: isStart() ? '#93F100' : 'none' }}
          />
        </Tooltip>
      );
    } else {
      return (
        <BorderOutlined
          style={{ backgroundColor: isStart() ? '#93F100' : 'none' }}
        />
      );
    }
  }

  function renderDependencIcon(isDependencyModeActiv: boolean) {
    if (isDependencyModeActiv) {
      return null;
    } else {
      return (
        <div>
          {getBoxWithOptionalTooltip('superiorId')}
          <ArrowRightOutlined />
          <Tooltip
            placement="left"
            arrowPointAtCenter={false}
            title={getText('dependentId')}
            overlayInnerStyle={{ width: '400px' }}
            overlayStyle={{ width: '400px' }}
          >
            <BorderOutlined
              style={{ backgroundColor: isEnd() ? '#93F100' : 'none' }}
            />
          </Tooltip>
        </div>
      );
    }
  }
  return renderDependencIcon(props.dependenciesMode);
};
export default DependencyIcon;
