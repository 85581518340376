import { Select } from 'antd';
import React, { FunctionComponent, useContext } from 'react';
import { CanvasContext } from '../../../canvasContext';
import '../../../styles/ComponentPropertyEditor.css';
import { IStrings } from '../../../core/IStrings';

const { Option } = Select;

interface SelectMenuEditorProps {
  label: string;
  placeholder: string;
  value?: string;
  dataKey: string;
  options?: { key: string; value: string }[];
}

export const SelectMenuEditor: FunctionComponent<SelectMenuEditorProps> = ({
  label,
  value,
  dataKey,
  placeholder,
  options,
}) => {
  const { setPropOfActiveField } = useContext(CanvasContext);
  return (
    <>
      <label className="stringEditor">{label}</label>
      <Select
        className="select"
        placeholder={IStrings[placeholder]}
        style={{ width: '100%' }}
        onChange={(selectValue: any) => {
          setPropOfActiveField(dataKey, selectValue);
        }}
      >
        {options?.map((option) => (
          <Option key={option.key} value={option.value}>
            {option.key}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default SelectMenuEditor;
