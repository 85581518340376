export let AsbStrings = {
  en: {
    Add_SAR_Action_label:
      'Add one of the following SAR functionalities as an action:',
    Use_SAR: 'Use Save and Retrieve (SAR)',
    Button_Action: 'Button Action',
    Button_Variant: 'Button Variant',
    Link: 'Link',
    Navigation_Buttons: 'Navigation Buttons',
    Page_Stepper: 'Page Stepper',
    Source_of_Incomes: 'Source of incomes',
    Conditional_Dropdown_Menu: 'Conditional Dropdown Menu',
    Superior_Label: 'superior dropdown label',
    Superior_FieldName: 'superior dropdown fieldname',
    Dependent_Label: 'dependent dropdown label',
    Dependent_FieldName: 'dependent dropdown fieldname',
    Validation_Collapse_Title: 'Field Validations',
    Validation_Required: 'Required',
    Validation_Datatype: 'Data type',
    Validation_Pattern: 'Pattern',
    Validation_DateRestriction: 'Time period',
    Validation_disableFuture: 'Allow only past dates',
    Validation_disablePast: 'Allow only future dates',
    Validation_adult: 'Allow only past dates 18 years ago at least',
    Validation_Value: 'Value',
    Validation_Length: 'Length',
    Validation_Selection: 'Selection',
    Validation_Minimum: 'Mininum',
    Validation_Maximum: 'Maximum',
    Validation_minLength: 'Minimal Length',
    Validation_maxLength: 'Maximal Length',
    Validation_minItems: 'Minimal Items',
    Validation_maxItems: 'Maximal Items',
    Validation_enum: 'Allowed Values',
    Validation_format: 'Expected Format',
    Validation_negate: 'Negate',
    Superior_Dropdown_Label: 'Label Superior Dropdown',
    Superior_Dropdown_FieldName: 'Fieldname Superior Dropdown',
    Dependent_Dropdown_Label: 'Label Dependent Dropdown',
    Dependent_Dropdown_FieldName: 'Fieldname Dependent Dropdown',
    Listvalue_to_fill: 'Listvalue to fill',
    multiline: 'multiline input',
    Page_content_is_shown_in_summary: 'Page content is shown in summary',
    Thank_You_DefaultValue: 'Thank you! We received your data.',
    Left: 'Left',
    Center: 'Center',
    Right: 'Right',
    S: 'S',
    M: 'M',
    L: 'L',
    Mr: 'Mr',
    Ms: 'Ms',
    other: 'other',
    'no specification': 'no specification',
    Input_Mark: 'Input Mark',
    Select_Type: 'Select Unit',
    Select: 'Select',
    Input_Step: 'Input Step',
    Page: 'Page',
    Option_Label: 'Option label',
    Option_Value: 'Option value',
    Option_Info: 'Option info',
    Mapping_Client: 'Fieldname (Client)',
    Mapping_Endpoint: 'Fieldname (Endpoint)',
    Mapping_Value: 'Static value',
    Input_Min_Value: 'Input Min Value',
    Input_Max_Value: 'Input Max Value',
    Subheader: 'Subheader',
    Empty_Page: 'This page is empty. Add Fields before creating dependencies.',
    Label: 'Label',
    Value: 'Value',
    Choose_Project: 'Choose an existing project or create a new project',
    Created: 'Created',
    LastEdit: 'Last edit',
    Unknown: 'unknown',
    Action: 'Action',
    Actions: 'Actions',
    Edit: 'Edit',
    View: 'View',
    Delete: 'Delete',
    LogIn: 'Login',
    LogOut: 'Log Out',
    Duplicate: 'Duplicate',
    NewForm: 'Create new Form',
    Description: 'Description',
    Name: 'Name',
    Return: 'Return',
    Save: 'Save',
    Release: 'Approve',
    Components: 'Components',
    Layout: 'Layout',
    Navigation: 'Navigation',
    TechnicalComponents: 'Technical Components',
    Predefined: 'Predefined',
    Input: 'Input',
    Confirmation: 'Confirmation',
    Technical: 'Technical',
    Container: 'Container',
    EnableableContainer: 'Enableable container',
    Text_Area: 'Text Area',
    Header: 'Header',
    Salutation: 'Salutation',
    Firstname: 'Firstname',
    Lastname: 'Lastname',
    Place_Of_Birth: 'Place Of Birth',
    Birthday: 'Birthday',
    Street: 'Street',
    House_Number: 'House Number',
    Postal_Code: 'Postal Code',
    Town: 'Town',
    Email: 'Email',
    Phone: 'Phone',
    Picture: 'Picture',
    Currency: 'Currency',
    Calendar: 'Calendar',
    CalendarFormat: 'Format',
    Slider: 'Slider',
    IBAN: 'IBAN',
    IbanWithBicAndBankname: 'IBAN with BIC and bankname',
    YearDropdown: 'Year dropdown',
    CountryDropdown: 'Country dropdown',
    YearAmount: 'Total years',
    YearShowPre: 'Show "pre year x" option',
    Radio_Button: 'Radio button',
    Radio_Buttons: 'Radio buttons',
    CheckboxGroup: 'CheckboxGroup',
    Dropdown_Menu: 'Dropdown Menu',
    CountField: 'CountField',
    Checkbox: 'Checkbox',
    ToggleSwitch: 'ToggleSwitch',
    FileUpload: 'FileUpload',
    JPEG: 'JPEG',
    PNG: 'PNG',
    BMP: 'BMP',
    PDF: 'PDF',
    AGB: 'T&C',
    Summary: 'Summary',
    Thank_You: 'Thank You',
    PDF_Download: 'PDF-Download',
    Redirect_Button: 'Redirect Button',
    Timed_Redirect: 'Timed Redirect',
    Confirm_Mail: 'Confirm Mail',
    restField: 'API call field',
    vviField: 'VVI field',
    vviFieldAmex: 'Amex VVI field',
    vviFieldVisa: 'Visa VVI field',
    fireOnlyOnce: 'Prevent repeated calls',
    InputMappings: 'Input values',
    Constants: 'Constants',
    Properties: 'Properties',
    Placeholder: 'Placeholder',
    FieldName: 'FieldName',
    NavButtons: 'Navigation Buttons',
    FieldType: 'FieldType',
    Text: 'Text',
    Multipleselect: 'Multiple select',
    IsClearable: 'Value clearable',
    Additional_Information_Text: 'Additional Information Text',
    Additional_Information_Text_Detail: 'Additional Information Text Detail',
    Editor_Text: 'Editor Text',
    Header_Text: 'Header Text',
    Salutation_Type: 'Salutation Type',
    Display_Type: 'Display Type',
    Required: 'Required',
    Hide_ContainerName: 'Hide Container Name',
    Toggleable: 'Container Content Toggleable',
    Activatable: 'Container Content Activatable',
    Validationrule: 'Validationrule',
    Position: 'Position',
    Size: 'Size',
    Decimal_Separator: 'Decimal Separator',
    Thousand_Separator: 'Thousand Separator',
    label: 'label',
    Min_Value: 'Min Value',
    Max_Value: 'Max Value',
    Default_Value: 'Default Value',
    Marks: 'Marks',
    Select_Currency: 'Select Currency',
    Show_value_label: 'Show value label',
    Display: 'Display',
    Read_Only: 'Read Only',
    Options: 'Options',
    Row: 'Row',
    ButtonLabel: 'Button Label',
    filetype: 'filetype',
    filesize: 'filesize (MegaByte)',
    url: 'url',
    BackendUrl: 'Backend URL',
    Mappings: 'Mappings',
    FireOnEvent: 'When should we trigger the API call',
    onMount: 'Entering the page',
    onUnmount: 'Leaving the page',
    timer: 'timer',
    Sender_Email: 'Sender Email',
    Sender_Password: 'Sender Password',
    SMTP_Host: 'SMTP Host',
    Port: 'Port',
    Send_mail: 'Send E-Mail',
    Mail_Header: 'E-Mail Header',
    Mail_Body: 'E-Mail Body',
    Delete_Text: 'Field has dependencies. Do you want to delete it anyway?',
    Footer: 'Footer',
    German: 'German',
    English: 'English',
    warning_unsaved_changes_title: 'Do you really want to leave the project?',
    warning_unsaved_changes_content:
      'You have unsaved changes. If you leave the project, they will be lost.',
    error_uniqueValue: 'Please enter a unique Value',
    error_parseCSV: 'The uploaded CSV file does not match the required format.',
    error_enterName: 'Please enter a name',
    error_uniqueName: 'Please enter a unique fieldname',
    error_projectNameValidation:
      'The name has to be without any blank. Only the following characters are allowed: a-z, A-Z, 0-9',
    error_name_exists_already:
      'There are fields with the same fieldnames. The following names are used more than once:',
    error_missing_fieldname: 'Please enter a fieldname for ',
    error_missing_email: 'Please fill atleast one Email Component',
    error_Database_unavailable:
      'Database cannot be reached. Please reload the page',
    error_Database_check_connection:
      'Please make sure that a database is connected',
    error_confirmationPage_notConfigured_1:
      'You have not yet configured the confirmation page for this application form.',
    error_confirmationPage_notConfigured_2:
      'This is mandatory, please configure it now.',
    error_fieldName_NotFound: 'Fieldname (Client) could not be found',
    error_fieldName_NotFound_desc:
      'The entered field name (Client) could not be found',
    discard_project_modal_title: 'Do you want to discard the form?',
    discard_project_modal_description: 'All progress will be deleted.',
    discard_project_confirm_message: 'Project successfully deleted.',
    discard_project_error_message:
      'An error has occured, project could not be deleted!',
    Reload: 'Reload',
    Cancel: 'Cancel',
    Test: 'Test',
    ConfirmationPage_title: 'Confirmation Page',
    was_successful: 'was successful',
    failed: 'failed',
    msg_popup_delete_container:
      'When the group is deleted, all components contained in it are also deleted.',
    msg_popup_delete_element: 'Do you want to delete the element?',
    Continuous: 'Continuous',
    Restricted: 'Restricted',
    Discrete: 'Discrete',
    Minimum: 'minimum',
    Maximum: 'maximum',
    Minimum_Abbrev: 'min',
    Maximum_Abbrev: 'max',
    Default: 'Default',
    Step: 'Step',
    Add: 'Add',
    on: 'on',
    off: 'off',
    auto: 'auto',
    On: 'On',
    Off: 'Off',
    UploadCSV: 'Upload from CSV',
    Automatically: 'Automatically',
    Dependency: 'Dependency',
    Dependencies: 'Dependencies',
    Dependency_Card_Name_Label: 'Dependency Name',
    Dependency_Condition_Text: 'If',
    Dependency_Action_Text: 'Then',
    Dependency_Choose_Action: 'choose Action',
    Dependency_Action_Specify_Text: 'Else (Optional)',
    Dependency_Start_Placeholder: 'Start-Placeholder',
    Dependency_End_Placeholder: 'End-Placeholder',
    Dependency_Positive_Effect: 'Positive Effect',
    Dependency_Positive_Effect_Value: 'Positive Effect Value',
    Dependency_Negative_Effect: 'Negative Effect',
    Dependency_Negative_Effect_Value: 'Negative Effect Value',
    Dependency_Description: 'Description',
    Dependency_Delete_Button_Label: 'Delete Dependency',
    Dependency_Threshold: 'Threshold',
    Dependency_Superior: 'Superior',
    Dependency_Superior_Name: 'Superior Name',
    Dependency_Superior_Field: 'Superior Field',
    Dependency_Dependent: 'Dependent',
    Dependency_Dependent_Name: 'Dependent Name',
    Dependency_Dependent_Field: 'Dependent Field',
    Dependency_Operator: 'Operator',
    Dependency_Delete_Button_Popup_Text:
      'Are your sure that you want to delete the Dependency?',

    Dependency_Warning_Tag_Missing_Name: 'Dependency name is missing.',
    Dependency_Warning_Tag_Missing_Action:
      'Action is missing. Dependency may not work as expected',
    Dependency_Warning_Tag_Missing_Operator:
      'Operator is missing. Dependency may not work as expected',
    Dependency_Error_Tag_Missing_Dependent:
      'Dependent is missing. Connect a new Dependent.',
    Dependency_Error_Tag_Missing_Superior:
      'Superior is missing. Connect a new Superior.',
    Dependency_Delete_Successfull_Title: 'Dependency deleted sucessfully!',
    Dependencies_Description_Placeholder:
      'Add a long description of the dependency to tell others why this dependency is necessary. Or just leave some kind words for your colleagues. ',
    Was_Removed: 'was removed',
    Dependencies_Actions: [
      { value: 'show', key: 'Show', specifiable: false },
      { value: 'hide', key: 'Hide', specifiable: false },
      { value: 'fill', key: 'Fill value', specifiable: true, disabled: true },
      {
        value: 'delete',
        key: 'Delete value',
        specifiable: false,
        disabled: true,
      },
      { value: 'select', key: 'Select', specifiable: true, disabled: true },
      {
        value: 'deselect',
        key: 'Deselect',
        specifiable: false,
        disabled: true,
      },
      { value: 'enable', key: 'Enable', specifiable: false, disabled: true },
      { value: 'disable', key: 'Disable', specifiable: false, disabled: true },
    ],
    Dependencies_Operators: [
      { value: 'Contains', key: 'contains', type: 'string' },
      { value: 'ContainsNot', key: 'contains not', type: 'string' },
      { value: '=', key: '=', type: 'numeric' },
      { value: '!=', key: '!=', type: 'numeric' },
      { value: '>', key: '>', type: 'numeric' },
      { value: '>=', key: '>=', type: 'numeric' },
      { value: '<', key: '<', type: 'numeric' },
      { value: '<=', key: '<=', type: 'numeric' },
      { value: 'True', key: 'true', type: 'boolean' },
      { value: 'False', key: 'false', type: 'boolean' },
      { value: 'Checked', key: 'checked', type: 'checkbox' },
      { value: 'Unchecked', key: 'unchecked', type: 'checkbox' },
    ],
    Dependency_unnamed: 'Unnamed Dependency',
    Dataschema: 'Dataschema',
    type: 'Type',
    pattern: 'Regular expression',
    minimum: 'Min value',
    maximum: 'Max value',
    enum: 'Enum',
    PermittedValue: 'Permitted value',
    format: 'Format',
    CreateProject: 'Create project',
    CreateConfig: 'Create configuration',
    ErrorMessage: 'Error message',
    Error: 'Error',
    Export: 'Export',
    ImportConfig: 'Import configuration',
    ErrorExportConfig: 'The configuration could not be retrieved or is faulty.',
    UploadFileAreaText: 'Click or drag file to this area to upload',
    UploadFileAreaHint:
      'Upload an approved configuration for a project in JSON format.',
    HashValue: 'Hash value',
    Import: 'Import',
    ImportConfigEnterHash:
      'Please enter the hash value you received at the export of the configuration file.',
    ImportIntoProject: 'Will be imported into the following project:',
    ErrorImportConfigEmpty: 'No configuration file provided!',
    ErrorImportConfigGeneral: 'is no valid configuration!',
    ErrorImportConfigApprove: 'Configuration has not been approved!',
    ErrorImportConfigRelease: 'Configuration has not been released!',
    SuccessImportConfig: 'configuration imported successfully!',
    ErrorDuplicateConfig: 'Failed to duplicate configuration',
    Activate: 'Activate',
    Deactivate: 'Deactivate',
    Approve: 'Review',
    ErrorConfigApproveSameUser:
      'The configuration can only be approved by another user!',
    Reject: 'Reject',
    ReleaseStatus: 'Release status',
    ReleaseActions: 'Release',
    Activation: 'Activation',
    ConfirmDeleteConfigTitle: 'Confirm delete config',
    ConfirmDeleteConfigText: 'The configuration will be irrevocably deleted.',
    ConfirmActivateConfigTitle: 'Confirm activate config',
    ConfirmActivateConfigText:
      'The configuration will be activated immediately. Any other configuration for this project will be deactivated.',
    ConfirmDeactivateConfigTitle: 'Confirm deactivate config',
    ConfirmDeactivateConfigText:
      'The configuration will be deactivated immediately. The application process for this project will no longer be available.',
    SaveNotAvailableInReview: 'Saving of changes not available in review.',
    CS_NEW: 'New',
    CS_EDIT: 'In progress',
    CS_REVIEW: 'In review',
    CS_APPROVED: 'Approved',
    CS_REJECTED: 'Rejected',
    next: 'Next',
    prev: 'Previous',
    previous: 'Previous',
    custom: 'Redirect',
    confirm: 'Confirm',
    submit: 'Submit',
    outlined: 'Outlined',
    contained: 'Contained',
    navigationButtons: 'Navigation Buttons',
  },
  de: {
    //
    Add_SAR_Action_label:
      'Füge eine der folgenden SAR-Funktionalitäten als Aktion hinzu',
    Use_SAR: '"Save and Retrieve (SAR)" verwenden',
    Button_Action: 'Button Aktion',
    Link: 'Weiterleitung',
    Button_Variant: 'Button Variante',
    Navigation_Buttons: 'Navigation Buttons',
    Page_Stepper: 'Page Stepper',
    Source_of_Incomes: 'Einnahmequelle',
    Conditional_Dropdown_Menu: 'Bedingtes Auswahlmenü',
    Superior_Label: 'Überlegenes Auswahlmenü Anzeigename',
    Superior_FieldName: 'Überlegenes Auswahlmenü Feldname',
    Dependent_Label: 'Abhängiges Auswahlmenü Anzeigename',
    Dependent_FieldName: 'Abhängiges Auswahlmenü Feldname',
    Superior_Dropdown_Label: 'Überlegenes Auswahlmenü Anzeigename',
    Superior_Dropdown_FieldName: 'Überlegenes Auswahlmenü Feldname',
    Dependent_Dropdown_Label: 'Abhängiges Auswahlmenü Anzeigename',
    Dependent_Dropdown_FieldName: 'Abhängiges Auswahlmenü Feldname',
    Listvalue_to_fill: 'Zu befüllendes Listenelement',
    multiline: 'mehrzeiliger input',
    Page_content_is_shown_in_summary: 'Zeige Seiteninhalt in Zusammenfassung',
    Validation_Required: 'Pflichtfeld',
    Validation_Collapse_Title: 'Feld Validierungen',
    Validation_Datatype: 'Daten Typ',
    Validation_Pattern: 'Muster',
    Validation_DateRestriction: 'Zeitraum',
    Validation_disableFuture: 'Nur vergangene Daten erlauben',
    Validation_disablePast: 'Nur zukünftige Daten erlauben',
    Validation_adult: 'Nur vergangene Daten vor mindestens 18 Jahren erlauben',
    Validation_Value: 'Wert',
    Validation_Length: 'Länge',
    Validation_Selection: 'Auswahl',
    Validation_Minimum: 'Minimum',
    Validation_Maximum: 'Maximum',
    Validation_minLength: 'Minimale Länge',
    Validation_maxLength: 'Maximale Länge',
    Validation_minItems: 'Minimale Items',
    Validation_maxItems: 'Maximale Items',
    Validation_enum: 'Erlaubte Werte',
    Validation_format: 'Erwartetes Format',
    Validation_negate: 'Negieren',
    Thank_You_DefaultValue: 'Vielen Dank! Wir haben Ihre Daten erhalten.',
    Left: 'Links',
    Center: 'Mitte',
    Right: 'Rechts',
    S: 'S',
    M: 'M',
    L: 'L',
    Mr: 'Herr',
    Ms: 'Frau',
    other: 'andere',
    'no specification': 'keine Angabe',
    Input_Mark: 'Eingabe Markierung',
    Select_Type: 'Einheit auswählen',
    Select: 'Auswählen',
    Input_Step: 'Eingabeschritte',
    Page: 'Seite',
    Option_Label: 'Option Titel',
    Option_Value: 'Option Value',
    Option_Info: 'Option Info',
    Mapping_Client: 'Feldname (Client)',
    Mapping_Endpoint: 'Feldname (Endpunkt)',
    Mapping_Value: 'Statischer Wert',
    Input_Min_Value: 'minimaler Eingabewert',
    Input_Max_Value: 'maximaler Eingabewert',
    Subheader: 'Zwischenüberschrift',
    Empty_Page:
      'Diese Seite ist noch leer. Füge zuerst Felder hinzu, um Abhängigkeiten erstellen zu können',
    Label: 'Beschriftung',
    Value: 'Wert',
    Choose_Project:
      'Bitte wählen Sie ein existierendes Projekt aus oder erstellen Sie ein neues Projekt',
    Created: 'Erstellt',
    LastEdit: 'Zuletzt bearbeitet',
    Unknown: 'unbekannt',
    Action: 'Aktion',
    Actions: 'Aktionen',
    Edit: 'Bearbeiten',
    View: 'Betrachten',
    Delete: 'Löschen',
    LogIn: 'Login',
    LogOut: 'Log Out',
    Duplicate: 'Kopieren',
    NewForm: 'Neues Formular erstellen',
    Name: 'Name',
    Description: 'Beschreibung',
    Return: 'Zurück',
    Save: 'Speichern',
    Release: 'Freigeben',
    Components: 'Komponenten',
    Layout: 'Layout',
    TechnicalComponents: 'Technische Komponenten',
    Navigation: 'Navigation',
    Predefined: 'Vordefiniert',
    Confirmation: 'Bestätigung',
    Input: 'Input',
    Container: 'Container',
    EnableableContainer: 'Aktivierbarer Container',
    Technical: 'Technisch',
    Text_Area: 'Textfeld',
    Header: 'Überschrift',
    Salutation: 'Anrede',
    Firstname: 'Vorname',
    Lastname: 'Nachname',
    Place_Of_Birth: 'Geburtsort',
    Birthday: 'Geburtsdatum',
    Street: 'Straße',
    House_Number: 'Hausnummer',
    Postal_Code: 'Postleitzahl',
    Town: 'Stadt',
    Email: 'E-Mail',
    Phone: 'Telefon',
    Picture: 'Bild',
    Currency: 'Währung',
    Calendar: 'Kalender',
    CalendarFormat: 'Format',
    Slider: 'Schieberegler',
    IBAN: 'IBAN',
    IbanWithBicAndBankname: 'IBAN mit BIC und Bankname',
    YearDropdown: 'Jahreszahl Dropdown',
    YearAmount: 'Anzahl Jahre',
    YearShowPre: 'Zeige "vor Jahr X" Option',
    CountryDropdown: 'Land Dropdown',
    Radio_Button: 'Optionsschaltfläche',
    Radio_Buttons: 'Optionsschaltflächen',
    CheckboxGroup: 'Kontrollkastengruppe',
    Dropdown_Menu: 'Auswahlmenü',
    CountField: 'Zählfeld',
    Checkbox: 'Kontrollkasten',
    ToggleSwitch: 'Knopfschalter',
    FileUpload: 'Datei Hochladen',
    JPEG: 'JPEG',
    PNG: 'PNG',
    BMP: 'BMP',
    PDF: 'PDF',
    AGB: 'AGB',
    Summary: 'Zusammenfassung',
    Thank_You: 'Dankeschön',
    PDF_Download: 'PDF-Download',
    Redirect_Button: 'Weiterleitungsknopf',
    Timed_Redirect: 'Zeitliche Weiterleiten',
    Confirm_Mail: 'Bestättigungsemail',
    restField: 'API Aufruf Feld',
    vviField: 'VVI Feld',
    vviFieldAmex: 'Amex VVI Feld',
    vviFieldVisa: 'Visa VVI Feld',
    fireOnlyOnce: 'Wiederholtes Absenden verhindern',
    InputMappings: 'Eingabewerte',
    Constants: 'Konstanten',
    Properties: 'Eigenschaften',
    Placeholder: 'Platzhalter',
    FieldName: 'Feld Name',
    NavButtons: 'Navigation Buttons',
    FieldType: 'Feld Typ',
    Text: 'Text',
    Multipleselect: 'Mehrfachauswahl',
    IsClearable: 'Wert löschbar',
    Additional_Information_Text: 'Zusätzliche Informationen',
    Additional_Information_Text_Detail: 'Zusätzliche Informationen Details',
    Editor_Text: 'Editor Text',
    Header_Text: 'Überschrift',
    Salutation_Type: 'Begrüßung',
    Display_Type: 'Darstellungsweise',
    Required: 'Verpflichtend',
    Hide_ContainerName: 'Kontainername verstecken',
    Toggleable: 'Kontainerinhalt Ein- und Ausklappbar',
    Activatable: 'Kontainerinhalt aktivierbar',
    Validationrule: 'Überprüfungsregel',
    Position: 'Position',
    Size: 'Größe',
    Decimal_Separator: 'Dezimal Trennzeichen',
    Thousand_Separator: 'Tausender Trennzeichen',
    label: 'Text',
    Min_Value: 'Min Wert',
    Max_Value: 'Max Wert',
    Default_Value: 'Standardwert',
    Marks: 'Markierungen',
    Select_Currency: 'Währungsauswahl',
    Show_value_label: 'Zeige Werte',
    Display: 'Anzeigen',
    Read_Only: 'Schreibgeschützt',
    Options: 'Optionen',
    Row: 'Reihe',
    ButtonLabel: 'Knopfbeschriftung',
    filetype: 'Datei Typ',
    filesize: 'Datei Größe (MegaByte)',
    url: 'url',
    BackendUrl: 'Backend URL',
    Mappings: 'Mappings',
    FireOnEvent: 'Wann soll der API-Aufruf abgesetzt werden',
    onMount: 'Betreten der Seite',
    onUnmount: 'Verlassen der Seite',
    timer: 'Timer',
    Sender_Email: 'Sender E-Mail',
    Sender_Password: 'Passwort',
    SMTP_Host: 'SMTP Host',
    Port: 'Port',
    Send_mail: 'E-Mail senden',
    Mail_Header: 'E-Mail Überschrift',
    Mail_Body: 'E-Mail Text',
    Delete_Text: 'Feld hat Abhängigkeiten. Wollen Sie es trotzdem löschen?',
    Footer: 'Fußnote',
    German: 'Deutsch',
    English: 'Englisch',
    warning_unsaved_changes_title: 'Wollen sie das Projekt wirklich Verlassen?',
    warning_unsaved_changes_content:
      'Sie haben nicht gespeicherte Änderungen. Falls sie das Projekt verlassen gehen diese verloren.',
    error_uniqueValue: 'Bitte geben Sie einen einzigartigen Wert ein!',
    error_parseCSV: 'CSV entspricht nicht dem erwarteten Format.',
    error_enterName: 'Bitte geben Sie einen Namen an.',
    error_uniqueName: 'Bitte geben Sie einen einzigartigen Feldnamen an.',
    error_projectNameValidation:
      'Der Name muss ohne Leerzeichen sein. Nur folgende Zeichen dürfen enthalten sein: a-z, A-Z, 0-9',
    error_name_exists_already:
      'Es existieren Felder mit gleichen Feldnamen. Folgende Namen werden mehr als einmal genutzt:',
    error_two_fields_with_no_name:
      'Es existieren mehrere Felder ohne Feldnamen.',
    error_missing_fieldname:
      'Bitte geben Sie einen Feldnamen für ${expression} auf Seite ${expression} ein!',
    error_missing_email:
      'Bitte geben Sie mindestens eine gültige Email Adresse an.',
    error_Database_unavailable:
      'Die Datenbank kann nicht erreicht werden. Bitte laden Sie die Seite neu.',
    error_Database_check_connection:
      'Bitte überprüfen Sie ob die Datenbank verbunden ist.',
    error_confirmationPage_notConfigured_1:
      'Sie haben die Bestätigungsseite zu dieser Antragsstrecke noch nicht konfiguriert. ',
    error_confirmationPage_notConfigured_2:
      'Diese ist zwingend erforderlich, bitte konfigurieren sie sie jetzt.',
    error_fieldName_NotFound: 'Feldname (Client) nicht gefunden',
    error_fieldName_NotFound_desc:
      'Der eingegebene Feldname (Client) konnte im Projekt nicht gefunden werden',
    discard_project_modal_title: 'Wollen Sie die Form verwerfen?',
    discard_project_modal_description: 'Der gesamte Fortschritt geht verloren',
    discard_project_confirm_message: 'Projekt wurde erfolgreich gelöscht.',
    discard_project_error_message:
      'Ein Fehler ist aufgetreten, Projekt konnte nicht gelöscht werden.',
    Reload: 'Neuladen',
    Cancel: 'Abbrechen',
    Test: 'Test',
    ConfirmationPage_title: 'Bestätigungsseite',
    was_successful: 'war erfolgreich',
    failed: 'ist fehlgeschlagen',
    msg_popup_delete_container:
      'Wenn die Gruppe gelöscht wird, werden alle enthaltenen Felder mit gelöscht.',
    msg_popup_delete_element:
      'Sind Sie sicher, dass Sie das Element löschen wollen',
    Continuous: 'Kontinuierlich',
    Restricted: 'Beschränkt',
    Discrete: 'Diskret',
    Minimum: 'minimum',
    Maximum: 'maximum',
    Minimum_Abbrev: 'min',
    Maximum_Abbrev: 'max',
    Default: 'Standard',
    Step: 'Schritt',
    Add: 'Hinzufügen',
    on: 'an',
    off: 'aus',
    auto: 'auto',
    On: 'An',
    Off: 'Aus',
    UploadCSV: 'Upload aus CSV',
    Automatically: 'Automatisch',
    Dependency: 'Abhängigkeit',
    Dependencies: 'Abhängigkeiten',
    Dependency_Card_Name_Label: 'Abhängigkeit Name',
    Dependency_Condition_Text: 'Wenn',
    Dependency_Action_Text: 'Dann',
    Dependency_Choose_Action: 'wähle Aktion',
    Dependency_Action_Specify_Text: 'Sonst (Optional)',
    Dependency_Start_Placeholder: 'Start-Platzhalter',
    Dependency_End_Placeholder: 'Ende-Platzhalter',
    Dependency_Positive_Effect: 'Positiver Effekt',
    Dependency_Positive_Effect_Value: 'Positiver Effekt Wert',
    Dependency_Negative_Effect: 'Negativer Effekt',
    Dependency_Negative_Effect_Value: 'Negativer Effekt Wert',
    Dependency_Description: 'Beschreibung',
    Dependency_Delete_Button_Label: 'Abhängigkeit löschen',
    Dependency_Threshold: 'Grenzwert',
    Dependency_Superior: 'Überlegener',
    Dependency_Superior_Name: 'Überlegener Name',
    Dependency_Superior_Field: 'Überlegenes Feld',
    Dependency_Dependent: 'Abhängiger',
    Dependency_Dependent_Name: 'Abhängiger Name',
    Dependency_Dependent_Field: 'Abhängiges Feld',
    Dependency_Operator: 'Operator',
    Dependency_Delete_Successfull_Title:
      'Abhängigkeit wurde erfolgreich gelöscht!',
    Dependency_Delete_Button_Popup_Text:
      'Sind Sie sicher, dass sie die ausgewählte Abhängigkeit löschen wollen?',
    Dependency_Warning_Tag_Missing_Name: 'Name der Abhängigkeit benötigt.',
    Dependency_Warning_Tag_Missing_Action:
      'Aktion fehlt. Die Abhängigkeit verhält sich vermutlich nicht wie erwartet.',
    Dependency_Warning_Tag_Missing_Operator:
      'Operator fehlt. Die Abhängigkeit verhält sich vermutlich nicht wie erwartet.',
    Dependency_Error_Tag_Missing_Dependent:
      'Abhängiges Feld fehlt. Ein neues abhängiges Feld muss gewählt werden.',
    Dependency_Error_Tag_Missing_Superior:
      'Überlegenes Feld fehlt. Ein neues überlegenes Feld muss gewählt werden.',

    Dependencies_Description_Placeholder:
      'Füge eine Beschreibung hinzu, um andere von der Notwendigkeit dieser Abhängigkeit zu überzeugen. Oder hinterlasse einfach ein paar nette Worte für deine Kollegen. ',
    Was_Removed: 'wurde entfernt',
    Dependencies_Actions: [
      { value: 'show', key: 'anzeigen', specifiable: false },
      { value: 'hide', key: 'ausblenden', specifiable: false },
      { value: 'fill', key: 'füllen', specifiable: true, disabled: true },
      { value: 'delete', key: 'löschen', specifiable: false, disabled: true },
      { value: 'select', key: 'auswählen', specifiable: true, disabled: true },
      {
        value: 'deselect',
        key: 'abwählen',
        specifiable: false,
        disabled: true,
      },
      {
        value: 'enable',
        key: 'aktivieren',
        specifiable: false,
        disabled: true,
      },
      {
        value: 'disable',
        key: 'deaktivieren',
        specifiable: false,
        disabled: true,
      },
    ],
    Dependencies_Operators: [
      { value: 'Contains', key: 'enthält', type: 'string' },
      { value: 'ContainsNot', key: 'enthält nicht', type: 'string' },
      { value: '=', key: '=', type: 'numeric' },
      { value: '!=', key: '!=', type: 'numeric' },
      { value: '>', key: '>', type: 'numeric' },
      { value: '>=', key: '>=', type: 'numeric' },
      { value: '<', key: '<', type: 'numeric' },
      { value: '<=', key: '<=', type: 'numeric' },
      { value: 'True', key: 'wahr', type: 'boolean' },
      { value: 'False', key: 'falsch', type: 'boolean' },
      { value: 'Checked', key: 'ausgewählt', type: 'checkbox' },
      { value: 'Unchecked', key: 'unausgewählt', type: 'checkbox' },
    ],
    Dependency_unnamed: 'Unbenannte Abhängigkeit',
    Dataschema: 'Validierung',
    type: 'Typ',
    pattern: 'Regulärer Ausdruck',
    minimum: 'Minimalwert',
    maximum: 'Maximalwert',
    enum: 'Enum',
    PermittedValue: 'Zulässiger Wert',
    format: 'Format',
    CreateProject: 'Projekt erstellen',
    CreateConfig: 'Konfiguration erstellen',
    ErrorMessage: 'Fehlermeldung',
    Error: 'Fehler',
    Export: 'Exportieren',
    ImportConfig: 'Konfiguration importieren',
    ErrorExportConfig:
      'Die Konfiguration konnte nicht abgerufen werden oder ist fehlerhaft.',
    UploadFileAreaText:
      'Klicken oder Datei in den Bereich bewegen zum Hochladen',
    UploadFileAreaHint:
      'Laden Sie eine freigegebene Konfiguration für eine Antragsstrecke im JSON-Format hoch.',
    HashValue: 'Hashwert',
    Import: 'Importieren',
    ImportConfigEnterHash:
      'Bitte geben Sie den Hashwert ein, den Sie bei dem Export der Konfiguration erhalten haben.',
    ImportIntoProject: 'Wird in folgendes Projekt importiert:',
    ErrorImportConfigEmpty: 'Keine Konfiguration hochgeladen!',
    ErrorImportConfigGeneral: 'ist keine gültige Konfiguration!',
    ErrorImportConfigApprove: 'Konfiguration wurde nicht freigegeben!',
    ErrorImportConfigRelease: 'Konfiguration wurde nicht released!',
    SuccessImportConfig: 'Konfiguration erfolgreich importiert.',
    ErrorDuplicateConfig: 'Duplizieren der Konfiguration fehlgeschlagen',
    Activate: 'Aktivieren',
    Deactivate: 'Deaktivieren',
    Approve: 'Überprüfen',
    ErrorConfigApproveSameUser:
      'Die Konfiguration kann nur von einem anderen Benutzer freigegeben werden!',
    Reject: 'Abweisen',
    ReleaseStatus: 'Freigabestatus',
    ReleaseActions: 'Freigabe',
    Activation: 'Aktivierung',
    ConfirmDeleteConfigTitle: 'Konfiguration löschen bestätigen',
    ConfirmDeleteConfigText: 'Die Konfiguration wird unwiderruflich gelöscht.',
    ConfirmActivateConfigTitle: 'Konfiguration aktivieren bestätigen',
    ConfirmActivateConfigText:
      'Die Konfiguration wird unverzüglich aktiviert. Jede andere Konfiguration für dieses Projekt wird dadurch deaktiviert.',
    ConfirmDeactivateConfigTitle: 'Konfiguration deaktivieren bestätigen',
    ConfirmDeactivateConfigText:
      'Die Konfiguration wird unverzüglich deaktiviert. Die Antragsstrecke für dieses Projekt wird nicht mehr verfügbar sein.',
    SaveNotAvailableInReview:
      'Speichern von Änderungen während der Überprüfung nicht möglich.',
    CS_NEW: 'Neu',
    CS_EDIT: 'In Bearbeitung',
    CS_REVIEW: 'In Überprüfung',
    CS_APPROVED: 'Freigegeben',
    CS_REJECTED: 'Abgewiesen',
    next: 'Weiter',
    prev: 'Zurück',
    previous: 'Zurück',
    custom: 'Weiterleitung',
    confirm: 'Bestätigen',
    submit: 'Submit',
    outlined: 'Umrandet',
    contained: 'Ausgefüllt',
    navigationButtons: 'Navigation Buttons',
  },
};
