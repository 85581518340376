import { DownOutlined, MinusOutlined, UpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { FunctionComponent } from 'react';
import { NavButtonObject } from './ButtonEditor';

interface ButtonListItemProps {
  isSelected: boolean;
  isLastItem: boolean;
  buttons: NavButtonObject[];
  index: number;
  dataKey: string;
  item: NavButtonObject;
  loadButtonToEdit: (buttonItem: NavButtonObject, index: number) => void;
  moveButtonUp: (index: number) => void;
  moveButtonDown: (index: number) => void;
  removeButtonItem: (index: number) => void;
}

export const ButtonListItem: FunctionComponent<ButtonListItemProps> = ({
  isSelected = false,
  isLastItem = false,
  index,
  item,
  loadButtonToEdit,
  moveButtonUp,
  moveButtonDown,
  removeButtonItem,
}) => {
  return (
    <>
      <span style={{ margin: 5 }}>
        <Button
          className="listMoveUpButton"
          size="small"
          shape="circle"
          onClick={() => moveButtonUp(index)}
          icon={<UpOutlined style={{ margin: '0px' }} />}
          data-testid="listEditorUpBtn"
          disabled={index === 0 ? true : false}
        />
        <Button
          className="listMoveDownButton"
          size="small"
          shape="circle"
          onClick={() => {
            moveButtonDown(index);
          }}
          icon={<DownOutlined style={{ margin: '0px' }} />}
          data-testid="listEditorDownBtn"
          disabled={isLastItem}
        />
      </span>
      <span
        style={{
          maxWidth: '60%',
          padding: 5,
          borderColor: isSelected ? '#93f100' : '#d5d5d5',
          borderWidth: 2,
          borderStyle: 'solid',
          borderRadius: 25,
          width: 250,
        }}
        onClick={() => loadButtonToEdit(item, index)}
      >
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {item.label}
        </div>
      </span>
      <Button
        className="listRemoveButton"
        size="small"
        shape="circle"
        onClick={() => {
          removeButtonItem(index);
        }}
        icon={<MinusOutlined style={{ margin: '0px' }} />}
        data-testid="listEditorDelBtn"
      />
    </>
  );
};

export default ButtonListItem;
