import { Input } from 'antd';
import React, { FunctionComponent, useContext } from 'react';
import { CanvasContext } from '../../../../../canvasContext';
import { IStrings } from '../../../../../core/IStrings';
import { VALIDATIONVALUE } from '../constants';

interface TextInputEditorProps {
  value: string;
  validationKey: string;
  disabled?: boolean;
}

export const TextInputEditor: FunctionComponent<TextInputEditorProps> = ({
  value,
  validationKey,
  disabled,
}) => {
  const { setValidationPropOfActiveField } = useContext(CanvasContext);
  return (
    <>
      <label className="stringEditor">{IStrings[validationKey]}</label>
      <Input
        value={value}
        disabled={disabled}
        onChange={(e: any) => {
          setValidationPropOfActiveField(
            validationKey,
            e.target.value,
            VALIDATIONVALUE
          );
        }}
        data-testid={'stringEditor' + validationKey}
      />
    </>
  );
};
