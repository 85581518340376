import { Button, Select } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import downloadZip, { getPageFromConfig } from '../../../api/clx';
import { getConfig } from '../../../api/project';
import { IStrings } from '../../../core/IStrings';

export const ClxDownloadButton = (projectConfig, projectName) => {
  const [selectedPageIndex, setSelectedPageIndex] = useState('All');
  const [activePageConfig, setActivePageConfig] = useState();
  const [activeProjectId, setActiveProjectId] = useState();
  const [pageNames, setPageNames]: any[] = useState([]);
  const { Option } = Select;

  useEffect(() => {
    getConfig(projectName, projectConfig.projectConfig.id)
      .then((result) => {
        setActivePageConfig(result.config.editor_config);
        let names = result.config.editor_config
          .filter((item) => item.title === 'Page')
          .map((item) =>
            item.propConfig.find(
              (propConfigItem) => propConfigItem.key === 'label'
            )
          )
          .map((item, index) => item?.value || index + 1);
        setPageNames(names);
      })
      .catch((error) => {
        console.log('wupsi dupsi');
      });
  }, [projectConfig]);

  function renderDownloadOptions(pageNamesForOptions) {
    return (
      <>
        <Option key={'All'} value={'All'}>
          All (.zip)
        </Option>
        {pageNamesForOptions.map((pageName) => (
          <Option key={pageName} value={pageNames.indexOf(pageName)}>
            {pageName}
          </Option>
        ))}
      </>
    );
  }

  function downloadOnePage() {
    getPageFromConfig(
      projectConfig.projectConfig.id,
      selectedPageIndex,
      pageNames[selectedPageIndex]
    ).catch((error) => console.error(error));
  }

  function downloadSelectOnChange(option) {
    setSelectedPageIndex(option);
  }

  return (
    <>
      <Select
        style={{
          fontSize: '0.9em',
          height: '2.7em',
          width: '8em',
          textAlign: 'center',
          border: '1.5px solid #1990FF',
          color: '#1990FF',
          backgroundColor: '#fff',
        }}
        onChange={downloadSelectOnChange}
        defaultValue={'All'}
      >
        {renderDownloadOptions(pageNames)}
      </Select>
      <Button
        ghost
        type="primary"
        onClick={async () => {
          if (selectedPageIndex === 'All') {
            downloadZip(
              JSON.stringify(activePageConfig),
              projectConfig.projectConfig.project.id,
              projectConfig.projectConfig.project.name
            );
          } else {
            downloadOnePage();
          }
        }}
        data-testid="saveConfigBTN"
      >
        {IStrings.Download}
      </Button>
    </>
  );
};
