import { MinusOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import React, { FunctionComponent, useContext, useState } from 'react';
import { CanvasContext } from '../../../canvasContext';
import { IStrings } from '../../../core/IStrings';
import '../../../styles/ComponentPropertyEditor.css';
interface InputandDropdownProps {
  label: string;
  value?: string;
  dataKey: string;
  options?: { key: string; value: string }[];
}
export const InputandDropdown: FunctionComponent<InputandDropdownProps> = ({
  label,
  value,
  dataKey,
  options,
}) => {
  const { setPropOfActiveField } = useContext(CanvasContext);
  let newValue = value ? JSON.parse(value) : [];
  const [markInput, setMarkInput] = useState<string>('');
  const [markDropdown, setMarkDropdown] = useState<string>('');

  const addMarksOnClick = () => {
    newValue.push({
      label: markInput + ' ' + markDropdown,
      value: markInput,
      mark: markDropdown,
    });

    newValue.forEach(function (part, index) {
      newValue[index].label = newValue[index].value + ' ' + markDropdown;
      newValue[index].mark = markDropdown;
    }, newValue);

    setMarkInput('');
    setMarkDropdown('');
    setPropOfActiveField(dataKey, JSON.stringify(newValue));
  };

  const removeOnClick = (index: any) => {
    newValue.splice(index, 1);
    setPropOfActiveField(dataKey, JSON.stringify(newValue));
  };

  return (
    <>
      <label className="radioEditor">{label}</label>

      {newValue.length !== 0 && (
        <ul className="radioEditorUl">
          {newValue.map((el: any, i: any) => (
            <li key={label + i} className="radioEditorLi">
              {el.label}
              {dataKey === 'options' ? '- ' + el.info : ''}
              <Button
                className="listRemoveButton"
                size="small"
                shape="circle"
                onClick={() => {
                  removeOnClick(i);
                }}
                icon={<MinusOutlined style={{ margin: '0px' }} />}
                data-testid="inputRadioDelBtn"
              />
            </li>
          ))}
        </ul>
      )}

      <Input
        placeholder={IStrings.Input_Mark}
        value={markInput}
        onChange={(e: any) => {
          const specialCharRegex = new RegExp('^\\d+$');
          if (e.target.value && specialCharRegex.test(e.target.value)) {
            setMarkInput(e.target.value);
          } else {
            setMarkInput('');
          }
        }}
        data-testid={'radioEditorInputOne' + label}
      />
      <br />
      <Select
        className="selectButton"
        placeholder={IStrings.Select_Type}
        onChange={(el) => setMarkDropdown(el.toString())}
        value={markDropdown === '' ? undefined : markDropdown}
      >
        {options?.map((option) => (
          <Select.Option key={option.key} value={option.value}>
            {option.value}
          </Select.Option>
        ))}
      </Select>
      <br />
      <Button
        className="deleteButton"
        disabled={markInput ? false : true}
        onClick={() => addMarksOnClick()}
      >
        <span className="deleteButtonSpan">{IStrings.Add}</span>
      </Button>
    </>
  );
};

export default InputandDropdown;
