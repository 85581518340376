import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const AlertModal = () => {
  return Modal.warning({
    title: 'Unauthorized access to Backend',
    icon: <ExclamationCircleOutlined />,
    okText: 'Ok',
    style: {
      width: '100%',
      margin: '0 auto',
    },
    okButtonProps: {
      style: {
        background: '#f4f4f4 0% 0% no-repeat padding-box',
        borderColor: '',
        color: 'rgba(0, 0, 0, 0.65)',
      },
    },
  });
};

export default AlertModal;
