import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useDrop } from 'react-dnd';
import { v4 as uuidv4 } from 'uuid';
import { CanvasContext } from '../canvasContext';
import DependenciesDragSource from '../core/dependenciesDragSource';
import { DependenciesModeContext } from '../core/dependenicesModeContext';
import DraggableWrapper from '../core/draggableWrapper';
import { useResizeObserver } from '../components/utils/useResizeObserver';
import { DependencyProps } from '../core/IDependency';
import { DragItemProps } from '../core/IDragItem';
import { IStrings } from '../core/IStrings';
import { ItemTypes } from '../core/itemTypes';
import DependencyArrow from './dependencyArrow';
import { DependencyIcon } from './dependencyIcon';

export const Item: FunctionComponent<DragItemProps> = ({ ...props }) => {
  const { children, ...item } = props;
  const {
    activeField,
    setActiveField,
    dropDependency,
    dependenciesOfActivePage,
  } = useContext(CanvasContext);
  const { dependenciesMode, activeDependency } = useContext(
    DependenciesModeContext
  );
  const [dependenciesOfItem, setDependenciesOfItem] = useState<
    DependencyProps[]
  >([]);

  const widthTreshold = 200;
  const resizeSubject = useRef(null);
  const [width] = useResizeObserver(resizeSubject);

  function handleClick(e: any) {
    setActiveField(item);
    e.stopPropagation();
  }

  function renderDependencyIcon() {
    return dependenciesOfActivePage &&
      dependenciesOfActivePage.find(
        (dependency) =>
          dependency.dependentId === props.id ||
          dependenciesOfActivePage.find(
            (dependency) => dependency.superiorId === props.id
          )
      ) ? (
      <DependencyIcon itemId={props.id} dependenciesMode={dependenciesMode} />
    ) : null;
  }
  //TODO move function out of item, to use it for container and other comopnents as well
  function renderDependencyArrows() {
    let dependencyCounter = 0;
    return dependenciesMode === true ? (
      dependenciesOfItem.map((dependency) => {
        let calculatedOffset = dependencyCounter * 20; // use different offsets (x * 10) for each dependency to avoid the arrows to lay above each other
        dependencyCounter = dependencyCounter + 1; // count the depedency to calculate the offset
        return (
          <DependencyArrow
            dependency={dependency}
            verticalOffset={calculatedOffset}
          />
        );
      })
    ) : (
      <></>
    );
  }

  function returnItemLabel(): string {
    let placeholder = item?.propConfig.find(
      (obj) => obj.key === 'placeholder'
    )?.value;

    return placeholder
      ? placeholder
      : IStrings[item.internalName || item.title];
  }

  function getBorderColor() {
    if (props.id === activeField?.id) {
      return '#93F100';
    } else if (dependenciesMode) {
      return dependenciesOfActivePage?.find(
        (dependency) =>
          dependency.superiorId === activeField?.id &&
          dependency.dependentId === props.id
      ) ||
        dependenciesOfActivePage?.find(
          (dependency) =>
            dependency.dependentId === activeField?.id &&
            dependency.superiorId === props.id
        )
        ? '#eb9642'
        : '#B1B0AF';
    }
    return '#B1B0AF';
  }

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.DEPENDENCY,
    drop(dropItem: DependencyProps, monitor) {
      //check for id, return an error when ids are equal
      activeField && dropDependency(activeField, dropItem, item.id);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  useEffect(() => {
    let newDependenciesOfItem: DependencyProps[] = [];

    dependenciesOfActivePage?.forEach((dependency) => {
      if (dependency.superiorId === item.id) {
        newDependenciesOfItem.push(dependency);
      }
    });
    setDependenciesOfItem(newDependenciesOfItem);
  }, [dependenciesOfActivePage, activeField, activeDependency]);

  return (
    <DraggableWrapper {...item}>
      <div ref={resizeSubject}>
        <div
          id={item.id}
          ref={drop}
          style={{
            height: '68px',
            margin: '10px',
            paddingLeft: '20px',
            paddingRight: '20px',
            border: '0.3px',
            borderStyle: 'solid',
            boxShadow: isOver
              ? '-3px 0px 3px #93F100, 3px 0px 3px #93F100'
              : 'none',
            borderColor: getBorderColor(),
            backgroundColor: '#F4F5F7',
            display: 'flex',
            alignItems: 'center',
            justifyContent:
              width <= widthTreshold && renderDependencyIcon() === null
                ? 'center'
                : 'space-between',
          }}
          onClick={handleClick}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              maxWidth: '70%',
            }}
          >
            {dependenciesMode === true && item.id === activeField?.id ? (
              <DependenciesDragSource
                superiorId={activeField && activeField.id}
                type={ItemTypes.DEPENDENCY}
                dependentId={item.id}
                dependencyId={uuidv4()}
                dependencyName=""
                operator=""
                threshold=""
                dependencyColor=""
              ></DependenciesDragSource>
            ) : (
              <></>
            )}

            {item.icon && (
              <img
                alt=""
                style={{ width: '32px', height: '32px', color: '#51504E' }}
                src={require('../imgs/' + item.icon)}
              />
            )}
            {width && width >= widthTreshold ? (
              <span
                style={{
                  marginLeft: '10px',
                  color: '#51504E',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {returnItemLabel()}
              </span>
            ) : (
              <></>
            )}
          </div>
          {renderDependencyIcon()}
          {renderDependencyArrows()}
        </div>
      </div>

      {children}
    </DraggableWrapper>
  );
};

export default Item;
