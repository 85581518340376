import React, { useState, createContext, FunctionComponent } from 'react';
import { IStrings } from '../IStrings';

export const LanguageContext = createContext<{
  language: string | undefined;
  setLanguageByCountryCode: (countryCode: string) => void;
}>({
  language: undefined,
  setLanguageByCountryCode: () => {},
});

const LanguageContextProvider: FunctionComponent<any> = ({ children }) => {
  return (
    <LanguageContext.Provider value={useLanguage()}>
      {children}
    </LanguageContext.Provider>
  );
};

const useLanguage = () => {
  const [language, setLanguage] = useState('en');

  function setLanguageByCountryCode(countryCode: string) {
    document.documentElement.lang = countryCode;

    IStrings.setLanguage(countryCode);
    setLanguage(countryCode);
  }

  return { language, setLanguageByCountryCode };
};

export default LanguageContextProvider;
