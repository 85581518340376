import { Col } from 'antd';
import React, { ReactNode, useContext } from 'react';
import { CanvasContext } from '../canvasContext';
import { DependenciesModeContext } from '../core/dependenicesModeContext';
import DropableAreaHorizontal from '../core/dropableAreaHorizontal';
import DropableAreaVertical from '../core/dropableAreaVertical';
import { DragItemProps } from '../core/IDragItem';
import { ItemTypes } from '../core/itemTypes';
import { Container } from './container';
import GridRow from './gridRow';
import { Item } from './item';
import { Page } from './page';

interface TabContentProps {
  tabKey: number;
}

export const TabContent: React.FC<TabContentProps> = ({ tabKey, children }) => {
  const { dependenciesMode } = useContext(DependenciesModeContext);

  function renderItem(
    item: DragItemProps,
    index: number,
    parentId?: string,
    gridSpace?: number
  ) {
    return item.itemType === ItemTypes.PAGE ? (
      <>
        <Page {...item}>
          {item.elements?.length &&
            item.elements.map((element: any, index: number) => {
              return renderItem(element, index, item.id);
            })}
        </Page>
      </>
    ) : item.itemType === ItemTypes.CONTAINER ? (
      <>
        <Container {...item}>
          {item.elements?.length &&
            item.elements.map((element: any, index: number) => {
              return renderItem(element, index, item.id);
            })}
        </Container>
        <DropableAreaHorizontal
          parentId={parentId}
          index={index + 1}
          acceptedItemTypes={[
            ItemTypes.ITEM,
            ItemTypes.CONTAINER,
            ItemTypes.ROW,
          ]}
        />
      </>
    ) : item.itemType === ItemTypes.ROW ? (
      ((gridSpace = 18 / (item.elements?.length ? item.elements?.length : 1)),
      (
        <>
          <GridRow {...item}>
            {item.elements?.length &&
              item.elements.map((element: any, index: number) => {
                return renderItem(element, index, item.id, gridSpace);
              })}
          </GridRow>
          <DropableAreaHorizontal
            parentId={parentId}
            index={index + 1}
            acceptedItemTypes={[
              ItemTypes.ITEM,
              ItemTypes.CONTAINER,
              ItemTypes.ROW,
            ]}
          />
        </>
      ))
    ) : gridSpace ? (
      <>
        <Col span={gridSpace ? gridSpace - 1 : 20}>
          <Item {...item}></Item>
        </Col>
        <Col span={2}>
          {renderDragNDropZone(dependenciesMode, index, parentId)}
        </Col>
      </>
    ) : (
      <>
        <Item {...item}></Item>
        <DropableAreaHorizontal
          parentId={parentId}
          index={index + 1}
          acceptedItemTypes={[ItemTypes.ITEM, ItemTypes.CONTAINER]}
        />
      </>
    );
  }

  function renderDragNDropZone(
    isDependencyModeActive: boolean,
    index: number,
    parentId?: string
  ): ReactNode {
    return isDependencyModeActive === true ? (
      <></>
    ) : (
      <DropableAreaVertical
        parentId={parentId}
        index={index + 1}
        acceptedItemTypes={[ItemTypes.ITEM]}
      />
    );
  }

  return (
    <CanvasContext.Consumer>
      {(value) => {
        return renderItem(value.fields[tabKey], tabKey);
      }}
    </CanvasContext.Consumer>
  );
};
