import { validationPropertiesBNF } from './core/constants/validationProperties';
import { DragItemProps } from './core/IDragItem';
import { IFieldProp } from './core/IFieldProp';
import { PageConfig } from './pageConfig';

let backendFields: DragItemProps[] = [];

export const saveFields = (fields: DragItemProps[]): void => {
  window.localStorage.setItem('fields', JSON.stringify(fields));
};

export const saveJSON = (elements: any): void => {
  window.localStorage.setItem('jsonConfig', elements);
};

export const setBackendFields = (fields: DragItemProps[]): void => {
  backendFields = fields;
};

export const loadFields = (): DragItemProps[] => {
  return backendFields.length !== 0
    ? backendFields
    : [
        {
          ...PageConfig,
          id: '' + Date.now(),
        },
      ];
};

export const loadBNFDependencies = (): IFieldProp[] => {
  let fields = loadFields();
  let bnfProps: IFieldProp[] = [];
  fields.forEach((page) => {
    page.elements?.forEach((row) => {
      row.elements?.forEach((field) => {
        let prop = field.propConfig.find((prop) => {
          return validationPropertiesBNF.includes(prop.key) && prop.value;
        });
        if (prop) {
          bnfProps.push({ key: prop.key, id: field.id, value: prop.value });
        }
      });
    });
  });

  return bnfProps;
};

export const saveActiveField = (field: DragItemProps): void => {
  window.localStorage.setItem('activeField', JSON.stringify(field));
};

export const loadActiveField = (): DragItemProps => {
  if (window.localStorage.getItem('fields') == null) {
    window.localStorage.removeItem('activeField');
  }
  let activeFieldInStorage = window.localStorage.getItem('activeField');
  return activeFieldInStorage !== null ? JSON.parse(activeFieldInStorage) : {};
};
