import * as React from 'react';
import { getItemByIDRecursive } from '../../components/utils/dataHelper';

declare var __TENANT__: string;

export const useDependencyValidation = ({
  fields,
  setFields,
}): [(key: string, value: any, id: string) => void, (value: any) => void] => {
  const [validateBNFDependencyChange] =
    __TENANT__ === 'clx'
      ? require('./clx/useBnf').useBnf({
          fields,
        })
      : [];

  const validateDependeciesOfFieldOnChange = (value: any) => {
    if (__TENANT__ === 'clx') {
      validateBNFDependencyChange(value).forEach((violation) => {
        setDependencyErrorOfField(
          violation.key,
          violation.status,
          violation.id
        );
      });
    }
  };

  const setDependencyErrorOfField = (key: string, status: any, id: string) => {
    let newFields = [...fields];
    let fieldToEdit = getItemByIDRecursive(id, newFields);
    let prop = fieldToEdit?.propConfig.find((obj) => {
      return obj.key === key;
    });
    if (fieldToEdit !== null && prop !== undefined) {
      prop.dependencyError = status;
    }
    setFields(newFields);
  };

  return [setDependencyErrorOfField, validateDependeciesOfFieldOnChange];
};

export default useDependencyValidation;
