import { Button, Input, message, Modal } from 'antd';
import React, { useState } from 'react';
import { postDuplicate } from '../../../api/project';
import { IStrings } from '../../../core/IStrings';

export interface DuplicateConfigModalProps {
  configurationVersion?: number;
  setVisible: (visible: boolean) => void;
  visible: boolean;
  projectName: string | number;
  fetchProjectConfigurations: () => void;
}

const DuplicateConfigModal: React.FC<DuplicateConfigModalProps> = ({
  configurationVersion,
  setVisible,
  visible,
  projectName,
  fetchProjectConfigurations,
}) => {
  const resetState = () => {
    setVisible(false);
  };
  const [newConfigName, setNewConfigName] = useState('');
  const [newConfigDescription, setNewConfigDescription] = useState('');

  const handleOk = async () => {
    if (newConfigName.length === 0) {
      message.error(IStrings.error_enterName);
      return;
    }
    if (!/^[a-zA-Z0-9]{1,255}$/.test(newConfigName)) {
      message.error(IStrings.error_projectNameValidation);
      return;
    }
    await duplicateConfiguration();
  };

  async function duplicateConfiguration() {
    await postDuplicate(projectName, configurationVersion, newConfigName)
      .then((response) => {
        if (!response.ok) {
          throw new Error();
        }
        projectName && fetchProjectConfigurations();
        setVisible(false);
      })
      .catch((error) => {
        message.error(IStrings.ErrorDuplicateConfig);
        setVisible(false);
      });
  }

  return (
    <>
      <Modal
        title={
          configurationVersion &&
          configurationVersion.toString().concat(' ').concat(IStrings.Duplicate)
        }
        visible={visible}
        onOk={handleOk}
        onCancel={resetState}
        footer={[
          <Button key="back" onClick={resetState}>
            {IStrings.Return}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
          >
            {IStrings.Save}
          </Button>,
        ]}
      >
        <label style={{ display: 'block' }}>{IStrings.Name}</label>
        <Input
          placeholder="Name"
          value={newConfigName}
          onChange={(e: any) => {
            setNewConfigName(e.target.value);
          }}
        />
        <Input
          placeholder="Description"
          value={newConfigDescription}
          onChange={(e: any) => {
            setNewConfigDescription(e.target.value);
          }}
        />
      </Modal>
    </>
  );
};

export default DuplicateConfigModal;
