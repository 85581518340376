import React, { FunctionComponent, useContext } from 'react';
import { List, Input, Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import '../../../styles/ComponentPropertyEditor.css';
import { CanvasContext } from '../../../canvasContext';

interface ListEditorProps {
  label: string;
  value?: string;
  dataKey: string;
  disabled: boolean;
}

export const ListEditor: FunctionComponent<ListEditorProps> = ({
  label,
  value = '',
  dataKey,
  disabled,
}) => {
  const { setPropOfActiveField } = useContext(CanvasContext);
  const [listItem, setListItem] = React.useState('');
  let list: string[] = value !== '' ? value?.split(',') : [];
  return (
    <>
      <List
        header={<div>{label}</div>}
        bordered
        dataSource={list}
        className="listEditor"
        renderItem={(item, index) => (
          <List.Item key={item + index} data-testid={'listEditorItem' + index}>
            {item.toUpperCase()}
            <Button
              className="listRemoveButton"
              size="small"
              shape="circle"
              onClick={() => {
                list.splice(index, 1);
                setPropOfActiveField(dataKey, list.join(','));
              }}
              icon={<MinusOutlined style={{ margin: '0px' }} />}
              data-testid="listEditorDelBtn"
            />
          </List.Item>
        )}
      />
      <Input
        value={listItem}
        className="listInput"
        onChange={(event) => {
          setListItem(event.target.value);
        }}
        data-testid={'listEditorListe'}
        disabled={disabled}
      />
      <Button
        className="listButton"
        icon={
          <PlusOutlined
            style={{ margin: '0px', position: 'relative', top: '-1px' }}
          />
        }
        onClick={(e: any) => {
          list.push(listItem);
          setListItem('');
          setPropOfActiveField(dataKey, list.join(','));
        }}
        data-testid={'listEditorBtn'}
      ></Button>
    </>
  );
};
export default ListEditor;
