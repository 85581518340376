import { MenuCardProps } from '../components/menuCard';
import getEditorForTenant from '../tenant';

const config = getEditorForTenant().componentsConfig;
export const COMPONENTSCONFIG: MenuCardProps[] = config.Components;
export const COMPONENTTYPES: ComponentType[] = config.ComponentsType;

export interface ComponentType {
  key: string;
  name: string;
  color: string;
}
