import { Layout } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { DependenciesModeContext } from '../core/dependenicesModeContext';
import ComponentsMenu from './componentsMenu';

const { Sider } = Layout;

const SiderLeft = () => {
  const { dependenciesMode, setDependenciesMode } = useContext(
    DependenciesModeContext
  );
  const [IsCollapsed, setIsCollapsed] = useState(
    dependenciesMode === true ? true : false
  );

  function onCollapseSider() {
    IsCollapsed ? setIsCollapsed(false) : setIsCollapsed(true);
    dependenciesMode && setDependenciesMode(false);
  }

  useEffect(() => {
    dependenciesMode === true ? setIsCollapsed(true) : setIsCollapsed(false);
  }, [dependenciesMode]);

  return (
    <Sider
      collapsible={true}
      collapsed={IsCollapsed}
      collapsedWidth={40}
      onCollapse={() => {
        onCollapseSider();
      }}
      style={{
        width: '100%',
        overflowY: 'auto',
        height: '96vh',
        paddingBottom: '12em',
        background: '#f4f4f4 0% 0% no-repeat padding-box',
        boxShadow: '3px 0px 2px #00000029',
        opacity: '1',
        overflowX: 'hidden',
      }}
      width={'21vw'}
    >
      {IsCollapsed === true ? <></> : <ComponentsMenu />}
    </Sider>
  );
};

export default SiderLeft;
