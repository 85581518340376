import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Input, message, Modal } from 'antd';
import React, { useState } from 'react';
import { IStrings } from '../core/IStrings';
import { ProjectItemProps } from './formDashboard';
import { FolderAddOutlined } from '@ant-design/icons';

const { confirm } = Modal;
const { TextArea } = Input;

export interface ProjectAddButtonProps {
  addProject: (item: ProjectItemProps) => Promise<boolean>;
}

const AddProject: React.FC<ProjectAddButtonProps> = ({ addProject }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const resetState = () => {
    setVisible(false);
    setName('');
    setDescription('');
  };

  const handleOk = async () => {
    if (name.length === 0) {
      message.error(IStrings.error_enterName);
      return;
    }
    if (!/^[a-zA-Z0-9]{1,255}$/.test(name)) {
      message.error(IStrings.error_projectNameValidation);
      return;
    }
    let isUnique = await addProject({
      id: name,
      name: name,
      status: 'Edit',
      description: description,
    });
    if (isUnique) {
      resetState();
    } else {
      message.error(IStrings.error_uniqueName);
    }
  };

  function showConfirm() {
    confirm({
      title: IStrings.discard_project_modal_title,
      icon: <ExclamationCircleOutlined />,
      content: IStrings.discard_project_modal_description,
      onOk() {
        setVisible(false);
        resetState();
      },
      onCancel() {
        /* This is intentional */
      },
    });
  }
  return (
    <>
      <Button
        onClick={() => {
          setVisible(true);
        }}
        icon={<FolderAddOutlined />}
        data-testid="addFormBTN"
      >
        {IStrings.CreateProject}
      </Button>
      <Modal
        title={IStrings.NewForm}
        visible={visible}
        onOk={handleOk}
        onCancel={showConfirm}
        footer={[
          <Button key="back" onClick={showConfirm} data-testid="returnFormBTN">
            {IStrings.Return}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            data-testid="saveFormBTN"
          >
            {IStrings.Save}
          </Button>,
        ]}
      >
        <label style={{ display: 'block' }}>{IStrings.Name}</label>
        <Input
          placeholder="Name"
          value={name}
          autoFocus={true}
          onChange={(e: any) => {
            setName(e.target.value);
          }}
          data-testid="addFormName"
        />
        <label style={{ display: 'block' }}>{IStrings.Description}</label>
        <TextArea
          placeholder={IStrings.Description}
          value={description}
          onChange={(e: any) => {
            setDescription(e.target.value);
          }}
          data-testid="addFormDescription"
        />
      </Modal>
    </>
  );
};

export default AddProject;
