import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Radio, Select } from 'antd';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { CanvasContext } from '../../../../canvasContext';
import { IStrings } from '../../../../core/IStrings';
import '../../../../styles/ComponentPropertyEditor.css';
import { ButtonList } from './ButtonList';
import { BUTTONS_STYLES, BUTTON_ACTIONS } from './constants';

interface ButtonEditorProps {
  label: string;
  buttons?: NavButtonObject[];
  dataKey: string;
}

export interface NavButtonObject {
  label?: string;
  action?: string;
  location?: string;
  useSARAction: boolean;
  saveAndRetrieveConsent?: boolean;
  variant?: string;
}

export const ButtonEditor: FunctionComponent<ButtonEditorProps> = ({
  label,
  buttons = [],
  dataKey,
}) => {
  const { setPropOfActiveField } = useContext(CanvasContext);
  const [listItem, setListItem] = React.useState<NavButtonObject>({
    useSARAction: false,
    label: undefined,
    action: undefined,
    location: undefined,
    saveAndRetrieveConsent: false,
    variant: undefined,
  });
  const [editIndex, setEditIndex] = useState<number | undefined>(undefined);

  const SAR_OPTIONS = [
    { key: 'Activate', value: true, label: IStrings['Activate'] },
    { key: 'Deactivate', value: false, label: IStrings['Deactivate'] },
  ];

  const BUTTON_STYLES_SELECT_OPTIONS = Object.keys(BUTTONS_STYLES).map(
    (action) => {
      return { value: action, label: IStrings[action] };
    }
  );

  const BUTTON_ACTIONS_SELECT_OPTIONS = Object.keys(BUTTON_ACTIONS).map(
    (action) => {
      return { value: action, label: IStrings[action] };
    }
  );

  const enableSaveButton = (): boolean => {
    return !!(listItem.action && listItem.action === BUTTON_ACTIONS.custom
      ? listItem.label && listItem.variant && listItem.location
      : listItem.label && listItem.variant);
  };

  const resetEditor = () => {
    setListItem((prevState) => ({
      ...prevState,
      label: undefined,
      action: undefined,
      location: undefined,
      useSARAction: false,
      saveAndRetrieveConsent: undefined,
      variant: undefined,
    }));
  };

  useEffect(() => {
    resetEditor();
    setEditIndex(undefined);
  }, [buttons]);

  return (
    <div
      style={{
        padding: 5,
        borderColor: '#93f100',
        borderWidth: 2,
        borderStyle: 'solid',
        marginTop: '1em',
      }}
    >
      <ButtonList
        label={label}
        buttons={buttons}
        dataKey={dataKey}
        setListItem={setListItem}
        resetEditor={resetEditor}
        setEditIndex={setEditIndex}
        editIndex={editIndex}
      />
      <label className="stringEditor">{IStrings.Action}</label>
      <Select
        style={{ display: 'block' }}
        options={BUTTON_ACTIONS_SELECT_OPTIONS}
        placeholder={IStrings.Button_Action}
        onChange={(value) => {
          setListItem((prevState) => ({
            ...prevState,
            action: value,
            //TODO: remvove Payback specific logic
            saveAndRetrieveConsent: undefined,
          }));
        }}
        disabled={false}
        value={listItem?.action}
      />
      <label
        style={{
          display: listItem.action === BUTTON_ACTIONS.custom ? 'block' : 'none',
        }}
        className="stringEditor"
      >
        {IStrings.Link}
      </label>
      <Input
        style={{ display: 'block' }}
        value={listItem?.location}
        className="listInput"
        placeholder="Link"
        onChange={(event) => {
          setListItem((prevState) => ({
            ...prevState,
            location: event.target.value,
          }));
        }}
        hidden={listItem.action !== BUTTON_ACTIONS.custom}
      />
      <label className="stringEditor">{IStrings.Button_Variant}</label>
      <Select
        style={{ display: 'block' }}
        options={BUTTON_STYLES_SELECT_OPTIONS}
        placeholder="Button Variant"
        onChange={(value) => {
          setListItem((prevState) => ({
            ...prevState,
            variant: value,
          }));
        }}
        value={listItem?.variant}
      />
      <div style={{ marginTop: '1em', marginBottom: '1em' }}>
        <Checkbox
          checked={listItem.useSARAction}
          onChange={(e) => {
            setListItem((prevState) => ({
              ...prevState,
              //TODO: remvove Payback specific logic
              useSARAction: !prevState.useSARAction,
            }));
          }}
          disabled={
            listItem?.action !== BUTTON_ACTIONS.next ||
            listItem?.action === undefined
          }
        >
          {IStrings.Use_SAR}
        </Checkbox>
        <div style={{ marginLeft: '1.8em' }}>
          <label
            style={{ display: 'block', whiteSpace: 'normal', marginTop: '5px' }}
          >
            {IStrings.Add_SAR_Action_label}
          </label>
          <Radio.Group
            style={{ display: 'block', marginTop: '5px' }}
            disabled={!listItem.useSARAction}
            onChange={(e: any) => {
              setListItem((prevState) => ({
                ...prevState,
                saveAndRetrieveConsent: e.target.value,
              }));
            }}
            value={listItem.saveAndRetrieveConsent}
          >
            {SAR_OPTIONS?.map((option) => (
              <Radio key={option.key} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Radio.Group>
        </div>
      </div>

      <label className="stringEditor">{IStrings.ButtonLabel}</label>
      <Input
        style={{ display: 'block' }}
        value={listItem.label}
        placeholder="Label"
        onChange={(event) => {
          setListItem((prevState) => ({
            ...prevState,
            label: event.target.value,
          }));
        }}
      />

      {editIndex === undefined ? (
        <Button
          style={{ marginBottom: '0.4em' }}
          icon={
            <PlusOutlined style={{ margin: '0px', position: 'relative' }} />
          }
          onClick={(event) => {
            buttons.push(listItem);
            resetEditor();
            setPropOfActiveField(dataKey, buttons);
          }}
          disabled={!enableSaveButton()}
        ></Button>
      ) : (
        <>
          <Button
            style={{ marginBottom: '0.4em' }}
            onClick={(e: any) => {
              buttons.splice(editIndex, 1, listItem);
              setPropOfActiveField(dataKey, buttons);
              resetEditor();
              setEditIndex(undefined);
            }}
            disabled={!enableSaveButton()}
          >
            {IStrings.Save}
          </Button>
          <Button
            style={{ marginBottom: '0.4em' }}
            icon={
              <RedoOutlined
                rotate={180}
                style={{ margin: '0px', position: 'relative', top: '-1px' }}
              />
            }
            onClick={(e: any) => {
              resetEditor();
            }}
          />
        </>
      )}
    </div>
  );
};
export default ButtonEditor;
