import React, { FunctionComponent } from 'react';
import { ItemTypes } from '../core/itemTypes';
import DependenciesDraggableWrapper from './dependenciesDraggableWrapper';
import { LinkOutlined } from '@ant-design/icons';
import { DependencyProps } from './IDependency';

export interface DependenciesDragSourceProps {
  // title: string;
  id?: string;
  superiorId: string;
  itemType: ItemTypes;
  dependency?: DependencyProps;
}

export const DependenciesDragSource: FunctionComponent<DependencyProps> = ({
  ...props
}) => {
  const { children, ...dependency } = props;
  return (
    <div
      style={{
        textAlign: 'center',
        width: '32px',
        height: '32px',
      }}
    >
      <DependenciesDraggableWrapper {...dependency}>
        {children}
      </DependenciesDraggableWrapper>
      <LinkOutlined
        style={{
          color: '#B1B0AF',
          width: '32px',
          height: '32px',
          fontSize: '150%',
          textAlign: 'center',
          verticalAlign: 'center',
          alignItems: 'center',
          display: 'flex',
          cursor: 'pointer',
        }}
      ></LinkOutlined>
    </div>
  );
};

export default DependenciesDragSource;
