import React, { FunctionComponent, useContext, useState } from 'react';
import { Input, Button } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import '../../../styles/ComponentPropertyEditor.css';
import { IStrings } from '../../../core/IStrings';
import { CanvasContext } from '../../../canvasContext';

interface CheckboxComponentEditorProps {
  label: string;
  value?: string;
  dataKey: string;
}

export const CheckboxComponentEditor: FunctionComponent<
  CheckboxComponentEditorProps
> = ({ label, value, dataKey }) => {
  const { setPropOfActiveField } = useContext(CanvasContext);
  let newValue = value ? JSON.parse(value) : [];
  const [InputOne, setInputOne] = useState<string>('');
  const [InputTwo, setInputTwo] = useState<string>('');

  const addOnClick = () => {
    if (InputTwo === '') {
      newValue.push(InputOne.trim());
    } else {
      newValue.push({
        url: InputTwo.trim(),
        label: InputOne.trim(),
      });
    }
    setInputOne('');
    setInputTwo('');
    setPropOfActiveField(dataKey, JSON.stringify(newValue));
  };

  const removeOnClick = (index: any) => {
    newValue.splice(index, 1);
    setPropOfActiveField(dataKey, JSON.stringify(newValue));
  };

  return (
    <>
      <label className="radioEditor">{label}</label>

      {newValue.length !== 0 && (
        <ul className="radioEditorUl">
          {newValue.map((el: any, i: any) => (
            <li key={label + i} className="radioEditorLi">
              {el.label ? el.label : el} - {el.url}
              <Button
                className="listRemoveButton"
                size="small"
                shape="circle"
                onClick={() => {
                  removeOnClick(i);
                }}
                icon={<MinusOutlined style={{ margin: '0px' }} />}
                data-testid="listEditorDelBtn"
              />
            </li>
          ))}
        </ul>
      )}

      <Input
        placeholder={'Label'}
        value={InputOne}
        onChange={(e: any) => {
          setInputOne(e.target.value);
        }}
        data-testid={'radioEditorInputOne' + label}
        // maxLength={30}
      />
      <br />
      <Input
        placeholder={'Label Url'}
        value={InputTwo}
        onChange={(e: any) => {
          setInputTwo(e.target.value);
        }}
        data-testid={'radioEditorInputTwo' + label}
        // maxLength={50}
      />
      <br />
      <Button
        className="deleteButton"
        disabled={InputOne.trim() ? false : true}
        onClick={() => addOnClick()}
      >
        <span className="deleteButtonSpan">{IStrings.Add}</span>
      </Button>
    </>
  );
};

export default CheckboxComponentEditor;
