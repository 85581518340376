import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { functionNamesBNF } from '../../../core/constants/functionNames';
import { validationPropertiesBNF } from '../../../core/constants/validationProperties';
import { IFieldProp } from '../../../core/IFieldProp';
import { IStrings } from '../../../core/IStrings';
import {
  getAllIdentifiers,
  getAllItemNames,
  getItemByIDRecursive,
} from '../../../components/utils/dataHelper';
import { loadBNFDependencies } from '../../../manageContextFields';
import { validateExpressionSyntax } from '../../expressions/expressionParser';

interface BNFError {
  key: string;
  status: boolean;
  id: string;
}

export const useBnf = ({
  fields,
}): [
  (value: any) => BNFError[],
  (key: string, value: any, id: string) => boolean
] => {
  const [bnfDependencies, setBNFDependencies] = useState<
    Map<string, IFieldProp[]>
  >(new Map());

  useEffect(() => {
    loadBNFDependencies().forEach((dep) => {
      let [dependencyFiltered, dependencyTree] = checkBNFDependencies(
        validateExpressionSyntax(dep.value)
      );
      createBNFDependencies(dependencyTree, dep.key, dep.id);
    });
  }, []);

  const checkBNF = (key: string, value: any, id: string): boolean => {
    if (validationPropertiesBNF.includes(key)) {
      //check simple syntax
      const validationOutput = validateExpressionSyntax(value);
      if (validationOutput instanceof Error) {
        message.error(
          bnfValidationErrorMessageCreator(validationOutput.message, key),
          10
        );
        return true;
      } else {
        //check if mentioned fields exist
        let [dependencyFiltered, dependencyTree] =
          checkBNFDependencies(validationOutput);
        if (dependencyFiltered.length > 0) {
          message.error(
            /*IStrings.bnf_error_6*/ +dependencyFiltered +
              /*IStrings.bnf_error_7*/ '',
            10
          );
          return true;
        } else {
          createBNFDependencies(dependencyTree, key, id);
          return false;
        }
      }
    }
    return false;
  };

  const createBNFDependencies = (dependencyTree, key, id) => {
    let copyBNFMap = new Map(bnfDependencies);
    dependencyTree.forEach((dependency) => {
      let oldBNFDependencies = bnfDependencies.get(dependency)
        ? bnfDependencies.get(dependency)!
        : [];
      let copyBNFArray = [...oldBNFDependencies];
      copyBNFMap = new Map(copyBNFMap);
      copyBNFArray.push(
        ...(copyBNFArray.find((prop) => {
          return prop.id === id;
        })
          ? []
          : [{ id: id, key: key }])
      );
      copyBNFMap.set(dependency, copyBNFArray);
    });
    setBNFDependencies(copyBNFMap);
  };

  const checkBNFDependencies = (validationOutput) => {
    let dependencyTree = [];
    if (!(validationOutput instanceof Error)) {
      validationOutput.forEach((output) => {
        dependencyTree = dependencyTree.concat(getAllIdentifiers(output));
      });
      let fieldNameList = getAllItemNames(fields);
      let dependencyFiltered = dependencyTree.filter((dependency) => {
        if (functionNamesBNF.includes(dependency)) {
          return '';
        }
        return fieldNameList.includes(dependency) ? '' : dependency;
      });
      return [dependencyFiltered, dependencyTree];
    } else {
      return [[], []];
    }
  };

  const validateBNFDependencyChange = (value): BNFError[] => {
    let violatedDependencies: BNFError[] = [];
    if (bnfDependencies.has(value)) {
      bnfDependencies.get(value)!.forEach((fieldObject) => {
        let newFields = [...fields]; // only copies first layer, references to nested elements stay the same
        let fieldToEdit = getItemByIDRecursive(fieldObject.id, newFields);
        let prop = fieldToEdit?.propConfig.find((obj) => {
          return obj.key === fieldObject.key;
        });
        if (prop !== undefined) {
          let status = checkBNF(prop.key, prop.value, fieldObject.id);
          violatedDependencies.push({
            key: prop.key,
            status: status,
            id: fieldObject.id,
          });
        }
      });
    }
    return violatedDependencies;
  };

  const bnfValidationErrorMessageCreator = (
    validationOutput: string,
    propertyKey: string
  ) => {
    const lineError = validationOutput.split('line ')[1]?.split(' col')[0];
    const colError = validationOutput.split('col ')[1]?.split('\n')[0];
    const tokenError = validationOutput.split('token: ')[1]?.split('.')[0];
    const errorMessage = '';
    /*IStrings.bnf_error_1 +
      IStrings[propertyKey] +
      IStrings.bnf_error_2 +
      lineError +
      IStrings.bnf_error_3 +
      colError +
      IStrings.bnf_error_4 +
      tokenError +
      IStrings.bnf_error_5;*/
    return errorMessage;
  };

  return [validateBNFDependencyChange, checkBNF];
};

export default useBnf;
