export const BUTTONS_STYLES = {
  outlined: 'outlined',
  contained: 'contained',
};

export const BUTTON_ACTIONS = {
  next: 'next',
  previous: 'previous',
  custom: 'custom',
  submit: 'submit',
};
