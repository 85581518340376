import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, message, Modal, Table } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { deleteProjectFromBackend } from '../api';
import { IStrings } from '../core/IStrings';
import ProjectAddButton from './addProject';
import {
  ConfigurationTable,
  ProjectConfiguration,
} from './dashboard/configurationTable/configurationTable';
import ConfigImportButton from './dashboard/tableButtons/configImportButton';
import { ConditionalComponentContainer } from '../services/auth/conditionalComponentContainer';
import { ActiveConfig } from '../App';
import { SortOrder } from 'antd/es/table/interface';
import { getConfigList } from '../api/project';

const { confirm } = Modal;

interface FormDashboardProps {
  projectList: ProjectItemProps[];
  addProject: (item: ProjectItemProps) => Promise<boolean>;
  getProjectList: () => void;
  setActiveConfig: (item: ActiveConfig | undefined) => void;
  setSelectedProjectName: (projectName: string | number) => void;
  selectedProjectName?: string | number;
}
export interface ProjectItemProps {
  id: string;
  name: string;
  status: string;
  description?: string;
  lastModified?: string;
}

export interface ProjectRenameItemProps {
  name: string;
  id: string | number;
  newName: string;
}

export const FormDashboard: React.FC<FormDashboardProps> = ({
  projectList,
  addProject,
  getProjectList,
  setActiveConfig,
  setSelectedProjectName,
  selectedProjectName,
}) => {
  const [projectConfigurations, setProjectConfigurations] = useState<
    ProjectConfiguration[]
  >([]);

  async function fetchProjectConfigurations() {
    getConfigList(selectedProjectName).then(
      (result) => setProjectConfigurations(result.configs),
      (error) => message.error(`${IStrings.Error}: ${error?.message}`, 10)
    );
  }

  const columns: {
    title: string;
    key: string;
    dataIndex?: string;
    render?: (any) => any;
    sorter?: any;
    defaultSortOrder?: SortOrder;
  }[] = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      sorter: {
        compare: (a: { name: string }, b: { name: string }) =>
          a.name.localeCompare(b.name),
      },
    },
    {
      title: IStrings.Created,
      key: 'created',
      dataIndex: 'last_modified',
      render: (lastModified) =>
        lastModified
          ? new Date(lastModified).toLocaleString(IStrings.getLanguage())
          : IStrings.Unknown,
      sorter: {
        compare: (
          a: { last_modified: moment.MomentInput },
          b: { last_modified: moment.MomentInput }
        ) => moment(a.last_modified).diff(moment(b.last_modified)),
      },
      defaultSortOrder: 'descend',
    },
    {
      title: IStrings.Actions,
      key: 'edit',
      render: (project: ProjectItemProps) => {
        return [
          <Button
            style={{
              margin: '10px',
            }}
            danger
            key="deleteBtn"
            onClick={() => {
              confirm({
                title: IStrings.discard_project_modal_title,
                content: IStrings.discard_project_modal_description,
                cancelText: IStrings.Cancel,
                okType: 'danger',
                icon: <ExclamationCircleOutlined />,
                onOk() {
                  deleteProjectFromBackend(project)
                    .then(() => {
                      getProjectList();
                      message.info(IStrings.discard_project_confirm_message);
                    })
                    .catch(() => {
                      message.error(IStrings.discard_project_error_message);
                    });
                },
                onCancel() {
                  /* This is intentional */
                },
              });
            }}
            icon={<DeleteOutlined />}
          >
            {IStrings.Delete}
          </Button>,
        ];
      },
    },
  ];

  function localizeProjectList(
    projectList: ProjectItemProps[]
  ): ProjectItemProps[] {
    let localizedProjectList: ProjectItemProps[] = [];
    projectList.forEach((project) => {
      project.status = localizeProjectStatus(project.status);
      localizedProjectList.push(project);
    });
    return localizedProjectList;
  }

  function localizeProjectStatus(status: string): string {
    let localizedProjectStatus = '';
    switch (status) {
      case 'Edit':
      case 'Bearbeiten':
        localizedProjectStatus = IStrings.Edit;
        break;
      case 'Test':
        localizedProjectStatus = IStrings.Test;
        break;
      default:
        localizedProjectStatus = status;
        break;
    }

    return localizedProjectStatus;
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedProjectName(selectedRowKeys[0]);
    },
  };

  return (
    <>
      <Table
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        dataSource={projectList && localizeProjectList(projectList)}
        columns={columns}
        rowKey="name"
        locale={{ triggerAsc: '', triggerDesc: '' }}
        sortDirections={['ascend', 'descend', 'ascend']}
        footer={() => {
          return (
            <>
              <ConditionalComponentContainer identifier="projectAddButton">
                <ProjectAddButton addProject={addProject} />
              </ConditionalComponentContainer>
              <ConditionalComponentContainer identifier="configImportButton">
                <ConfigImportButton
                  fetchProjectConfigurations={fetchProjectConfigurations}
                  style={{ marginLeft: '1em' }}
                />
              </ConditionalComponentContainer>
            </>
          );
        }}
      />
      <ConfigurationTable
        setActiveConfig={setActiveConfig}
        projectName={selectedProjectName}
        projectConfigurations={projectConfigurations}
        fetchProjectConfigurations={fetchProjectConfigurations}
      />
    </>
  );
};

export default FormDashboard;
