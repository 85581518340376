import { Select } from 'antd';
import React, { FunctionComponent, useContext } from 'react';
import { CanvasContext } from '../../../../../canvasContext';
import { IStrings } from '../../../../../core/IStrings';
import { VALIDATIONVALUE } from '../constants';

interface SelectInputEditorProps {
  value: string;
  validationKey: string;
  valueOptions?: Array<string>;
  disabled?: boolean;
}

export const SelectInputEditor: FunctionComponent<SelectInputEditorProps> = ({
  validationKey,
  value,
  valueOptions,
  disabled,
}) => {
  const { Option } = Select;
  const { setValidationPropOfActiveField } = useContext(CanvasContext);
  return (
    <>
      <label>{IStrings[validationKey]}</label>
      <Select
        data-testid="select-type"
        placeholder={IStrings[validationKey]}
        allowClear={true}
        style={{ width: '100%', height: '32px' }}
        onChange={(e: string) => {
          setValidationPropOfActiveField(validationKey, e, VALIDATIONVALUE);
        }}
        value={value}
        disabled={disabled}
      >
        {valueOptions?.map((option) => (
          <Option
            title={option}
            data-testid={'select-option-' + option}
            key={option}
            value={option}
          >
            {IStrings['Validation_' + option] ?? option}
          </Option>
        ))}
      </Select>
    </>
  );
};
