import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Input, message, Modal } from 'antd';
import React, { useState } from 'react';
import { putConfig } from '../../../api/project';
import { DragItemProps } from '../../../core/IDragItem';
import { IStrings } from '../../../core/IStrings';
import {FileAddOutlined} from "@ant-design/icons";

const { confirm } = Modal;

interface ConfigAddButtonProps {
  projectName?: string | number;
  editorConfig: DragItemProps[];
  fetchProjectConfigurations: Function;
}

export const ConfigAddButton: React.FC<ConfigAddButtonProps> = ({
  projectName,
  editorConfig,
  fetchProjectConfigurations,
}) => {
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);

  const handleOk = async () => {
    if (!name) {
      message.error(IStrings.error_enterName);
      return;
    }
    if (!/^[a-zA-Z0-9]{1,255}$/.test(name)) {
      message.error(IStrings.error_projectNameValidation);
      return;
    }
    putConfig(projectName, editorConfig, name, description)
     .then(() => {
        setVisible(false);
        fetchProjectConfigurations(projectName);
      })
      .catch((e) => {console.error(e)});
  };

  function showConfirm() {
    confirm({
      title: IStrings.discard_project_modal_title,
      icon: <ExclamationCircleOutlined />,
      content: IStrings.discard_project_modal_description,
      onOk() {
        setVisible(false);
      },
      onCancel() {
        /* This is intentional */
      },
    });
  }
  return (
    <>
      <Button onClick={() => setVisible(true)} icon={<FileAddOutlined />}>{IStrings.CreateConfig}</Button>
      <Modal visible={visible} onOk={handleOk} onCancel={showConfirm}>
        <Input
          placeholder="Name"
          value={name}
          autoFocus={true}
          onChange={(e: any) => {
            setName(e.target.value);
          }}
        />
        <Input
          placeholder={IStrings.Description}
          value={description}
          onChange={(e: any) => {
            setDescription(e.target.value);
          }}
        />
      </Modal>
    </>
  );
};
