import {getConfig} from '../../../api/project';
import { IStrings } from '../../../core/IStrings';
import {ProjectConfiguration} from "../configurationTable/configurationTable";
import {setBackendFields} from "../../../manageContextFields";
import React from 'react';
import {EditOutlined} from "@ant-design/icons";
import {TableButton} from "../tableButton";
import {ActiveConfig} from "../../../App";

interface ConfigEditButtonProps {
    projectName?: string | number;
    projectConfig: ProjectConfiguration;
    setActiveConfig: (item: ActiveConfig | undefined) => void;
}

function getProjectConfig(
    projectName: string | number,
    projectConfig: ProjectConfiguration,
    setActiveConfig: (item: ActiveConfig | undefined) => void,
) {
    getConfig(projectName, projectConfig.id)
        .then(({ config }) => {
            setBackendFields(config?.editor_config);

            setActiveConfig({name: config.id, readonly: false});
        })
        .catch((e) => console.error(e));
}

export const ConfigEditButton: React.FC<ConfigEditButtonProps> = ({
    projectConfig,
    projectName,
    setActiveConfig,
}) => {

    return (
        <TableButton
            key="editBtn"
            ghost
            type="primary"
            onClick={() => {
                projectName && getProjectConfig(projectName, projectConfig, setActiveConfig);
            }}
            icon={<EditOutlined />}
            label={IStrings.Edit}
        />
    );
};
