import { getAllItems } from '../../services/fieldsValidation/fieldsValidation';
import { DragItemProps } from '../../core/IDragItem';

export const getItemByIDRecursive = (
  id: string,
  children: DragItemProps[] | undefined
): DragItemProps | null => {
  if (children === undefined) {
    return null;
  }

  let found = children.findIndex((item) => item.id === id);

  if (found !== -1) {
    return children[found];
  }

  let result;
  for (let i = 0; result == null && i < children.length; i++) {
    result = getItemByIDRecursive(id, children[i].elements);
  }
  return result;
};

export function getAllItemNames(fields) {
  return getAllItems(fields).map((item) => {
    return item.propConfig.find((prop) => prop.key === 'fieldName')?.value;
  });
}

export function getAllIdentifiers(output) {
  let result: [] = [];
  getAllIdentifiersRecursiv(output, result);
  return result;
}

export function getAllIdentifiersRecursiv(element, collectedItems) {
  if (element.left) {
    getAllIdentifiersRecursiv(element.left, collectedItems);
  }
  if (element.right) {
    getAllIdentifiersRecursiv(element.right, collectedItems);
  }
  if (!element.left && !element.right && element.type === 'IDENTIFIER') {
    collectedItems.push(element.value);
  }
  if (element.args) {
    collectedItems.push(element.name);
    if (element.args instanceof Array) {
      element.args.forEach((arg) => {
        getAllIdentifiersRecursiv(arg, collectedItems);
      });
    }
    getAllIdentifiersRecursiv(element.args, collectedItems);
  }
}
