import { Tabs } from 'antd';
import * as React from 'react';
import { IStrings } from '../../../core/IStrings';
import ComponentPropertyEditor from '../../../components/componentPropertyEditor';

const { TabPane } = Tabs;

export const getPropertiesTabPane = () => {
  return (
    <TabPane
      data-testid="sider-right-properties"
      tab={IStrings.Properties}
      key="properties"
    >
      <ComponentPropertyEditor />
    </TabPane>
  );
};

export default getPropertiesTabPane;
