import { InboxOutlined } from '@ant-design/icons';
import { Button, message, Modal, Upload } from 'antd';
import React, {CSSProperties, FunctionComponent, useState} from 'react';
import { IStrings } from '../../../core/IStrings';
import Dragger from 'antd/lib/upload/Dragger';
import { postImportConfig } from '../../../api/project';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import {ProjectConfiguration} from "../configurationTable/configurationTable";
import {ImportOutlined} from "@ant-design/icons";

interface ConfigImportProps {
    fetchProjectConfigurations: () => void;
    style?: CSSProperties;
}

const validateConfig = (config?: ProjectConfiguration): string | void => {
    if (!config)
        return IStrings.ErrorImportConfigGeneral;
    if (!config.approveDate || !config.approver)
        return IStrings.ErrorImportConfigApprove;
    if (!config.releaseDate || !config.releaser)
        return IStrings.ErrorImportConfigRelease;
}

const parseAndValidateConfig = (stringToBeParsed: string): ProjectConfiguration => {
    let config: ProjectConfiguration;
    config = JSON.parse(stringToBeParsed);
    const error: string | void = validateConfig(config);
    if (error) throw new Error(error)
    return config;
}

const ConfigImportButton: FunctionComponent<ConfigImportProps> = ({fetchProjectConfigurations, style}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [config, setConfig] =
    useState<ProjectConfiguration | undefined>(undefined);
  const [fileList, setFileList] = useState<UploadFile[]>();

  const resetState = () => {
    setFileList(undefined);
    setConfig(undefined)
    setVisible(false);
  };

  const handleOk = async () => {
  if (!config) {
      message.error(IStrings.ErrorImportConfigEmpty)
      return resetState();
  }
    const projectName: string = config.project.name;
    await postImportConfig(projectName, JSON.stringify(config))
      .then((result) => {
        if (result.status < 200 || result.status > 299)
          throw new Error(`${result.statusText} ${result.statusText}`);
        fetchProjectConfigurations();
        message.success(
          `${projectName}: ${IStrings.SuccessImportConfig}`
        );
      })
      .catch((error) =>
        message.error(`${IStrings.Error}: ${error?.message}`, 10)
      );
    resetState();
  };

  const beforeUpload = (file: RcFile) => {
    const reader: FileReader = new FileReader();

    reader.onload = (e) => {
      if (e.target?.result) {
        try {
          setConfig(parseAndValidateConfig(e.target.result.toString()));
        } catch (ex: any) {
          message.error(ex.message);
          setConfig(undefined);
          return Upload.LIST_IGNORE;
        }
      }
    };
    reader.readAsText(file);

    //Prevent upload event from antd upload
    return false;
  };

  return (
    <>
      <Button
        onClick={() => {
          setVisible(true);
        }}
        icon={<ImportOutlined />}
        style={style}
      >
        {IStrings.ImportConfig}
      </Button>
      <Modal
        title={IStrings.ImportConfig}
        visible={visible}
        onOk={handleOk}
        onCancel={() => resetState()}
        footer={[
          <Button
            key="back"
            onClick={() => resetState()}
          >
            {IStrings.Cancel}
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={!config}
            onClick={handleOk}
          >
            {IStrings.Import}
          </Button>,
        ]}
      >
        <Dragger
          accept=".json"
          beforeUpload={beforeUpload}
          multiple={false}
          maxCount={1}
          showUploadList={false}
          fileList={fileList}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{IStrings.UploadFileAreaText}</p>
          <p className="ant-upload-hint">{IStrings.UploadFileAreaHint}</p>
        </Dragger>

        {config && (
          <div style={{ marginTop: '2em' }}>
              <p>{IStrings.Name}: {config?.name}</p>
            <p>
              {IStrings.ImportIntoProject} {config?.project?.name}
            </p>
          </div>
        ) }
      </Modal>
    </>
  );
};

export default ConfigImportButton;
