import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Button, Col, Input, notification, Row, Upload } from 'antd';
import '../../../styles/ComponentPropertyEditor.css';
import { IStrings } from '../../../core/IStrings';
import { CanvasContext } from '../../../canvasContext';
import MappingCard from '../../../components/propertyEditors/nestedUI/MappingCard';
import React from 'react';

interface InputMappingsEditorProps {
  label: string;
  mappingKeyLabel: string;
  mappingValueLabel: string;
  value?: string;
  handleChange: (key: string, value: string) => void;
  disabled?: boolean;
  dataKey: string;
  id: string | undefined;
  validateKey: (key: string) => boolean;
}

interface MappingItem {
  client?: string;
  endpoint?: string;
  clientInput: string;
  endpointInput: string;
  index?: number;
}

interface MappingElement {
  client: string;
  endpoint: string;
}

export const InputMappingsEditor: FunctionComponent<
  InputMappingsEditorProps
> = ({
  label,
  mappingKeyLabel,
  mappingValueLabel,
  value,
  handleChange,
  dataKey,
  id,
  disabled,
  validateKey,
}) => {
  const [newValue, setNewValue] = useState(value ? JSON.parse(value) : []);
  const [item, setItem] = useState<MappingItem>({
    clientInput: '',
    endpointInput: '',
  });
  const [activeItem, setActiveItem] = useState<MappingItem | undefined>(
    undefined
  );
  const { activeField } = useContext(CanvasContext);

  useEffect(() => {
    setItem({
      clientInput: '',
      endpointInput: '',
    });
  }, [activeField]);

  useEffect(() => {
    //As soon as the value changes = push it to the parent
    handleChange(dataKey, JSON.stringify(newValue));
  }, [newValue]);

  useEffect(() => {
    setNewValue(value ? JSON.parse(value) : []);
  }, [id]);

  function addToList(client, endpoint) {
    setNewValue((prevState) => [
      ...prevState,
      {
        client: client,
        endpoint: endpoint !== '' ? endpoint : client,
      },
    ]);
  }

  const addOnClick = () => {
    if (activeItem && activeItem.index) {
      const arr = [...newValue];
      const updatedItem = arr[activeItem.index];
      updatedItem.client = item.clientInput.trim();
      updatedItem.endpoint = item.endpointInput.trim();
      setNewValue(arr);
    } else {
      if (validateKey(item.clientInput.trim())) {
        addToList(item.clientInput.trim(), item.endpointInput.trim());
        resetActiveItem();
      } else {
        notification.open({
          key: 'Error',
          type: 'error',
          message: IStrings.error_fieldName_NotFound,
          description: IStrings.error_fieldName_NotFound_desc,
          duration: 10,
        });
      }
    }
  };

  const removeOnClick = (index: number) => {
    resetActiveItem();
    const element = newValue[index];
    setNewValue(newValue.filter((entry) => entry !== element));
  };

  const handleClickListItem = (el, i) => {
    if (activeItem && activeItem.index === i) {
      resetActiveItem();
    } else setActiveItem({ ...el, index: i });
  };

  const resetActiveItem = () => {
    setItem({ clientInput: '', endpointInput: '' });
    setActiveItem({
      clientInput: '',
      endpointInput: '',
      index: undefined,
    });
  };

  useEffect(() => {
    if (activeItem && activeItem.client && activeItem.endpoint) {
      setItem({
        clientInput: activeItem.client,
        endpointInput: activeItem.endpoint,
      });
    }
  }, [activeItem]);

  return (
    <>
      <label className="radioEditor">{label}</label>
      {newValue.length !== 0 &&
        newValue.map((el: MappingElement, i: number) => (
          <MappingCard
            index={i}
            element={el}
            handleClick={handleClickListItem}
            handleDelete={removeOnClick}
            activeItem={activeItem}
            draggable={false}
            disabled={disabled}
          />
        ))}
      <Row>
        <Col span={24}>
          <Input
            placeholder={mappingKeyLabel}
            value={item.clientInput}
            disabled={disabled}
            onChange={(e: any) => {
              setItem({ ...item, clientInput: e.target.value });
            }}
            data-testid={'stringEditor' + label}
          />
        </Col>
        <Col span={24}>
          <Input
            placeholder={mappingValueLabel}
            value={item.endpointInput}
            disabled={disabled}
            onChange={(e: any) => {
              setItem({ ...item, endpointInput: e.target.value });
            }}
            data-testid={'stringEditor' + value}
          />
        </Col>
      </Row>
      <Button
        className="deleteButton"
        disabled={disabled || !item.clientInput}
        onClick={() => addOnClick()}
      >
        <span className="deleteButtonSpan">
          {activeItem ? IStrings.Save : IStrings.Add}
        </span>
      </Button>
    </>
  );
};

export default InputMappingsEditor;
