import React, { createContext, FunctionComponent, useState } from 'react';
import { DependencyProps } from './IDependency';

export const DependenciesModeContext = createContext<{
  dependenciesMode: boolean;
  setDependenciesMode: (dependenciesMode: boolean) => void;
  activeDependency: DependencyProps | undefined;
  setActiveDependency: (dependency: DependencyProps | undefined) => void;
}>({
  dependenciesMode: false,
  setDependenciesMode: () => {
    /* This is intentional */
  },
  activeDependency: undefined,
  setActiveDependency: () => {
    /* This is intentional */
  },
});

const DependenciesModeContextProvider: FunctionComponent<any> = ({
  children,
}) => {
  return (
    <DependenciesModeContext.Provider value={useDependciesMode()}>
      {children}
    </DependenciesModeContext.Provider>
  );
};

const useDependciesMode = () => {
  const [dependenciesMode, setDependenciesMode] = useState(false);
  const [activeDependency, setActiveDependency] = useState<
    DependencyProps | undefined
  >(undefined);

  return {
    dependenciesMode,
    setDependenciesMode,
    activeDependency,
    setActiveDependency,
  };
};

export default DependenciesModeContextProvider;
