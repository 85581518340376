import React, { useContext } from 'react';
import { useDrag } from 'react-dnd';
import { MenuCardProps } from '../components/menuCard';
import { DependenciesModeContext } from './dependenicesModeContext';

const DraggableWrapper: React.FC<MenuCardProps> = ({
  id,
  children,
  title,
  internalName,
  refComponent,
  propConfig,
  validationConfig,
  itemType,
  icon,
  componentTypes,
}) => {
  const type = itemType;
  const { dependenciesMode } = useContext(DependenciesModeContext);
  const [, drag] = useDrag({
    item: {
      id,
      internalName,
      type,
      title,
      propConfig,
      validationConfig,
      refComponent,
      itemType,
      icon,
      componentTypes,
    },
    type: type,
    canDrag: !dependenciesMode,
  });
  return (
    <div data-testid={id ?? title} ref={drag}>
      {children}
    </div>
  );
};

export default DraggableWrapper;
