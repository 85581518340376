import React, { FunctionComponent, useContext } from 'react';
import '../../../styles/ComponentPropertyEditor.css';
import axios from 'axios';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { removeUploadPicture } from '../../../api/index';
import { CanvasContext } from '../../../canvasContext';

const { Dragger } = Upload;

interface PictureUploadEditorProps {
  label: string;
  value?: string;
  dataKey: string;
}

interface FileUploadProps {
  uid: string;
  name: string;
  url: string;
  status: string;
  percent: number;
  thumbUrl: string;
}
export const PictureUploadEditor: FunctionComponent<
  PictureUploadEditorProps
> = ({ label, value, dataKey }) => {
  const { setPropOfActiveField } = useContext(CanvasContext);
  let newValue = value ? JSON.parse(value) : [];
  let fileName = newValue.length > 0 ? newValue[0].name : '';

  const props = {
    accept: 'image/png, image/jpeg',
    name: 'file',
    beforeUpload: (file: any) => {
      return false;
    },
    onRemove(file: any) {
      removeUploadPicture(fileName);
      message.success(`${fileName} file removed.`);
      setPropOfActiveField(dataKey, JSON.stringify([]));
    },
    async onChange(info: any) {
      let newFileName = '';
      if (info.fileList.length > 0) {
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization:
              process.env.REACT_APP_BACKEND_USERNAME +
              ':' +
              process.env.REACT_APP_BACKEND_PASSWORD,
          },
        };
        let formData = new FormData();
        formData.append('file', info.file);
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/upload/picture`,
            formData,
            config
          )
          .then((response) => {
            if (response) {
              if (response.status === 200) {
                if (fileName !== '') {
                  removeUploadPicture(fileName);
                }
                newFileName = response.data;
                message.success(
                  `${info.file.name} file uploaded successfully.`
                );
              } else if (response.status === 400) {
                message.error(
                  `${info.file.name} file uploaded unsuccessfully.`
                );
              } else if (response.status === 401) {
                message.error(`Unauthorized access to Backend`);
              }
            } else {
              throw new Error('NETWORK RESPONSE NOT OK');
            }
          })
          .then(() => {
            let fileList: any[] = [];
            fileList.splice(0, 0, info.fileList[info.fileList.length - 1]);
            Object.defineProperty(fileList[0], 'name', {
              value: newFileName,
              writable: false,
            });
            setPropOfActiveField(dataKey, JSON.stringify(fileList));
          })
          .catch((error: any) => {
            console.error('FETCH ERROR:', error);
            if (error.toString().includes('401')) {
              message.error(
                `${info.file.name} file uploaded unsuccessfully, Unauthorized access to Backend`
              );
            } else {
              message.error(`${info.file.name} file uploaded unsuccessfully.`);
            }
          });
      }
    },
  };

  return (
    <>
      <label className="pictureUploadEditor">{label}</label>
      <Dragger {...props} fileList={newValue} className="picture">
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Upload Image</p>
      </Dragger>
    </>
  );
};

export default PictureUploadEditor;
