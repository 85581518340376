import { ContentState, convertToRaw, EditorState, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { CanvasContext } from '../../../canvasContext';
import '../../../styles/ComponentPropertyEditor.css';

interface TextEditorProps {
  label: string;
  value?: string;
  styleType?: string;
  dataKey: string;
}

function getToolbarOptions(styleType: string | undefined) {
  switch (styleType) {
    case 'Header Text':
      return [
        'inline',
        'colorPicker',
        'fontSize',
        'blockType',
        'fontFamily',
        'textAlign',
      ];
    case 'Switch Text':
      return ['inline', 'link', 'fontSize', 'fontFamily'];
    default:
      return [
        'inline',
        'link',
        'colorPicker',

        'blockType',
        'fontSize',
        'fontFamily',
        'list',
        'textAlign',
      ];
  }
}

function getStyleType(style?: string): string {
  return style === 'button' ? 'rdw-editor-compact ' : '';
}

export const TextEditor: FunctionComponent<TextEditorProps> = ({
  label,
  value,
  styleType,
  dataKey,
}) => {
  const { setPropOfActiveField } = useContext(CanvasContext);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const prefixToolbarOption = (prefix: string) => {
    const addPrefix: Function = (editorState: any): void => {
      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        prefix,
        editorState.getCurrentInlineStyle()
      );

      setEditorState(
        EditorState.push(editorState, contentState, 'insert-characters')
      );
    };
    return (
      <div
        className="rdw-option-wrapper"
        title={`Add ${prefix} prefix`}
        onClick={() => addPrefix(editorState)}
      >
        {prefix}
      </div>
    );
  };

  useEffect(() => {
    if (draftToHtmlConvetor(editorState) === value) return;

    setEditorState(
      EditorState.push(
        editorState,
        ContentState.createFromBlockArray(
          htmlToDraft(value || '').contentBlocks
        ),
        'change-block-data'
      )
    );
  }, [value]);

  const onEditorStateChange = (NewEditorState) => {
    setEditorState(NewEditorState);
    const htmlCode = draftToHtmlConvetor(NewEditorState);
    setPropOfActiveField(dataKey, htmlCode);
  };

  const draftToHtmlConvetor = (NewEditorState) => {
    return draftToHtml(convertToRaw(NewEditorState.getCurrentContent()));
  };

  function handleReturn(e) {
    // prevents new line if true
    return styleType === 'button';
  }

  return (
    <>
      <label className="textEditor">{label}</label>
      <br />
      <Editor
        editorState={editorState}
        wrapperClassName={getStyleType(styleType)}
        toolbarClassName="editor-toolbar"
        onEditorStateChange={onEditorStateChange}
        handleReturn={handleReturn}
        toolbar={{
          options: getToolbarOptions(styleType),
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          fontFamily: {
            options: [
              'Arial',
              'Georgia',
              'Impact',
              'Open Sans',
              'Tahoma',
              'Times New Roman',
              'Payback',
              'Verdana',
            ],
          },
          colorPicker: {
            colors: [
              '#0046AA',
              '#B0D8F0',
              '#95CEED',
              '#4B4B4D',
              '#C1002B',
              '#52AE32',
            ],
          },
        }}
        toolbarCustomButtons={[
          prefixToolbarOption('field:'),
          prefixToolbarOption('system:'),
        ]}
      />
    </>
  );
};

export default TextEditor;
