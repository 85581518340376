import { Collapse, Divider, Menu } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { CanvasContext } from '../canvasContext';
import { IStrings } from '../core/IStrings';
import { COMPONENTTYPES } from '../core/readConfig';
import '../styles/ComponentPropertyEditor.css';
import '../styles/componentsMenu.css';
import getEditorForTenant from '../tenant';
import { collapseExpandIcon } from './collapseExpandIcon/collapseExpandIcon';
import DeleteIcon from './deleteIcon';

import ValidationCollapse from './rightSider/validationCollapse/validationCollapse';

interface EditorProps {}

export const ComponentPropertyEditor: React.FC<EditorProps> = ({
  children,
}) => {
  const { dependenciesOfActivePage, activeField } = useContext(CanvasContext);
  const [componentsColor, setcomponentsColor] = useState<string>();
  const { getPropertyEditors } = getEditorForTenant();

  const { Panel } = Collapse;

  function checkFieldHasDependencies(): boolean {
    let found = dependenciesOfActivePage?.findIndex(
      (dependency) => dependency.dependentId === activeField?.id
    );
    return !(found === -1 || found === undefined);
  }

  useEffect(() => {
    activeField?.componentTypes &&
      setcomponentsColor(
        COMPONENTTYPES.find((type) =>
          activeField?.componentTypes.includes(type.key)
        )?.color
      );
  }, [activeField]);

  return (
    <CanvasContext.Consumer>
      {(contextValue) => (
        <>
          <Menu
            mode="inline"
            data-testid="menuProperty"
            style={{ marginBottom: '2em' }}
          >
            <Menu.Item
              key="3"
              style={{
                height: 'auto',
                overflowY: 'auto',
                background: '#f4f4f4 0% 0% no-repeat padding-box',
              }}
            >
              <label
                style={{
                  display: contextValue.activeField ? 'block' : 'none',
                  color: componentsColor,
                  marginBottom: '1em',
                }}
              >
                {contextValue.activeField &&
                  IStrings[contextValue.activeField.title]}
                <Divider
                  style={{
                    color: componentsColor,
                    borderColor: componentsColor,
                  }}
                  className="divider"
                />
              </label>
              {contextValue.activeField?.propConfig?.map((entry) => {
                return getPropertyEditors(entry, contextValue);
              })}
            </Menu.Item>
          </Menu>
          {contextValue.activeField?.validationConfig && (
            <Collapse
              expandIconPosition="right"
              expandIcon={(e) => collapseExpandIcon(e.isActive)}
            >
              <Panel header={IStrings.Validation_Collapse_Title} key={2}>
                <ValidationCollapse />
              </Panel>
            </Collapse>
          )}
          <div
            className="deleteButtonContainer"
            style={{
              display:
                contextValue.activeField?.propConfig &&
                contextValue.activeField?.itemType !== 'page' &&
                contextValue.activeField?.title !== 'Footer' &&
                contextValue.activeField?.itemType !== 'footer'
                  ? 'block'
                  : 'none',
            }}
          >
            <DeleteIcon
              id={contextValue.activeField ? contextValue.activeField.id : ''}
              hasDependencies={checkFieldHasDependencies()}
              hasChildren={
                contextValue.activeField?.itemType === 'page' ||
                contextValue.activeField?.itemType === 'container' ||
                contextValue.activeField?.itemType === 'row'
              }
              activeField={contextValue.activeField}
            />
          </div>
        </>
      )}
    </CanvasContext.Consumer>
  );
};

export default ComponentPropertyEditor;
