import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, List, message, notification } from 'antd';
import React, { FunctionComponent } from 'react';
import { IStrings } from '../../../core/IStrings';
import '../../../styles/ComponentPropertyEditor.css';

interface ListEditorProps {
  label: string;
  value?: string[];
  handleChange: (key: string, value?: any) => void;
  dataKey: string;
  disabled: boolean;
}

function isInputForValidation(dataKey: string, listItem: string) {
  if (dataKey === 'validation' || dataKey === 'backendValidation') {
    try {
      JSON.parse(listItem);
    } catch (e) {
      notification.open({
        key: 'Error',
        type: 'error',
        message: IStrings.msgNotValidJson_Header,
        description: IStrings.msgNotValidJson_Body,
        duration: 5,
      });
      return false;
    }
  }
  return true;
}

function handleConfirm(
  dataKey: string,
  listItem: string,
  list: string[],
  handleChange: (key: string, value?: any) => void,
  setListItem: (value: ((prevState: string) => string) | string) => void
) {
  if (isInputForValidation(dataKey, listItem)) {
    list.push(listItem);
    handleChange(dataKey, list);
    setListItem('');
  }
}

const addToList = (
  item: string,
  dataKey: string,
  setListItem: (value: ((prevState: string) => string) | string) => void
) => {
  if (dataKey === 'spaceDistribution') {
    const specialCharRegex = new RegExp('^\\d+$');
    if (item && specialCharRegex.test(item)) {
      setListItem(item);
    } else {
      message.error(IStrings.wrong_value_error);
      setListItem('');
    }
  } else {
    setListItem(item);
  }
};

export const ListEditor: FunctionComponent<ListEditorProps> = ({
  label,
  value,
  handleChange,
  dataKey,
  disabled,
}) => {
  let list: string[] = value ? value : [];
  const [listItem, setListItem] = React.useState('');
  return (
    <>
      <List
        header={<div>{label?.toUpperCase()}</div>}
        bordered
        dataSource={list}
        className="listEditor"
        renderItem={(item, index) => (
          <List.Item key={index} data-testid={'listEditorItem' + index}>
            {item}
            <Button
              className="listRemoveButton"
              size="small"
              shape="circle"
              onClick={() => {
                list?.splice(index, 1);
                handleChange(dataKey, list);
              }}
              icon={<MinusOutlined style={{ margin: '0px' }} />}
              data-testid="listEditorDelBtn"
            />
          </List.Item>
        )}
      />
      <Input
        value={listItem}
        className="listInput"
        onChange={(event) => {
          addToList(event.target.value, dataKey, setListItem);
        }}
        data-testid={'listEditorListe'}
        disabled={disabled}
        onPressEnter={(e: any) => {
          handleConfirm(dataKey, listItem, list, handleChange, setListItem);
        }}
      />
      <Button
        className="listButton"
        icon={
          <PlusOutlined
            style={{ margin: '0px', position: 'relative', top: '-1px' }}
          />
        }
        onClick={(e: any) => {
          handleConfirm(dataKey, listItem, list, handleChange, setListItem);
        }}
        data-testid={'listEditorBtn'}
      />
    </>
  );
};
export default ListEditor;
