import { InputNumber } from 'antd';
import React, { FunctionComponent, useContext } from 'react';
import { CanvasContext } from '../../../../../canvasContext';
import { IStrings } from '../../../../../core/IStrings';
import { VALIDATIONVALUE } from '../constants';

interface NumericalInputEditorProps {
  value: number;
  validationKey: string;
  disabled?: boolean;
}

export const NumericalInputEditor: FunctionComponent<NumericalInputEditorProps> = ({
  value,
  validationKey,
  disabled,
}) => {
  const { setValidationPropOfActiveField } = useContext(CanvasContext);
  return (
    <InputNumber
      placeholder={IStrings[validationKey]}
      type={'number'}
      value={value}
      disabled={disabled}
      onChange={(value) => {
        setValidationPropOfActiveField(validationKey, value, VALIDATIONVALUE);
      }}
      data-testid={'radioEditorInputOne' + validationKey}
      // maxLength={30}
    />
  );
};
