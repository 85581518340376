import React from 'react';
import { IEditor } from '../core/IEditor';

declare var __TENANT__: string;

export const getEditorForTenant = () => {
  const EditorForTenant = {} as IEditor;
  EditorForTenant.getPropertyEditors =
    require(`./${__TENANT__}/propertyEditors/index`).getPropertyEditor;
  if (__TENANT__ === 'clx' || __TENANT__ === 'asb') {
    EditorForTenant.componentsConfig = require(`./${__TENANT__}/componentsConfig.json`);
  } else {
    const AsbConf = require(`./asb/componentsConfig.json`);
    const TenantConf = require(`./${__TENANT__}/componentsConfig.json`);
    AsbConf.Components = [...AsbConf.Components, ...TenantConf];
    EditorForTenant.componentsConfig = AsbConf;
  }
  EditorForTenant.getActionButtons =
    require(`./${__TENANT__}/actionButtons/index`).ActionButtons;
  EditorForTenant.getTabPanes =
    require(`./${__TENANT__}/tabPanes/index`).getTabPanes;
  return EditorForTenant;
};

export default getEditorForTenant;
