import { Card } from 'antd';
import React, { FunctionComponent } from 'react';
import DraggableWrapper from '../core/draggableWrapper';
import { ValidationConfig } from '../core/IDragItem';
import { IStrings } from '../core/IStrings';

export interface MenuCardProps {
  title: string;
  id?: string;
  internalName: string;
  propConfig: object;
  validationConfig?: ValidationConfig<any>[];
  refComponent: string;
  itemType: string;
  icon?: string;
  componentTypes: string[];
}

export const MenuCard: FunctionComponent<MenuCardProps> = ({
  title,
  id,
  internalName,
  propConfig,
  validationConfig,
  refComponent,
  itemType,
  icon,
  componentTypes,
}) => {
  icon = icon ? icon : 'icons-light.svg';
  return (
    <DraggableWrapper
      refComponent={refComponent}
      internalName={internalName}
      title={title}
      itemType={itemType}
      id={id}
      propConfig={propConfig}
      validationConfig={validationConfig}
      icon={icon}
      componentTypes={componentTypes}
    >
      <Card
        id={id}
        bordered
        style={{
          width: '120px',
          height: '120px',
          textAlign: 'center',
          verticalAlign: 'middle',
          cursor: 'pointer',
        }}
      >
        <img
          alt=""
          style={{ width: '60px', height: '60px' }}
          src={require('../imgs/' + icon)}
        />
        <div
          style={{
            hyphens: 'auto',
            MozHyphens: 'auto',
            WebkitHyphens: 'auto',
            msHyphens: 'auto',
            lineHeight: '100%',
            marginTop: '6px',
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
        >
          {IStrings[title]}
        </div>
      </Card>
    </DraggableWrapper>
  );
};

export default MenuCard;
