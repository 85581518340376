import { IStrings } from '../../../core/IStrings';
import {ProjectConfiguration} from "../configurationTable/configurationTable";
import React, {useState} from 'react';
import {CopyOutlined} from "@ant-design/icons";
import DuplicateConfigModal from "../configurationTable/duplicateConfigModal";
import {TableButton} from "../tableButton";

interface ConfigDuplicateButtonProps {
    projectName?: string | number;
    projectConfig: ProjectConfiguration;
    fetchProjectConfigurations: () => void;
}

export const ConfigDuplicateButton: React.FC<ConfigDuplicateButtonProps> = ({
      projectConfig,
      projectName,
      fetchProjectConfigurations,
  }) => {
    const [duplicateModalVisible, setDuplicateModalVisible] = useState<boolean>(false);
    const [configurationVersion, setConfigurationVersion] = useState<number>();

    return (
        <>
            <TableButton
              key="duplicateBtn"
              ghost
              type="primary"
              onClick={() => {
                setConfigurationVersion(projectConfig.id);
                setDuplicateModalVisible(true);
              }}
              icon={<CopyOutlined />}
              label={IStrings.Duplicate}
            />
            <DuplicateConfigModal
                projectName={projectName ?? ''}
                configurationVersion={configurationVersion}
                setVisible={setDuplicateModalVisible}
                visible={duplicateModalVisible}
                fetchProjectConfigurations={fetchProjectConfigurations}
            />
        </>
    );
};
