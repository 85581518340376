import React, { useEffect, useState } from 'react';
import '../../../styles/ComponentPropertyEditor.css';
import BasicDropdownEditor from '../../../components/propertyEditors/nestedUI/BasicDropdownEditor';
import SelectDropdownEditor from './SelectDropdownEditor';
import {
  DropdownListElement,
  SelectDropdownOption,
} from '../../../components/propertyEditors/util/DropdownUtil';
import { IStrings } from '../../../core/IStrings';

interface ConditionalDropdownEditorProps {
  label: string;
  handleChange: (key: string, value: string) => void;
  dataKey: string;
  id: string | undefined;
  value?: string;
}

export const ConditionalDropdown: React.FunctionComponent<
  ConditionalDropdownEditorProps
> = ({ label, handleChange, dataKey, id, value }) => {
  const [values, setValues] = useState<DropdownListElement[]>(
    value ? JSON.parse(value) : []
  );
  const [selected, setSelected] = useState<SelectDropdownOption>({
    key: values[0]?.label,
    value: values[0]?.key,
  });
  const [depValues, setDepValues] = useState<DropdownListElement[]>(
    getDepValues()
  );

  useEffect(() => {
    if (!values.some((elem) => elem.key === selected?.value)) {
      setSelected({ key: values[0]?.label, value: values[0]?.key });
    }
  }, [values]);

  useEffect(() => {
    setDepValues(getDepValues());
  }, [selected?.key]);

  function onActiveItemChange(key: string, value: string) {
    let element = values.find((e) => e.key === value);
    if (element) setSelected({ key: element.label, value: element.key });
  }

  function onValuesChange(key: string, list: DropdownListElement[]) {
    if (key.includes('dependent')) {
      let copyValues = [...values];
      let index = copyValues.findIndex((elem) => elem.label === selected?.key);
      if (index !== -1) copyValues[index].options = list;
      setValues(copyValues);
    } else setValues(list);
    handleChange(dataKey, JSON.stringify(values));
  }

  function getSupValuesAsOptions(): SelectDropdownOption[] {
    return values.map((element) => {
      return { key: element.label, value: element.key };
    });
  }

  function getDepValues(): DropdownListElement[] {
    let options = values.find((elem) => elem.label === selected?.key)?.options;
    return options ?? [];
  }

  return (
    <>
      <BasicDropdownEditor
        id={id}
        valueList={{ values: values, setValues: setValues }}
        onChange={onValuesChange}
        dataKey={'superior-' + dataKey}
        label={label}
      />

      <SelectDropdownEditor
        label={IStrings.Listvalue_to_fill}
        placeholder={IStrings.Select}
        dataKey={'activeItem'}
        handleChange={onActiveItemChange}
        options={getSupValuesAsOptions()}
        value={selected}
      />

      <BasicDropdownEditor
        id={id}
        valueList={{ values: depValues, setValues: setDepValues }}
        onChange={onValuesChange}
        dataKey={'dependent-' + dataKey}
        disabled={selected?.key === undefined || selected.key === ''}
        label={label}
      />
    </>
  );
};

export default ConditionalDropdown;
