import { Checkbox, Collapse } from 'antd';
import React, { FunctionComponent, useContext } from 'react';
import { CanvasContext } from '../../../canvasContext';
import { ValidationConfig } from '../../../core/IDragItem';
import { IStrings } from '../../../core/IStrings';
import { collapseExpandIcon } from '../../collapseExpandIcon/collapseExpandIcon';
import {
  VALIDATIONCHECKED,
  VALIDATIONVALUE,
} from './validationPanels/constants';
import { EnumValidation } from './validationPanels/enumValidation';
import { MinmaxValidation } from './validationPanels/minmaxValidation';
import { NumericalInputEditor } from './validationPanels/validationEditors/numericalInputEditor';
import { SelectInputEditor } from './validationPanels/validationEditors/selectInputEditor';
import { TextInputEditor } from './validationPanels/validationEditors/textInputEditor';
import { ValidationErrorMessageInput } from './validationPanels/validationErrorMessageInput';

export const ValidationCollapse: FunctionComponent = () => {
  const { activeField, setValidationPropOfActiveField } =
    useContext(CanvasContext);
  const { Panel } = Collapse;

  const renderValidationEditor = (validation: ValidationConfig<any>) => {
    switch (validation.key) {
      case 'type':
        return (
          <SelectInputEditor
            value={validation.value}
            valueOptions={validation.valueOptions}
            validationKey={validation.key}
            disabled={validation.disabled}
          />
        );
      case 'pattern':
        return (
          <TextInputEditor
            value={validation.value}
            validationKey={validation.key}
            disabled={validation.disabled}
          />
        );
      case 'minimum':
      case 'maximum':
        return (
          <MinmaxValidation
            value={validation.value}
            validationKey={validation.key}
            disabled={validation.disabled}
          />
        );

      case 'minLength':
      case 'maxLength':
      case 'minItems':
      case 'maxItems':
        return (
          <NumericalInputEditor
            value={validation.value}
            validationKey={validation.key}
            disabled={validation.disabled}
          />
        );
      case 'enum':
        return (
          <EnumValidation
            value={validation.value}
            validationKey={validation.key}
            negate={validation.negate}
            disabled={validation.disabled}
          />
        );
      case 'format':
      case 'dateRestriction':
        return (
          <SelectInputEditor
            value={validation.value}
            valueOptions={validation.valueOptions}
            disabled={validation.disabled}
            validationKey={validation.key}
          />
        );
      default:
        return <p>Invalid validationConfig key: {validation.key}</p>;
    }
  };

  function renderPanelHeader(validation: ValidationConfig<any>) {
    return (
      <>
        <Checkbox
          onChange={(e) =>
            setValidationPropOfActiveField(
              validation.key,
              e.target.checked,
              validation.key === 'required'
                ? VALIDATIONVALUE
                : VALIDATIONCHECKED
            )
          }
          checked={
            validation.key === 'required'
              ? validation.value
              : validation.isChecked
          }
          disabled={validation.disabled}
          style={{ marginRight: '1em' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        ></Checkbox>
        {IStrings[validation.label]}
      </>
    );
  }

  return (
    <Collapse
      bordered={false}
      expandIconPosition="right"
      expandIcon={({ isActive }) => collapseExpandIcon(isActive)}
      accordion
    >
      {activeField &&
        activeField.validationConfig?.map((validation) => (
          <Panel header={renderPanelHeader(validation)} key={validation.key}>
            {validation.key !== 'required' &&
              renderValidationEditor(validation)}
            <ValidationErrorMessageInput
              value={validation.message}
              validationKey={validation.key}
            />
          </Panel>
        ))}
    </Collapse>
  );
};

export default ValidationCollapse;
