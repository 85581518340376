import { MinusOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React, { FunctionComponent, useContext, useState } from 'react';
import { CanvasContext } from '../../../canvasContext';
import { IStrings } from '../../../core/IStrings';
import '../../../styles/ComponentPropertyEditor.css';
interface InputRadioEditorProps {
  label: string;
  value?: string;
  dataKey: string;
}
export const InputRadioEditor: FunctionComponent<InputRadioEditorProps> = ({
  label,
  value,
  dataKey,
}) => {
  const { setPropOfActiveField } = useContext(CanvasContext);
  let newValue = value ? JSON.parse(value) : [];
  const [optionLabel, setOptionLabel] = useState<string>('');
  const [optionInfo, setOptionInfo] = useState<string>('');

  const addRadioOnClick = () => {
    newValue.push({
      key: optionLabel.trim(),
      label: optionLabel.trim(),
      info: optionInfo,
    });
    setOptionLabel('');
    setOptionInfo('');
    setPropOfActiveField(dataKey, JSON.stringify(newValue));
  };

  const removeOnClick = (index: any) => {
    newValue.splice(index, 1);
    setPropOfActiveField(dataKey, JSON.stringify(newValue));
  };

  return (
    <>
      <label className="radioEditor">{label}</label>

      {newValue.length !== 0 && (
        <ul>
          {newValue.map((el: any, i: any) => (
            <li key={label + i} className="radioEditorLi">
              {el.label} - {el.info}
              <Button
                className="listRemoveButton"
                size="small"
                shape="circle"
                onClick={() => {
                  removeOnClick(i);
                }}
                icon={<MinusOutlined style={{ margin: '0px' }} />}
                data-testid="inputRadioDelBtn"
              />
            </li>
          ))}
        </ul>
      )}

      <Input
        placeholder={IStrings.Option_Label}
        value={optionLabel}
        onChange={(e: any) => {
          setOptionLabel(e.target.value);
        }}
        data-testid={'radioEditorInputOne' + label}
        // maxLength={30}
      />
      <br />
      <Input
        placeholder={IStrings.Option_Info}
        value={optionInfo}
        onChange={(e: any) => {
          setOptionInfo(e.target.value);
        }}
        data-testid={'radioEditorInputTwo' + label}
        // maxLength={50}
      />
      <br />
      <Button
        className="deleteButton"
        disabled={optionLabel.trim() ? false : true}
        onClick={() => addRadioOnClick()}
      >
        <span className="deleteButtonSpan">{IStrings.Add}</span>
      </Button>
    </>
  );
};

export default InputRadioEditor;
