import React, { FunctionComponent } from 'react';
import { Button, Col, Row, Tooltip, Typography } from 'antd';
import {
  InfoCircleOutlined,
  MinusOutlined,
  MenuOutlined,
} from '@ant-design/icons';

interface MappingItem {
  clientInput: string;
  endpointInput: string;
  index?: number;
}

interface MappingElement {
  client: string;
  endpoint: string;
}

interface MappingCardProps {
  index: number;
  element: MappingElement;
  handleClick: (el: object, id: number) => void;
  handleDelete: (id: number) => void;
  activeItem?: MappingItem;
  draggable?: boolean;
  disabled?: boolean;
}

export const MappingCard: FunctionComponent<MappingCardProps> = ({
  element,
  index,
  handleClick,
  handleDelete,
  activeItem,
  draggable,
  disabled,
}) => {
  const { Text } = Typography;

  return (
    <Row
      onClick={() => !disabled && handleClick(element, index)}
      style={{ lineHeight: 1.8 }}
    >
      {draggable && (
        <Col span={1}>
          <MenuOutlined />
        </Col>
      )}
      <Col
        span={21}
        style={{
          fontWeight:
            activeItem && activeItem.index === index ? 'bold' : 'normal',
        }}
      >
        {element.client} <Text type="secondary"> » {element.endpoint}</Text>
      </Col>
      <Col span={2}>
        <Button
          className="listRemoveButton"
          size="small"
          shape="circle"
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(index);
          }}
          icon={<MinusOutlined style={{ margin: '0px' }} />}
          data-testid="inputDropdownDelBtn"
          disabled={disabled ?? false}
        />
      </Col>
    </Row>
  );
};
export default MappingCard;
