import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Tabs } from 'antd';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CanvasContext } from '../canvasContext';
import { DependenciesModeContext } from '../core/dependenicesModeContext';
import { DragItemProps } from '../core/IDragItem';
import { PageConfig } from '../pageConfig';
import '../styles/formCanvas.css';
import { removePictureFromBackend } from './deleteIcon';
import { TabContent } from './tabContent';

const { TabPane } = Tabs;
interface TabBarProps {}

let DeactiveStyle = {
  height: '38px',
  background: '#fafafa',
  color: '',
};

let ActiveStyle = {
  height: '40px',
  background: 'white',
  color: '#1890ff',
};

export const TabBar: FunctionComponent<TabBarProps> = ({ children }) => {
  const {
    dropField,
    removeField,
    fields,
    moveTab,
    setActivePage,
    setActiveField,
    setConfirmationDisplay,
    updateShownDependencies,
  } = useContext(CanvasContext);

  let DeactiveStyle = {
    height: '38px',
    background: '#fafafa',
    color: '',
  };

  let ActiveStyle = {
    height: '40px',
    background: 'white',
    color: '#1890ff',
  };
  const { dependenciesMode, setDependenciesMode } = useContext(
    DependenciesModeContext
  );
  const [activeKey, setActiveKey] = useState(0);
  const [display, setDisplay] = useState('none');
  const [style, setStyle] = useState(DeactiveStyle);

  useEffect(() => {
    if (fields[activeKey]) {
      setActiveField(fields[activeKey]);
    } else {
      setActiveKey(activeKey - 1);
    }
    setActivePage(fields[activeKey]);
    if (activeKey > 0) updateShownDependencies(fields[activeKey]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.length]);

  const onChange = (targetKey: string) => {
    setActiveKey(parseInt(targetKey));
    setActiveField(fields[parseInt(targetKey)]);
    setDisplay('none');
    setConfirmationDisplay(false);
    setStyle(DeactiveStyle);
    updateShownDependencies(fields[parseInt(targetKey)]);
    setActivePage(fields[parseInt(targetKey)]);
  };

  const onEdit = (targetKey: any, action: any) => {
    switch (action) {
      case 'add':
        add();
        break;
      case 'remove':
        popConfirm(parseInt(targetKey));
        break;
    }
  };

  const add = () => {
    setActiveKey(fields.length);
    setDisplay('none');
    setConfirmationDisplay(false);
    setStyle(DeactiveStyle);
    dropField(PageConfig, fields.length, undefined);
    setDependenciesMode(false);
    // setActivePage(fields[targetKey]);
    // setActiveField(fields[targetKey])
  };

  const popConfirm = (targetKey: number) => {
    Modal.confirm({
      title:
        'When the page is deleted, all components contained in it are also deleted.',
      icon: <ExclamationCircleOutlined />,
      cancelText: 'Cancel',
      okText: 'Delete',
      okButtonProps: {
        style: {
          background: '#f4f4f4 0% 0% no-repeat padding-box',
          borderColor: '',
          color: 'rgba(0, 0, 0, 0.65)',
        },
      },
      onOk() {
        // if picture is been uploaded and component is deleted
        // then this will delete picture file from backend
        if (fields[targetKey].title === 'Page') {
          if (fields[targetKey].elements) {
            fields[targetKey].elements?.forEach((item: any) => {
              removePictureFromBackend(item);
            });
          }
        }
        remove(targetKey);
      },
      onCancel() {
        /* This is intentional */
      },
    });
  };

  const remove = (targetKey: number) => {
    removeField(fields[targetKey].id);

    if (fields[targetKey + 2] && targetKey === activeKey) {
      setActiveKey(targetKey);
    } else if (fields[targetKey - 1] && targetKey === activeKey) {
      setActiveKey(targetKey - 1);
    } else {
      if (
        targetKey !== activeKey &&
        activeKey !== 0 &&
        !fields[activeKey + 2]
      ) {
        setActiveKey(activeKey - 1);
      } else {
        setActiveKey(activeKey);
      }
    }
  };

  const renderTabBar = (props: any, DefaultTabBar: any) => {
    return (
      <DefaultTabBar {...props}>
        {(node: any) => (
          <DraggableTab id={node.key}>
            <DroppapleTab
              id={node.key}
              moveTab={moveTab}
              setActiveKey={setActiveKey}
            >
              {node}
            </DroppapleTab>
          </DraggableTab>
        )}
      </DefaultTabBar>
    );
  };

  return (
    <div style={{ height: 300, width: '100%', position: 'relative' }}>
      <DndProvider backend={HTML5Backend}>
        <div className="tab-list-formbuilder">
          <Tabs
            type="editable-card"
            onChange={onChange}
            activeKey={activeKey.toString()}
            onEdit={onEdit}
            className={dependenciesMode ? 'tabs_dependenciesMode' : 'tabs'}
            renderTabBar={renderTabBar}
          >
            {fields.map((pane, i) => (
              <TabPane
                tab={
                  pane.propConfig.find((obj) => obj.key === 'label')?.value ||
                  i + 1
                }
                key={i}
                closable={fields.length > 1}
              >
                <TabContent tabKey={i} />
              </TabPane>
            ))}
          </Tabs>
        </div>
        <div
          className="tab-list-confirmationpage"
          style={{
            display: display,
          }}
        >
          {/* <Card>
            <TabContent tabKey={fields.length - 2}></TabContent>
          </Card> */}
        </div>
      </DndProvider>
    </div>
  );
};

const DraggableTab: React.FC<{ id: string }> = ({ id, children }) => {
  const type = 'TAB';
  const [, drag] = useDrag({
    item: { id, type },
    type: type,
  });
  return <div ref={drag}>{children}</div>;
};

const DroppapleTab: React.FC<{
  id: string;
  moveTab: (dragKey: number, hoverKey: number) => void;
  setActiveKey: (id: number) => void;
}> = ({ id, moveTab, children, setActiveKey }) => {
  const [, drop] = useDrop({
    accept: 'TAB',
    drop(item: DragItemProps, monitor) {
      moveTab(parseInt(item.id), parseInt(id));
      setActiveKey(parseInt(id));
    },
  });
  return <div ref={drop}>{children}</div>;
};
