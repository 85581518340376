import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Layout, Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import UserService from './api/auth/UserService';
import { getProjects, postProject } from './api/project';
import './App.css';
import CanvasContextProvider from './canvasContext';
import FormCanvas from './components/formCanvas';
import FormDashboard, { ProjectItemProps } from './components/formDashboard';
import PageHeader from './components/pageHeader';
import SiderRight from './components/rightSider/siderRight';
import SiderLeft from './components/siderLeft';
import DependenciesModeContextProvider from './core/dependenicesModeContext';
import { AsbStrings } from './core/Internalization/AsbStrings';
import { IStrings } from './core/IStrings';
import { LanguageContext } from './core/localization/languageContext';
import { useExitPrompt } from './customHooks/useExitPrompt';
import { ReactComponent as GermanFlag } from './imgs/SVG_ASB__Deutsch.svg';
import { ReactComponent as EnglishFlag } from './imgs/SVG_ASB__Englisch.svg';
import './styles/antdDesign.css';
import './styles/Header.css';

const { Header, Content, Sider, Footer } = Layout;

export interface ActiveConfig {
  name?: string;
  readonly?: boolean;
}

function App() {
  const [projectList, setProjectList] = useState<ProjectItemProps[]>([]);
  const { setLanguageByCountryCode } = useContext(LanguageContext);
  const isLoggedIn = UserService.isLoggedIn();
  const [selectedProjectName, setSelectedProjectName] = useState<
    string | number
  >();

  const [activeConfig, setActiveConfig] = useState<ActiveConfig | undefined>(
    undefined
  );

  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);

  useEffect(() => {
    return () => {
      window.addEventListener('beforeunload', function (e) {
        e.preventDefault();
        setShowExitPrompt(!setShowExitPrompt);
      });
    };
  });

  useEffect(() => {
    return () => setShowExitPrompt(false);
  }, []);

  function getProjectList() {
    if (isLoggedIn) {
      getProjects()
        .then(function (data) {
          if (data.name !== 'error') {
            setProjectList(data);
          } else {
            Modal.confirm({
              title: IStrings.error_Database_unavailable,
              icon: <ExclamationCircleOutlined />,
              cancelText: IStrings.Cancel,
              okText: IStrings.Reload,
              style: {
                width: '100%',
                margin: '0 auto',
              },
              okButtonProps: {
                style: {
                  background: '#f4f4f4 0% 0% no-repeat padding-box',
                  borderColor: '',
                  color: 'rgba(0, 0, 0, 0.65)',
                },
              },
              onOk() {
                window.location.reload();
              },
              onCancel() {
                // Empty Function
              },
            });
          }
        })
        .catch((error) => {
          console.error('FETCH ERROR:', error);
        });
    }
  }

  useEffect(() => {
    setLanguageByCountryCode('en');
    getProjectList();
  }, [isLoggedIn]);

  async function addProject(item: ProjectItemProps): Promise<boolean> {
    let isUnique = true;
    projectList?.forEach(function (entry: ProjectItemProps) {
      if (entry.name === item.name) {
        isUnique = false;
      }
    });
    if (!isUnique) {
      return false;
    }
    await postProject(item.name, item.status, item.description);
    getProjectList();
    return true;
  }

  return (
    <Layout
      style={{ minHeight: '100vh', backgroundColor: 'rgb(244, 244, 244)' }}
    >
      {!activeConfig?.name && (
        <>
          <Header className="header" style={{ color: 'white' }}>
            {IStrings.Choose_Project}
            <div style={{ float: 'right' }}>
              <Button
                style={{ marginLeft: '4px', marginRight: '4px' }}
                type="text"
                icon={<GermanFlag />}
                onClick={() => setLanguageByCountryCode('de')}
              />
              <Button
                style={{ marginLeft: '4px', marginRight: '4px' }}
                type="text"
                icon={<EnglishFlag />}
                onClick={() => setLanguageByCountryCode('en')}
              />
              {isLoggedIn ? (
                <Button
                  style={{ marginLeft: '4px', marginRight: '4px' }}
                  type="primary"
                  onClick={() => UserService.doLogout()}
                >
                  {IStrings.LogOut}
                </Button>
              ) : (
                <Button
                  style={{ marginLeft: '4px', marginRight: '4px' }}
                  type="primary"
                  onClick={() => UserService.doLogin()}
                >
                  {IStrings.LogIn}
                </Button>
              )}
            </div>
          </Header>
          <Layout>
            <Sider
              data-testid="sider-right-sider"
              style={{
                minHeight: '100vh',
                width: '100%',
                paddingBottom: '40px',
                marginBottom: '50px',
                overflowY: 'auto',
                height: 'calc(100Vh - 4.5em)',
                background: '#f4f4f4 0% 0% no-repeat padding-box',
                boxShadow: '3px 0px 0px #00000029',
                opacity: '1',
                overflowX: 'hidden',
              }}
              width={'100vw'}
            >
              {isLoggedIn && (
                <FormDashboard
                  selectedProjectName={selectedProjectName}
                  setActiveConfig={setActiveConfig}
                  setSelectedProjectName={setSelectedProjectName}
                  addProject={addProject}
                  getProjectList={getProjectList}
                  projectList={projectList}
                />
              )}
            </Sider>
          </Layout>
          <Footer />
        </>
      )}
      {activeConfig?.name && (
        <DependenciesModeContextProvider>
          <CanvasContextProvider>
            <PageHeader
              activeConfig={activeConfig}
              selectedProjectName={selectedProjectName}
              setActiveConfig={setActiveConfig}
            />
            <Layout style={{ backgroundColor: 'rgb(244, 244, 244)' }}>
              <SiderLeft></SiderLeft>
              <Layout
                style={{
                  backgroundColor: 'rgb(244, 244, 244)',
                  overflowY: 'auto',
                  overflowX: 'auto',
                }}
              >
                <Content
                  style={{
                    background: '#f4f4f4 0% 0% no-repeat padding-box',
                  }}
                >
                  <FormCanvas></FormCanvas>
                </Content>
              </Layout>
              <SiderRight></SiderRight>
            </Layout>
          </CanvasContextProvider>
        </DependenciesModeContextProvider>
      )}
    </Layout>
  );
}

export default App;
