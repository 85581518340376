import * as React from 'react';
import { ConditionalComponentContainer } from '../../../services/auth/conditionalComponentContainer';
import { ConfigDeleteButton } from '../../../components/dashboard/tableButtons/configDeleteButton';
import { ConfigDuplicateButton } from '../../../components/dashboard/tableButtons/configDuplicateButton';
import { ConfigEditButton } from '../../../components/dashboard/tableButtons/configEditButton';
import { ProjectConfiguration } from '../../../components/dashboard/configurationTable/configurationTable';
import { ClxDownloadButton } from './ClxDownloadButton';

const renderActivateButton = (
  projectConfig: ProjectConfiguration,
  projectName: string | number | undefined,
  fetchProjectConfigurations: any
): JSX.Element => {
  return <></>;
};

const renderEditButtons = (
  projectConfig: ProjectConfiguration,
  projectName: string | number | undefined,
  fetchProjectConfigurations: any,
  setActiveConfig: any
): JSX.Element[] => {
  return [
    <ConditionalComponentContainer
      identifier="configEditButton"
      configState={projectConfig.release_status}
    >
      <ConfigEditButton
        setActiveConfig={setActiveConfig}
        projectConfig={projectConfig}
        projectName={projectName}
      />
    </ConditionalComponentContainer>,
    <ConditionalComponentContainer
      identifier="configDuplicateButton"
      configState={projectConfig.release_status}
    >
      <ConfigDuplicateButton
        projectConfig={projectConfig}
        fetchProjectConfigurations={fetchProjectConfigurations}
        projectName={projectName}
      />
    </ConditionalComponentContainer>,
  ];
};

const renderApproveButtons = (
  projectConfig: ProjectConfiguration,
  projectName: string | number | undefined,
  fetchProjectConfigurations: any
): JSX.Element[] => {
  return [
    <ConditionalComponentContainer
      identifier="configEditButton"
      configState={projectConfig.release_status}
    >
      <ClxDownloadButton
        projectConfig={projectConfig}
        projectName={projectName}
      />
    </ConditionalComponentContainer>,
  ];
};

function renderDeleteButton(
  projectConfig: ProjectConfiguration,
  projectName: string | number | undefined,
  fetchProjectConfigurations: any
): JSX.Element[] {
  return [
    <ConditionalComponentContainer
      identifier="configDeleteButton"
      configState={projectConfig.release_status}
      alwaysHide={projectConfig.active}
    >
      <ConfigDeleteButton
        projectName={projectName}
        projectConfig={projectConfig}
        fetchProjectConfigurations={fetchProjectConfigurations}
      />
    </ConditionalComponentContainer>,
  ];
}

export const ActionButtons = {
  renderActivateButton: renderActivateButton,
  renderEditButtons: renderEditButtons,
  renderApproveButtons: renderApproveButtons,
  renderDeleteButton: renderDeleteButton,
};
