import { Button } from 'antd';
import React from 'react';

interface TableButtonProps {
  label: string;
  icon: React.RefAttributes<any>;
  onClick: () => void;
  danger?: boolean;
  ghost?: boolean;
  type?:
    | 'link'
    | 'text'
    | 'ghost'
    | 'primary'
    | 'default'
    | 'dashed'
    | undefined;
}

export const TableButton: React.FC<TableButtonProps> = ({
  label,
  icon,
  onClick,
  danger,
  ghost,
  type,
}) => {
  return (
    <Button
      ghost={ghost}
      danger={danger}
      type={type}
      onClick={() => onClick()}
      icon={icon}
      style={{marginLeft: '1em'}}
    >
      {label}
    </Button>
  );
};
