import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Input, message, notification, Row, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../../../styles/ComponentPropertyEditor.css';
import { ReactSortable } from 'react-sortablejs';
import { DropdownInputItem, DropdownListElement } from '../util/DropdownUtil';
import OptionCard from './OptionCard';
import { IStrings } from '../../../core/IStrings';
import { processData, readCSV } from '../../utils/parseCsv';

interface BasicDropdownEditorProps {
  label: string;
  dataKey: string;
  id: string | undefined;
  valueList: { values: DropdownListElement[]; setValues: any };
  options?: object;
  handleChange?: (key: string, value: string) => void;
  onChange?: (key: string, value: DropdownListElement[]) => void;
  disabled?: boolean;
}

export const BasicDropdownEditor: FunctionComponent<BasicDropdownEditorProps> = ({
  label,
  valueList,
  handleChange,
  onChange,
  dataKey,
  options,
  id,
  disabled = false,
}) => {
  const { values, setValues } = valueList;

  const [item, setItem] = useState<DropdownInputItem>({
    textInput: '',
    valueInput: '',
    infoInput: '',
  });
  const [activeItem, setActiveItem]: any = useState<DropdownInputItem>();

  useEffect(() => {
    if (onChange) onChange(dataKey, values);
    else handleChange?.(dataKey, JSON.stringify(values));
  }, [values]);

  useEffect(() => {
    setValues(values ? values : []);
  }, [id]);

  useEffect(() => {
    if (activeItem) {
      setItem({
        textInput: activeItem.label,
        valueInput: activeItem.key,
        infoInput: activeItem.info,
      });
    }
  }, [activeItem]);

  function elemExists(
    item: DropdownInputItem,
    values: DropdownListElement[]
  ): boolean {
    let label = item.textInput.trim();
    let key = item.valueInput.trim();
    if (item.valueInput === '') key = label;
    else if (item.textInput === '') label = key;
    return values.some(
      (elem) => key === elem.key.trim() && label === elem.label.trim()
    );
  }

  function addToList(label: string, value: string, info?: string) {
    setValues((prevState) => [
      ...prevState,
      {
        key: value ? value : label,
        label: label,
        text: label,
        info: info,
      },
    ]);
  }

  const addOnClick = () => {
    if (activeItem) {
      const arr = [...values];
      const updatedItem = arr[activeItem.index];
      updatedItem.key = item.valueInput.trim() ?? item.textInput.trim();
      updatedItem.label = item.textInput.trim();
      updatedItem.text = item.textInput.trim();
      updatedItem.info = item.infoInput.trim();
      setValues(arr);
    } else {
      if (elemExists(item, values)) {
        message.error(IStrings.error_uniqueValue);
        return;
      }
      addToList(
        item.textInput.trim(),
        item.valueInput.trim(),
        item.infoInput.trim()
      );
    }
    resetActiveItem();
  };

  const removeOnClick = (index: number) => {
    resetActiveItem();
    const item = values[index];
    setValues(values.filter((entry) => entry !== item));
  };

  const handleClickListItem = (el, i) => {
    if (activeItem && activeItem.index === i) {
      resetActiveItem();
    } else setActiveItem({ ...el, index: i });
  };

  const resetActiveItem = () => {
    setItem({ textInput: '', valueInput: '', infoInput: '' });
    setActiveItem(undefined);
  };

  const onDragEnd = (updatedList) => {
    setValues(updatedList);
  };

  const processCSV = (file) => {
    readCSV(file)
      .then((dataStr) => {
        const csvResult = processData(dataStr);
        const error = !Object.values(csvResult).every(
          (entry: any) => entry.label !== undefined
        );

        if (!error) {
          const entries: any[] = [];
          csvResult.forEach((entry: any) => {
            entries.push({
              key: entry.value ? entry.value : entry.label,
              label: entry.label,
              text: entry.label,
              value: entry.value ? entry.value : entry.label,
              info: entry.info && options ? entry.info : '',
            });
          });
          setValues((prevState) => [...prevState, ...entries]);
        } else {
          error &&
            notification.error({
              key: 'parseCSVError',
              message: 'CSV Upload ' + IStrings.failed,
              description: IStrings.error_parseCSV,
              duration: 4,
            });
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <label className="radioEditor">{label}</label>
      <br />
      {values.length !== 0 && (
        <ReactSortable
          list={values}
          onStart={() => resetActiveItem()}
          setList={onDragEnd}
          disabled={disabled}
          style={{ marginBottom: 0 }}
        >
          {values.map((el: DropdownListElement, i: number) => (
            <OptionCard
              index={i}
              element={el}
              handleClick={handleClickListItem}
              handleDelete={removeOnClick}
              activeItem={activeItem}
              draggable={true}
            />
          ))}
        </ReactSortable>
      )}
      <Row>
        <Col span={24}>
          <Input
            placeholder={IStrings.Option_Label}
            value={item.textInput}
            disabled={disabled}
            onPressEnter={() => addOnClick()}
            onChange={(e: any) => {
              setItem({ ...item, textInput: e.target.value });
            }}
            data-testid={'stringEditor' + label}
          />
        </Col>
        <Col span={24}>
          <Input
            placeholder={IStrings.Option_Value}
            value={item.valueInput}
            disabled={disabled}
            onPressEnter={() => addOnClick()}
            onChange={(e: any) => {
              setItem({ ...item, valueInput: e.target.value });
            }}
            data-testid={'stringEditor' + values}
          />
        </Col>
        {options && (
          <Col span={24}>
            <Input
              placeholder={IStrings.Option_Info}
              value={item.infoInput}
              disabled={disabled}
              onChange={(e: any) => {
                setItem({ ...item, infoInput: e.target.value });
              }}
              data-testid={'stringEditor' + values}
            />
          </Col>
        )}
      </Row>
      <Button
        className="deleteButton"
        disabled={disabled || !item.textInput}
        onClick={() => addOnClick()}
      >
        <span className="deleteButtonSpan">
          {activeItem ? IStrings.Save : IStrings.Add}
        </span>
      </Button>

      <Upload
        multiple={false}
        accept={'.csv'}
        showUploadList={false}
        beforeUpload={(file) => {
          processCSV(file);
          return false;
        }}
      >
        <Button
          className="deleteButton"
          disabled={disabled}
          icon={<UploadOutlined />}
        >
          <span className="deleteButtonSpan">{IStrings.UploadCSV}</span>
        </Button>
      </Upload>
    </>
  );
};

export default BasicDropdownEditor;
