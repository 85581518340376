import React, { FunctionComponent, useContext } from 'react';
import { Input, Tooltip } from 'antd';

import '../../../styles/ComponentPropertyEditor.css';
import { IStrings } from '../../../core/IStrings';
import { CanvasContext } from '../../../canvasContext';

interface NumberEditorProps {
  label: string;
  placeholder: string;
  value: number;
  dataKey: string;
  disabled: boolean;
  maxLength?: number;
}

export const NumberEditor: FunctionComponent<NumberEditorProps> = ({
  label,
  value,
  dataKey,
  placeholder,
  disabled,
  maxLength,
}) => {
  const { setPropOfActiveField } = useContext(CanvasContext);

  return (
    <>
      <label className="stringEditor">{label}</label>
      <Input
        placeholder={IStrings[placeholder]}
        type={'number'}
        value={isNaN(value) ? '' : value}
        disabled={disabled}
        onChange={(e: any) => {
          setPropOfActiveField(dataKey, parseInt(e.target.value));
        }}
        data-testid={'radioEditorInputOne' + label}
        // maxLength={30}
      />
    </>
  );
};

export default NumberEditor;
