import React, { FunctionComponent } from 'react';
import { NumericalInputEditor } from './validationEditors/numericalInputEditor';

interface MinmaxValidationProps {
  value: number;
  validationKey: string;
  disabled?: boolean;
}

export const MinmaxValidation: FunctionComponent<MinmaxValidationProps> = ({
  value,
  validationKey,
  disabled,
}) => {
  return (
    <NumericalInputEditor
      disabled={disabled}
      value={value}
      validationKey={validationKey}
    />
  );
};
