import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  WarningOutlined,
} from '@ant-design/icons';
import { Input, Tooltip } from 'antd';
import React, { FunctionComponent } from 'react';
import { DragItemProps } from '../../../core/IDragItem';
import { IStrings } from '../../../core/IStrings';
import '../../../styles/ComponentPropertyEditor.css';

interface StringEditorProps {
  label: string;
  placeholder: string;
  value?: string;
  handleChange: (key: string, value: string) => void;
  dataKey: string;
  disabled: boolean;
  editorWarningText?: string;
  editorInfoText?: string;
  fields?: DragItemProps[];
  maxLength?: number;
  toolTip?: boolean;
}

export const StringEditor: FunctionComponent<StringEditorProps> = ({
  label,
  value,
  handleChange,
  dataKey,
  placeholder,
  disabled,
  editorWarningText,
  editorInfoText,
  maxLength,
  fields,
  toolTip = false,
}) => {
  return (
    <>
      <label className="stringEditor">{label}</label>
      {dataKey !== 'senderPassword' ? (
        <Input
          placeholder={IStrings[placeholder]}
          value={value}
          disabled={disabled}
          onChange={(e: any) => {
            handleChange(dataKey, e.target.value);
          }}
          suffix={
            editorWarningText && (
              <Tooltip placement="topRight" title={IStrings[editorWarningText]}>
                <WarningOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
            )
          }
          data-testid={'stringEditor' + label}
          // maxLength={maxLength}
        />
      ) : (
        <Input.Password
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={(e: any) => {
            handleChange(dataKey, e.target.value);
          }}
          data-testid={'stringEditor' + label}
          // maxLength={maxLength}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      )}
    </>
  );
};

export default StringEditor;
