import React from 'react';
import { useDrag } from 'react-dnd';
import { DependencyProps } from './IDependency';
import { ItemTypes } from './itemTypes';

export interface DependenciesDraggableWrapperProps {}

const DependenciesDraggableWrapper: React.FC<DependencyProps> = ({
  dependencyId,
  dependencyName,
  description,
  superiorId,
  dependentId,
  operator,
  threshold,
  positiveEffect,
  negativeEffect,
  positiveEffectValue,
  negativeEffectValue,
  children,
}) => {
  const type = ItemTypes.DEPENDENCY;
  const [, drag] = useDrag({
    item: {
      dependencyId,
      type,
      dependencyName,
      description,
      superiorId,
      dependentId,
      operator,
      threshold,
      positiveEffect,
      negativeEffect,
      positiveEffectValue,
      negativeEffectValue,
    },
    type: type,
  });

  return (
    <div
      style={{
        width: '32px',
        height: '32px',
        color: 'black',
        position: 'absolute',
        zIndex: 10000,
      }}
      data-testid={dependencyId}
      ref={drag}
    >
      {children}
    </div>
  );
};

export default DependenciesDraggableWrapper;
