import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect } from 'react';
import { IStrings } from '../../../core/IStrings';
import { loadFields } from '../../../manageContextFields';
import {
  checkConditions,
  ConditionalComponentContainer,
} from '../../../services/auth/conditionalComponentContainer';
import { ConfigurationState } from '../constants/configStatus';
import { ConfigAddButton } from './configAddButton';
import moment from 'moment';
import { ActiveConfig } from '../../../App';
import getEditorForTenant from '../../../tenant';

const { CS_NEW, CS_EDIT, CS_REVIEW, CS_APPROVED, CS_REJECTED } =
  ConfigurationState;

interface ConfigurationTableProps {
  projectName?: string | number;
  setActiveConfig: (item: ActiveConfig | undefined) => void;
  projectConfigurations: ProjectConfiguration[];
  fetchProjectConfigurations: () => void;
}

export interface ProjectConfiguration {
  id: number;
  name: string;
  active: boolean;
  release_status: ConfigurationState;
  creation_date: string;
  last_edit?: string;
  editor?: string;
  approver?: string;
  approveDate?;
  releaser?: string;
  releaseDate?;
  project: {
    name: string;
  };
}

export const ConfigurationTable: React.FC<ConfigurationTableProps> = ({
  setActiveConfig,
  projectName,
  projectConfigurations,
  fetchProjectConfigurations,
}) => {
  const ActionButtonsRenderer = getEditorForTenant().getActionButtons;
  const renderActivateButton = ActionButtonsRenderer.renderActivateButton;
  const renderEditButtons = ActionButtonsRenderer.renderEditButtons;
  const renderApproveButtons = ActionButtonsRenderer.renderApproveButtons;
  const renderDeleteButton = ActionButtonsRenderer.renderDeleteButton;

  let columns: ColumnsType<ProjectConfiguration> = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      sorter: {
        compare: (a: { id: number }, b: { id: number }) =>
          b.id > a.id ? 1 : b.id < a.id ? -1 : 0,
      },
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (name) => String(name),
      sorter: {
        compare: (a: { name: string }, b: { name: string }) =>
          !a.name ? -1 : !b.name ? 1 : a.name?.localeCompare(b.name),
      },
    },
    {
      title: IStrings.ReleaseStatus,
      key: 'release_status',
      dataIndex: 'release_status',
      render: (release_status) =>
        IStrings[
          Object.keys(ConfigurationState)[
            Object.values(ConfigurationState).indexOf(release_status)
          ]
        ] ?? release_status,
      sorter: {
        compare: (
          a: { release_status: ConfigurationState },
          b: { release_status: ConfigurationState }
        ) => a.release_status?.localeCompare(b.release_status),
      },
      filters: [
        { text: IStrings.CS_NEW, value: CS_NEW },
        { text: IStrings.CS_EDIT, value: CS_EDIT },
        { text: IStrings.CS_REVIEW, value: CS_REVIEW },
        { text: IStrings.CS_APPROVED, value: CS_APPROVED },
        { text: IStrings.CS_REJECTED, value: CS_REJECTED },
      ],
      onFilter: (value, record: ProjectConfiguration) =>
        record.release_status === value,
    },
    {
      title: IStrings.Created,
      key: 'creation_date',
      dataIndex: 'creation_date',
      render: (creation_date) =>
        creation_date
          ? new Date(creation_date).toLocaleString(IStrings.getLanguage())
          : IStrings.Unknown,
      sorter: {
        compare: (
          a: { creation_date: moment.MomentInput },
          b: { creation_date: moment.MomentInput }
        ) => moment(a.creation_date).diff(moment(b.creation_date)),
      },
    },
    {
      title: IStrings.LastEdit,
      key: 'last_edit',
      dataIndex: 'last_edit',
      render: (last_edit) =>
        last_edit
          ? new Date(last_edit).toLocaleString(IStrings.getLanguage())
          : IStrings.Unknown,
      sorter: {
        compare: (
          a: { last_edit?: moment.MomentInput },
          b: { last_edit?: moment.MomentInput }
        ) =>
          !a.last_edit
            ? -1
            : !b.last_edit
            ? 1
            : moment(a.last_edit).diff(moment(b.last_edit)),
      },
    },
  ];

  if (checkConditions('configActivateButton'))
    columns.push({
      title: IStrings.Activation,
      key: 'active',
      render: (projectConfig: ProjectConfiguration) =>
        renderActivateButton(
          projectConfig,
          projectName,
          fetchProjectConfigurations
        ),
      sorter: {
        compare: (a: { active: boolean }, b: { active: boolean }) =>
          a.active === b.active ? 0 : a.active ? -1 : 1,
      },
    });

  if (
    checkConditions('configEditButton') ||
    checkConditions('configDuplicateButton')
  )
    columns.push({
      title: IStrings.Actions,
      key: 'edit',
      render: (projectConfig: ProjectConfiguration) =>
        renderEditButtons(
          projectConfig,
          projectName,
          fetchProjectConfigurations,
          setActiveConfig
        ),
    });

  if (
    checkConditions('configApproveButton') ||
    checkConditions('configRejectButton') ||
    checkConditions('configExportButton')
  )
    columns.push({
      title: IStrings.ReleaseActions,
      key: 'approve',
      render: (projectConfig: ProjectConfiguration) =>
        renderApproveButtons(
          projectConfig,
          projectName,
          fetchProjectConfigurations
        ),
    });

  if (checkConditions('configDeleteButton'))
    columns.push({
      title: IStrings.Delete,
      key: 'delete',
      render: (projectConfig: ProjectConfiguration) =>
        renderDeleteButton(
          projectConfig,
          projectName,
          fetchProjectConfigurations
        ),
    });

  useEffect(() => {
    projectName && fetchProjectConfigurations();
  }, [projectName]);

  return (
    <>
      <Table
        title={() => projectName ?? 'No Project selected'}
        footer={() => (
          <ConditionalComponentContainer identifier="configAddButton">
            <ConfigAddButton
              fetchProjectConfigurations={fetchProjectConfigurations}
              projectName={projectName}
              editorConfig={loadFields()}
            />
          </ConditionalComponentContainer>
        )}
        dataSource={projectConfigurations}
        columns={columns}
        sortDirections={['ascend', 'descend', 'ascend']}
        locale={{ triggerAsc: '', triggerDesc: '' }}
      />
    </>
  );
};
