import { message, notification } from 'antd';
import { DragItemProps } from '../../core/IDragItem';
import { IStrings } from '../../core/IStrings';

export function fieldsAreUnique(fields, key: string) {
  let allItems: DragItemProps[] = getAllItems(fields);
  let itemNames: string[] = getFieldValuesForKey(allItems, key);
  let uniqueSet: string[] = Array.from(new Set(itemNames));

  return itemNames.length === uniqueSet.length;
}

export function getAllItems(fields) {
  let result: DragItemProps[] = [];
  getAllItemsRecursiv(fields, result);
  return result;
}

function getAllItemsRecursiv(elements, collectedItems) {
  elements.forEach((element) => {
    if (element.type === 'item') {
      collectedItems.push(element);
    }
    if (element.elements && element.elements?.length > 0) {
      getAllItemsRecursiv(element.elements, collectedItems);
    }
  });
}

export function getFieldValuesForKey(items, key) {
  return items
    .map((item) => item.propConfig)
    .flat()
    .filter((element) => element && element.key === key)
    .map((element) => element?.value);
}

export function findDuplicates(elements) {
  let duplicatedValues: string[] = [];
  for (let i = 0; i < elements.length; i++) {
    for (let j = 0; j < elements.length; j++) {
      if (i !== j) {
        if (
          elements[i] === elements[j] &&
          !duplicatedValues.includes(elements[i])
        ) {
          duplicatedValues.push(elements[i]);
          break;
        }
      }
    }
  }

  return duplicatedValues;
}

export const notificationFieldWasAlreadyUsed = (duplicates, placement) => {
  notification.open({
    key: 'Error',
    type: 'error',
    message: IStrings.error_uniqueName,
    description: getNotificationMessage(duplicates),
    duration: 10,
    placement: placement,
  });
};

function getNotificationMessage(duplicates) {
  return IStrings.error_name_exists_already + ' ' + duplicates.join(', ');
}

/**
 * @param fields
 * @return true if no violation, false if violation found
 */
export function validateConfig(fields: DragItemProps[] | any[]): boolean {
  for (let i = 0; i < fields.length; i++) {
    if (!validateElement(fields[i])) return false;
  }
  return true;
}

/**
 * @param element
 * @return true if no violation, false if violation found
 */
function validateElement(element: DragItemProps): boolean {
  if (element.elements) {
    for (let i = 0; i < element.elements.length; i++) {
      if (!validateElement(element.elements[i])) return false;
    }
    return true;
  }

  // add other validations
  return validateFieldname(element);
}

/**
 *
 * @param element
 * @return true if no violation, false if violation found
 */
function validateFieldname(element: DragItemProps) {
  let fieldnames = element.propConfig.filter((conf) =>
    conf.key.toLowerCase().includes('fieldname')
  );
  // for components with multiple fieldnames
  for (const fieldname of fieldnames) {
    if (fieldname?.value === '') {
      const placeholder = element.propConfig.find((conf) =>
        conf.key.toLowerCase().includes('placeholder')
      );
      let componentName =
        placeholder?.value !== ''
          ? placeholder?.value
          : placeholder?.placeholder;
      message.error(IStrings.error_missing_fieldname + componentName);
      return false;
    }
  }
  return true;
}
