import React from 'react';
import UserService from '../../api/auth/UserService';
import { ConfigurationState } from '../../components/dashboard/constants/configStatus';
import {
  Condition,
  ConditionsConfig,
  CONDITIONSCONFIG,
} from '../../components/dashboard/constants/conditionsConfig';
import { INSTANCE } from '../../core/constants/editorInstance';

function checkConditionsPure(
  identifier: string,
  conditionsConfig: ConditionsConfig,
  instance: string,
  hasRole: (roles: string[]) => boolean,
  configState?: ConfigurationState,
  alwaysFalse?: boolean,
): boolean {
  if (alwaysFalse) return false;
  const componentRules: Condition = conditionsConfig[identifier];
  if (!componentRules) throw new Error(`Identifier '${identifier}' not found in conditionsConfig!`);
  if (!componentRules.allowedInstances.includes(instance)) return false;
  if (componentRules.allowedRoles && !hasRole(componentRules.allowedRoles)) return false;
  if (configState && !componentRules.allowedConfigurationStates?.includes(configState)) return false;
  return true;
}

export function checkConditions(identifier: string, configState?: ConfigurationState, alwaysFalse?: boolean) {
  return checkConditionsPure(identifier, CONDITIONSCONFIG, INSTANCE, UserService.hasAtLeastOneOfRoles, configState, alwaysFalse);
}

interface ConditionalComponentContainerProps {
  identifier: string;
  configState?: ConfigurationState;
  alwaysHide?: boolean;
  children: React.ReactNode;
}

export const ConditionalComponentContainer: React.FC<
  ConditionalComponentContainerProps
> = ({ identifier, configState, alwaysHide, children }) => {
  return checkConditions(identifier, configState, alwaysHide) ? <>{children}</> : null;
};
