import { Checkbox } from 'antd';
import React, { FunctionComponent, useContext } from 'react';
import { CanvasContext } from '../../../canvasContext';
import '../../../styles/ComponentPropertyEditor.css';

interface BooleanEditorProps {
  label: string;
  value?: boolean;
  dataKey: string;
  disabled: boolean;
}

export const BooleanEditor: FunctionComponent<BooleanEditorProps> = ({
  label,
  value,
  dataKey,
  disabled,
}) => {
  const { setPropOfActiveField } = useContext(CanvasContext);
  return (
    <div style={{ marginTop: '0.5em' }}>
      <Checkbox
        checked={value}
        onChange={(e: any) => {
          setPropOfActiveField(dataKey, e.target.checked);
        }}
        data-testid={'booleanEditorPflichtfeld'}
        disabled={disabled}
      />
      <label className="booleanEditor">{label}</label>
    </div>
  );
};
export default BooleanEditor;
