export const processData = (dataString) => {
  const dataStringLines = dataString.split(/\r\n|\n/);
  let headers = dataStringLines[0]
    .toLowerCase()
    .split(
      /;(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)|,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

  const list = [];
  for (
    let contentLine = 1;
    contentLine < dataStringLines.length;
    contentLine++
  ) {
    const row = dataStringLines[contentLine].split(
      /;(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)|,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );
    if (headers && row.length == headers.length) {
      const obj = {};
      for (let index = 0; index < headers.length; index++) {
        let entry = row[index];
        if (entry.length > 0) {
          if (entry[0] == '"') entry = entry.substring(1, entry.length - 1);
          if (entry[entry.length - 1] == '"')
            entry = entry.substring(entry.length - 2, 1);
        }
        if (headers[index]) {
          obj[headers[index]] = entry;
        }
      }

      // remove the blank rows
      if (Object.values(obj).filter((line) => line).length > 0) {
        // @ts-ignore
        list.push(obj);
      }
    }
  }

  return list;
};

export const processRolesCSV = (dataString) => {
  return dataString.split(/\r\n|\n/);
};

export const readCSV = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      if (e && e.target && e.target.result) {
        const str = e.target.result;
        if (typeof str === 'string') {
          resolve(str);
        }
        setTimeout(
          () =>
            reject(
              new Error(
                `Type parse error! Expected string but got ${typeof str} instead!`
              )
            ),
          3000
        );
      }
    };
    reader.readAsText(file);
  });
};
