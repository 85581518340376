import React, { FunctionComponent } from 'react';
import { Input, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import '../../../styles/ComponentPropertyEditor.css';
import { IStrings } from '../../../core/IStrings';
import { nullValueProperties } from './constants/nullValues';

interface NumberEditorProps {
  label: string;
  placeholder: string;
  value?: string;
  handleChange: (key: string, value: string) => void;
  dataKey: string;
  disabled: boolean;
  maxLength?: number;
  toolTip?: boolean;
}

export const NumberEditor: FunctionComponent<NumberEditorProps> = ({
  label,
  value,
  handleChange,
  dataKey,
  placeholder,
  disabled,
  maxLength,
  toolTip,
}) => {
  let newValue = value ? value : '';
  let ToolTipText = toolTip ? 'To change the value, Please contact admin' : '';

  const onChange = (e: any) => {
    const specialCharRegex = new RegExp('^\\d+$');
    if (e && specialCharRegex.test(e)) {
      handleChange(dataKey, e.toString());
    } else {
      handleChange(dataKey, '');
    }
  };

  //Validierung Default Null Wert
  const onBlur = () => {
    if (newValue === '') {
      handleChange(dataKey, nullValueProperties[dataKey]);
    }
  };

  return (
    <>
      <label className="stringEditor">{label}</label>
      <Input
        placeholder={IStrings[placeholder]}
        value={newValue}
        disabled={disabled}
        onChange={(e: any) => {
          onChange(e.target.value);
        }}
        suffix={
          toolTip && (
            <Tooltip placement="topRight" title={ToolTipText}>
              <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          )
        }
        data-testid={'radioEditorInputOne' + label}
        onBlur={() => {
          onBlur();
        }}
        // maxLength={30}
      />
    </>
  );
};

export default NumberEditor;
