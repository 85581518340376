import AlertModal from '../components/alertModal';
import UserService from './auth/UserService';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
function getHeaders() {
  let headers = new Headers();
  headers.append('Authorization', `Bearer ${UserService.getToken()}`);
  headers.append('Content-Type', 'application/json');
  return headers;
}

function showAlertModal(response) {
  if (response.status === 401) {
    AlertModal();
  }
  return response;
}

export default function postConfig(jsonConfig, deployment, projectId) {
  return fetch(
    `${BACKEND_URL}/config/postData?id=${projectId}&deployment=${deployment}`,
    {
      method: 'POST',
      headers: getHeaders(),
      body: jsonConfig,
    }
  )
    .then((response) => showAlertModal(response))
    .catch((error) => {
      console.error(error);
    });
}

export function getProjectListFromBackend() {
  return fetch(`${BACKEND_URL}/project/getProjectList`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(function (response) {
      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        AlertModal();
      }
    })
    .catch(function (err) {
      throw new Error('NETWORK RESPONSE NOT OK');
    });
}

export function addFormInBackend(item) {
  return fetch(
    `${BACKEND_URL}/project/addProject?name=${item.name}&status=${item.status}&description=${item.description}`,
    {
      method: 'GET',
      headers: getHeaders(),
    }
  )
    .then((response) => showAlertModal(response))
    .catch((error) => {
      console.error('FETCH ERROR:', error);
    });
}

export function getConfigFromBackend(project) {
  let URL =
    project === ''
      ? `${BACKEND_URL}/config/getConfig`
      : `${BACKEND_URL}/config/getConfig?id=${project.id}`;
  return fetch(URL, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('NETWORK RESPONSE NOT OK');
      }
    })
    .catch((error) => {
      throw new Error('FETCH ERROR:', error);
    });
}

export function deleteProjectFromBackend(project) {
  return fetch(`${BACKEND_URL}/project/deleteProject?id=${project.id}`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then((response) => showAlertModal(response))
    .catch((error) => {
      throw new Error('FETCH ERROR:', error);
    });
}

export function duplicateProjectInBackend(project) {
  return fetch(
    `${BACKEND_URL}/project/duplicateProject?id=${project.id}&newName=${project.newName}`,
    {
      method: 'GET',
      headers: getHeaders(),
    }
  )
    .then((response) => {
      if (response.status === 401) {
        AlertModal();
      }
      return response;
    })
    .catch((error) => {
      throw new Error('FETCH ERROR:', error);
    });
}

export function removeUploadPicture(name) {
  return fetch(`${BACKEND_URL}/upload/remove?name=${name}`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then((removeResponse) => {
      if (removeResponse.ok) {
        return removeResponse.json();
      } else {
        throw new Error('NETWORK RESPONSE NOT OK');
      }
    })
    .catch((error) => {
      console.error('FETCH ERROR:', error);
    });
}

export function updateProjectStatus(id, status) {
  return fetch(
    `${BACKEND_URL}/project/updateProjectStatus?id=${id}&status=${status}`,
    {
      method: 'GET',
      headers: getHeaders(),
    }
  )
    .then((removeResponse) => {
      if (removeResponse.ok) {
        return removeResponse;
      } else {
        throw new Error('NETWORK RESPONSE NOT OK');
      }
    })
    .catch((error) => {
      console.error('FETCH ERROR:', error);
    });
}
