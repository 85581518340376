import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Button, Col, Input, notification, Row, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../../../styles/ComponentPropertyEditor.css';
import { IStrings } from '../../../core/IStrings';
import { processData, readCSV } from '../../../components/utils/parseCsv';
import OptionCard from '../../../components/propertyEditors/nestedUI/OptionCard';
import { ReactSortable } from 'react-sortablejs';
import { CanvasContext } from '../../../canvasContext';
import React from 'react';

interface InputDropdownEditorProps {
  label: string;
  value?: string;
  handleChange: (key: string, value: string) => void;
  dataKey: string;
  options?: object;
  id: string | undefined;
}

interface DropdownItem {
  textInput: string;
  valueInput: string;
  infoInput: string;
  index?: number;
  key?: string;
  label?: string;
  info?: string;
}

interface DropdownElement {
  key: string;
  label: string;
  value: string;
  info: string;
}

export const InputDropdownEditor: FunctionComponent<
  InputDropdownEditorProps
> = ({ label, value, handleChange, dataKey, options, id }) => {
  const [newValue, setNewValue] = useState(value ? JSON.parse(value) : []);
  const [item, setItem] = useState<DropdownItem>({
    textInput: '',
    valueInput: '',
    infoInput: '',
  });
  const [activeItem, setActiveItem] = useState<DropdownItem | undefined>(
    undefined
  );
  const { activeField } = useContext(CanvasContext);
  const onDragEnd = (updatedList) => {
    setNewValue(updatedList);
  };

  useEffect(() => {
    setItem({
      textInput: '',
      valueInput: '',
      infoInput: '',
    });
  }, [activeField]);

  useEffect(() => {
    //As soon as the value changes = push it to the parent
    handleChange(dataKey, JSON.stringify(newValue));
  }, [newValue]);

  useEffect(() => {
    setNewValue(value ? JSON.parse(value) : []);
  }, [id]);

  function addToList(label, value, info) {
    setNewValue((prevState) => [
      ...prevState,
      {
        key: value ? value : label,
        label: label,
        text: label,
        info: info,
      },
    ]);
  }

  const addOnClick = () => {
    if (activeItem && activeItem.index) {
      const arr = [...newValue];
      const updatedItem = arr[activeItem.index];
      updatedItem.key = item.valueInput.trim() ?? item.textInput.trim();
      updatedItem.label = item.textInput.trim();
      updatedItem.text = item.textInput.trim();
      updatedItem.info = item.infoInput.trim();
      setNewValue(arr);
    } else {
      addToList(
        item.textInput.trim(),
        item.valueInput.trim(),
        item.infoInput.trim()
      );
      resetActiveItem();
    }
  };

  const removeOnClick = (index: any) => {
    resetActiveItem();
    const element = newValue[index];
    setNewValue(newValue.filter((entry) => entry !== element));
  };

  const handleClickListItem = (el, i) => {
    if (activeItem && activeItem.index === i) {
      resetActiveItem();
    } else setActiveItem({ ...el, index: i });
  };

  const resetActiveItem = () => {
    setItem({ textInput: '', valueInput: '', infoInput: '' });
    setActiveItem(undefined);
  };

  useEffect(() => {
    if (activeItem && activeItem.label && activeItem.key && activeItem.info) {
      setItem({
        textInput: activeItem.label,
        valueInput: activeItem.key,
        infoInput: activeItem.info,
      });
    }
  }, [activeItem]);

  const processCSV = (file) => {
    readCSV(file)
      .then((dataStr) => {
        const csvResult = processData(dataStr);
        const error = !Object.values(csvResult).every(
          (entry: any) => entry.label !== undefined
        );

        if (!error) {
          csvResult.forEach((entry: any) => {
            addToList(
              entry.label,
              entry.value ? entry.value : entry.label,
              entry.info && options ? entry.info : ''
            );
          });
        } else {
          error &&
            notification.error({
              key: 'parseCSVError',
              message: 'CSV Upload ' + IStrings.failed,
              description: IStrings.error_parseCSV,
              duration: 4,
            });
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <label className="radioEditor">{label}</label>
      <br />
      {newValue.length !== 0 && (
        <ReactSortable
          list={newValue}
          onStart={() => resetActiveItem()}
          setList={onDragEnd}
          style={{ marginBottom: 0 }}
        >
          {newValue.map((el: DropdownElement, i: number) => (
            <OptionCard
              index={i}
              element={el}
              handleClick={handleClickListItem}
              handleDelete={removeOnClick}
              activeItem={activeItem}
              draggable={true}
            />
          ))}
        </ReactSortable>
      )}
      <Row>
        <Col span={24}>
          <Input
            placeholder={IStrings.Option_Label}
            value={item.textInput}
            onChange={(e: any) => {
              setItem({ ...item, textInput: e.target.value });
            }}
            data-testid={'stringEditor' + label}
          />
        </Col>
        <Col span={24}>
          <Input
            placeholder={IStrings.Option_Value}
            value={item.valueInput}
            onChange={(e: any) => {
              setItem({ ...item, valueInput: e.target.value });
            }}
            data-testid={'stringEditor' + value}
          />
        </Col>
        {options && (
          <Col span={24}>
            <Input
              placeholder={IStrings.Option_Info}
              value={item.infoInput}
              onChange={(e: any) => {
                setItem({ ...item, infoInput: e.target.value });
              }}
              data-testid={'stringEditor' + value}
            />
          </Col>
        )}
      </Row>
      <Button
        className="deleteButton"
        disabled={!item.textInput}
        onClick={() => addOnClick()}
      >
        <span className="deleteButtonSpan">
          {activeItem ? IStrings.Save : IStrings.Add}
        </span>
      </Button>

      <Upload
        multiple={false}
        accept={'.csv'}
        showUploadList={false}
        beforeUpload={(file) => {
          processCSV(file);
          return false;
        }}
      >
        <Button className="deleteButton" icon={<UploadOutlined />}>
          <span className="deleteButtonSpan">{IStrings.UploadCSV}</span>
        </Button>
      </Upload>
    </>
  );
};

export default InputDropdownEditor;
