import { DragItemProps } from './core/IDragItem';
import { ItemTypes } from './core/itemTypes';

export const PageConfig: DragItemProps = {
  title: 'Page',
  internalName: 'Page',
  type: 'page',
  id: 'undefined',
  itemType: ItemTypes.PAGE,
  componentTypes: ['layout'],
  icon: 'icons-light.svg',
  propConfig: [
    {
      key: 'label',
      label: 'Placeholder',
      placeholder: 'Pagename',
      editorType: 'String',
      value: '',
    },
    {
      key: 'description',
      label: 'Description',
      placeholder: '',
      editorType: 'String',
      value: '',
    },
    {
      key: 'shownInSummary',
      label: 'Page_content_is_shown_in_summary',
      placeholder: '',
      editorType: 'Boolean',
      value: 'true',
    },
  ],
  refComponent: 'components/@material/page',
};
