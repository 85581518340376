import { PlusOutlined, TableOutlined } from '@ant-design/icons';
import { Checkbox, Input, Button, message } from 'antd';
import { appendFile } from 'fs';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import {
  addRoleListToBackend,
  addRoleToBackend,
  getRolesFromBackend,
} from '../../../api/clx';
import { RolesUpdate } from '../../../core/constants/events';
import { DragItemProps } from '../../../core/IDragItem';
import { IStrings } from '../../../core/IStrings';
import '../../../styles/ComponentPropertyEditor.css';
import {
  processData,
  processRolesCSV,
  readCSV,
} from '../../../components/utils/parseCsv';

interface RoleEditorProps {
  label: string;
  value?: [string];
  handleChange: (key: string, value: string) => void;
  dataKey: string;
  disabled: boolean;
  editorWarningText?: string;
  editorInfoText?: string;
  fields?: DragItemProps[];
  maxLength?: number;
  toolTip?: boolean;
}

export const RoleEditor: FunctionComponent<RoleEditorProps> = ({
  label,
  value,
  handleChange,
  dataKey,
  disabled,
  editorWarningText,
  editorInfoText,
  maxLength,
  fields,
  toolTip = false,
}) => {
  const [roleOptions, setRoleOptions] = useState<Array<any>>();
  const [rolesChecked, setRolesChecked] = useState<Array<any>>();
  const [roleInput, setRoleInput] = useState<
    string | number | readonly string[] | undefined
  >();
  const [file, setFile] = useState<File>();

  useEffect(() => {
    //Get Options
    updateBackendData();
    return () => {
      window.removeEventListener(RolesUpdate, (event) => {
        updateBackendData();
      });
    };
  }, []);

  useEffect(() => {
    //Set CheckedOptions
    setRolesChecked(value);
  }, [value]);

  useEffect(() => {
    if (file !== undefined) {
      readCSV(file).then((result) => {
        addRoleListToBackend(processRolesCSV(result))
          .then((response) => {
            response
              .json()
              .then((json) => {
                message.success(IStrings['Add_Options_Success_Multiple']);
                dispatchEvent(new Event(RolesUpdate));
              })
              .catch((error) => {
                message.error(IStrings['Add_Options_Failure']);
              });
          })
          .catch((error) => {
            message.error(IStrings['Add_Options_Failure']);
          });
      });
    }
  }, [file]);

  window.addEventListener(RolesUpdate, (event) => {
    updateBackendData();
  });

  const fileUploadRef = useRef<any>();

  const triggerFileUpload = (event) => {
    if (fileUploadRef !== undefined) {
      fileUploadRef.current.click();
    }
  };

  const handleCheckboxGroup = (checkedValue) => {
    handleChange(dataKey, checkedValue);
  };

  const handleFileUpload = (event) => {
    const fileUploaded = event.target.files[0];
    setFile(fileUploaded);
  };

  const handleAddRole = () => {
    addRoleToBackend(roleInput)
      .then((response) => {
        response.json().then((json) => {
          message.success(json[0].name + IStrings['Add_Options_Success']);
          setRoleInput('');
          dispatchEvent(new Event(RolesUpdate));
        });
      })
      .catch((error) => {
        message.error(IStrings['Add_Options_Failure']);
      });
  };

  const updateBackendData = () => {
    getRolesFromBackend().then((response) => {
      response.json().then((json) => {
        setRoleOptions(
          json.map((entry) => {
            return { label: entry.name, value: entry.name };
          })
        );
      });
    });
  };

  return (
    <>
      <div>
        <label className="stringEditor">{label}</label>
      </div>
      <div
        style={{
          height: 150,
          overflow: 'scroll',
          marginTop: 15,
          marginBottom: 15,
        }}
      >
        <Checkbox.Group
          value={rolesChecked}
          options={roleOptions}
          onChange={(checkedValue) => handleCheckboxGroup(checkedValue)}
          className="roleCheckbox"
        />
      </div>
      <div>
        <Input
          value={roleInput}
          className="roleInput"
          disabled={disabled}
          onChange={(event) => setRoleInput(event.target.value)}
        />
        <Button
          className="listButton"
          onClick={() => handleAddRole()}
          icon={
            <PlusOutlined
              style={{ margin: '0px', position: 'relative', top: '-1px' }}
            />
          }
        />
        <input
          type={'file'}
          accept={'.csv'}
          ref={fileUploadRef}
          onChange={(event) => handleFileUpload(event)}
          style={{ display: 'none' }}
        />
        <Button
          className="listButton"
          onClick={(event) => triggerFileUpload(event)}
          icon={
            <TableOutlined
              style={{ margin: '0px', position: 'relative', top: '-1px' }}
            />
          }
        />
      </div>
    </>
  );
};

export default RoleEditor;
