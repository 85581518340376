import React, {
  createContext,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { getItemByIDRecursive } from './components/utils/dataHelper';
import { DependenciesModeContext } from './core/dependenicesModeContext';
import { DependencyProps } from './core/IDependency';
import { DragItemProps } from './core/IDragItem';
import { ItemTypes } from './core/itemTypes';
import {
  loadActiveField,
  loadFields,
  saveActiveField,
} from './manageContextFields';
import {
  fieldsAreUnique,
  findDuplicates,
  getAllItems,
  getFieldValuesForKey,
  notificationFieldWasAlreadyUsed,
} from './services/fieldsValidation/fieldsValidation';
import useDependencyValidation from './services/inputValidation/useDependencyValidation';
import useInputValidation from './services/inputValidation/useInputValidation';

// Syngenio colors
const COLORS = [
  '#689F38',
  '#8BC34A',
  '#AED581',
  '#64a600',
  '#388E3C',
  '#4CAF50',
  '#81C784',
  '#33691E',
  '#64a500',
  '#365900',
  '#dcedc8',
];

//Crealogix colors
// const COLORS = [
//   '#184964',
//   '#688195',
//   '#8c9faf',
//   '#0f4c6e',
//   '#1e5b7d',
//   '#20475c',
//   '#32527b',
//   '#275571',
//   '#044f67',
//   '#184964',
// ];

export const CanvasContext = createContext<{
  activeField: DragItemProps | undefined;
  setPropOfActiveField: (key: string, value: any) => void;
  setValidationPropOfActiveField: (
    validationKey: string,
    propertyValue: any,
    validationProperty: string
  ) => void;
  setActiveField: (field: DragItemProps) => void;
  activePage: DragItemProps | undefined;
  setActivePage: (field: DragItemProps) => void;
  fields: DragItemProps[];
  containsItemWithFieldName: (fieldName: string) => boolean;
  dropField: (
    newField: DragItemProps,
    index: number,
    parentId: string | undefined,
    createNewRow?: boolean
  ) => void;
  removeField: (id: string) => void;
  // dependencies: DependencyProps[] | undefined;
  dependenciesOfActivePage: DependencyProps[] | undefined;
  // itemsOfActivePage: DragItemProps[] | undefined;
  getAllElementsOfItemTypeFromPage: (
    page: DragItemProps | undefined,
    type: ItemTypes
  ) => DragItemProps[];
  dropDependency: (
    superior: DragItemProps,
    item: DependencyProps,
    itemId: string
  ) => void;
  deleteDependencyByID: (dependencyId: string) => void;
  editDependency: (
    dependencyId: string,
    superiorId: string,
    fieldName: string,
    value: string
  ) => void;
  moveTab: (dragKey: number, hoverKey: number) => void;
  confirmationDisplay: boolean;
  setConfirmationDisplay: (key: boolean) => void;
  addDependencyToField: (fieldId, dependentId) => void;
  updateShownDependencies: (page: DragItemProps) => void;
  getDependencyColor: (dependencyId: string) => string;
  validatePropertyOfActiveField: (key: string, value: any) => void;
  setValidationErrorOfActiveField: (key: string, status: boolean) => void;
}>({
  activeField: undefined,
  setPropOfActiveField: () => {},
  setValidationPropOfActiveField: () => {},
  setActiveField: () => {},
  activePage: undefined,
  setActivePage: () => {},
  fields: [],
  dropField: () => {},
  removeField: () => {},
  moveTab: () => {},
  dependenciesOfActivePage: [],
  getAllElementsOfItemTypeFromPage: () => [],
  dropDependency: () => {},
  deleteDependencyByID: () => {},
  editDependency: () => {},
  confirmationDisplay: false,
  setConfirmationDisplay: () => {},
  addDependencyToField: () => {},
  updateShownDependencies: () => {},
  getDependencyColor: (string) => string,
  containsItemWithFieldName: () => false,
  validatePropertyOfActiveField: () => {},
  setValidationErrorOfActiveField: () => {},
});

const CanvasContextProvider: FunctionComponent<any> = ({ children }) => {
  return (
    <CanvasContext.Provider value={useFields()}>
      {children}
    </CanvasContext.Provider>
  );
};

const useFields = () => {
  const [fields, setFields] = useState<DragItemProps[]>(loadFields());
  const [activeField, setActiveField] = useState<DragItemProps | undefined>(
    loadActiveField()
  );
  const [activePage, setActivePage] = useState<DragItemProps | undefined>(
    undefined
  );
  const [dependenciesOfActivePage, setDependenciesOfActivePage] = useState<
    DependencyProps[] | undefined
  >(activePage?.elements && getDependencies(activePage?.elements));
  const [confirmationDisplay, setConfirmationDisplay] =
    useState<boolean>(false);

  const [
    validatePropertyOfFieldOnBlur,
    setValidationErrorOfField,
    validatePropertyOfFieldOnChange,
  ] = useInputValidation({
    fields,
    setFields,
  });
  const [setDependencyErrorOfField, validateDependeciesOfFieldOnChange] =
    useDependencyValidation({
      fields,
      setFields,
    });

  const { setActiveDependency } = useContext(DependenciesModeContext);

  const clickField = (field: DragItemProps) => {
    setActiveField(field);
    saveActiveField(field);
  };

  const setPropOfActiveField = (key: string, value: string) => {
    if (activeField !== undefined) {
      let newFields = [...fields]; // only copies first layer, references to nested elements stay the same
      let fieldToEdit = getItemByIDRecursive(activeField.id, newFields);
      let prop = fieldToEdit?.propConfig.find((obj) => {
        return obj.key === key;
      });
      let oldValue: string | null = null;
      if (fieldToEdit !== null && prop !== undefined) {
        oldValue = prop.value;
        prop.value = value;
        setActiveField(fieldToEdit);
      }
      setFields(newFields);

      if (
        prop &&
        prop.key === 'fieldName' &&
        !fieldsAreUnique(newFields, 'fieldName')
      ) {
        let items: DragItemProps[] = getAllItems(newFields);
        let itemNames = getFieldValuesForKey(items, 'fieldName');
        notificationFieldWasAlreadyUsed(findDuplicates(itemNames), 'center');
      }
      if (oldValue) {
        validateDependeciesOfFieldOnChange(oldValue);
      }
      if (value) {
        validatePropertyOfFieldOnChange(key, value, activeField.id);
        validateDependeciesOfFieldOnChange(value);
      }
    }
  };

  const setValidationPropOfActiveField = (
    validationKey: string,
    propertyValue: any,
    validationProperty: string
  ): void => {
    if (activeField) {
      let newFields = [...fields]; // only copies first layer, references to nested elements stay the same
      let fieldToEdit = getItemByIDRecursive(activeField.id, newFields);
      if (fieldToEdit) {
        fieldToEdit.validationConfig = fieldToEdit?.validationConfig?.map(
          (item) => {
            if (item.key === validationKey)
              return { ...item, [validationProperty]: propertyValue };
            else return item;
          }
        );
        setActiveField(fieldToEdit);
        setFields(newFields);
      }
    }
  };

  const dropField = (
    newField: DragItemProps,
    index: number,
    parentId: string | undefined,
    createNewRow?: boolean
  ) => {
    let newFields = [...fields];
    if (newField.id === 'undefined' || newField.id === undefined) {
      if (createNewRow === true && newField.itemType === ItemTypes.ITEM) {
        addRow(newField, index, parentId, newFields);
      } else {
        addField(newField, index, parentId, newFields);
      }
    } else {
      if (isDropValid(newField.id, parentId, newFields)) {
        moveField(newField, index, parentId, newFields, createNewRow);
      }
    }
    setFields(newFields);

    if (!fieldsAreUnique(newFields, 'fieldName')) {
      let items: DragItemProps[] = getAllItems(newFields);
      let itemNames = getFieldValuesForKey(items, 'fieldName');
      notificationFieldWasAlreadyUsed(findDuplicates(itemNames), 'center');
    }
  };

  const addField = (
    newField: DragItemProps,
    index: number,
    parentId: string | undefined,
    newFields: DragItemProps[]
  ) => {
    //stringify and parse to create a real copy without references (otherwise propconfig is just a reference)
    if (newField.id === undefined || newField.id === 'undefined') {
      newField = {
        ...JSON.parse(JSON.stringify(newField)),
        id: '' + Date.now() + 1, // Date.now() +1 for avoiding same id when creating row and item at the same time
      };
    } else {
      newField = {
        ...JSON.parse(JSON.stringify(newField)),
      };
    }

    let children = getChildrenOfParent(parentId, newFields);
    children.splice(index, 0, newField);
    setActiveField(newField);
    saveActiveField(newField);
  };

  const addRow = (
    newField: DragItemProps,
    index: number,
    parentId: string | undefined,
    newFields: DragItemProps[]
  ) => {
    const rowId = '' + Date.now();
    const row: DragItemProps = {
      type: 'row',
      title: 'Row',
      internalName: 'ROW',
      itemType: ItemTypes.ROW,
      propConfig: [],
      id: rowId,
      refComponent: 'refComp',
      componentTypes: ['layout'],
    };

    const newRow = { ...JSON.parse(JSON.stringify(row)), id: rowId };
    let children = getChildrenOfParent(parentId, newFields);
    children.splice(index, 0, newRow);

    addField(newField, index, rowId, newFields);
  };

  const moveField = (
    newField: DragItemProps,
    index: number,
    parentId: string | undefined,
    newFields: DragItemProps[],
    createNewRow?: boolean
  ) => {
    let calledInsideMoveFunction = true;
    let parent = getChildrenToRemoveItem(newField.id, newFields);
    if (parent == null)
      throw new Error('Parent is null: No item was removed error');
    let parentIndex = parent.findIndex((item) => item.id === newField.id);

    if (parentIndex !== -1) {
      newField = { ...parent[parentIndex] };
      parent[parentIndex].id += '_MarkToRemove';

      createNewRow && newField.itemType !== ItemTypes.ROW
        ? addRow(newField, index, parentId, newFields)
        : addField(newField, index, parentId, newFields);

      parentIndex = parent.findIndex(
        (item) => item.id === newField.id + '_MarkToRemove'
      );

      removeField(parent[parentIndex].id, calledInsideMoveFunction);
    }
  };

  const removeField = (id: string, calledInsideMoveFunction?: boolean) => {
    let newFields = [...fields];
    let parent = getParentOfField(id, newFields);
    let fieldToRemove = getItemByIDRecursive(id, newFields);
    let fieldName = fieldToRemove?.propConfig.find((obj) => {
      return obj.key === 'fieldName';
    })?.value;
    if (
      calledInsideMoveFunction == undefined ||
      calledInsideMoveFunction !== true
    ) {
      parent?.elements?.forEach(function (element, i) {
        if (element.id === id && parent?.elements) {
          if (parent?.elements[i + 1]) {
            setActiveField(parent.elements[i + 1]);
          } else if (parent.elements[i - 1]) {
            setActiveField(parent.elements[i - 1]);
          } else {
            setActiveField(parent);
          }
        }
      });
    }

    let found = getChildrenToRemoveItem(id, newFields);
    if (found == null) throw new Error('No item was removed error');

    let index = found.findIndex((item) => item.id === id);

    if (
      parent?.itemType === ItemTypes.ROW &&
      parent.elements !== undefined &&
      parent.elements.length === 1
    ) {
      removeField(parent.id, calledInsideMoveFunction); //removes an empty row
    } else {
      if (index !== -1) found.splice(index, 1);
    }

    if (fields === newFields) throw new Error('No item was removed error');
    setFields(newFields);
    if (fieldName) {
      validatePropertyOfFieldOnChange(fieldName, fieldName, id);
    }
    activePage && updateShownDependencies(activePage);
  };

  const getChildrenOfParent = (
    parentId: string | undefined,
    children: DragItemProps[]
  ): DragItemProps[] => {
    let result = children;

    if (parentId !== undefined) {
      let parent = getItemByIDRecursive(parentId, children);
      if (parent !== null && parent.elements === undefined) {
        parent.elements = [];
      }
      if (parent?.elements !== undefined) {
        result = parent.elements;
      }
    }
    return result;
  };

  const getParentOfField = (
    fieldId: string,
    fields: DragItemProps[] | undefined,
    parent?: DragItemProps
  ): DragItemProps | undefined => {
    if (fields === undefined) {
      return undefined;
    }
    let found = fields.findIndex((item) => item.id === fieldId);

    if (found !== -1) {
      return parent;
    }

    let result;
    for (let i = 0; result == null && i < fields.length; i++) {
      parent = fields[i];

      result = getParentOfField(fieldId, fields[i].elements, parent);
    }
    return result;
  };

  const isDropValid = (
    id: string, //ich dragge id
    parentId: string | undefined, //und droppe id in parentId
    children: DragItemProps[] | undefined
  ): boolean => {
    if (parentId === undefined) {
      return true;
    }
    if (children === undefined) {
      return false;
    }

    let result = false;
    for (let i = 0; result === false && i < children.length; i++) {
      if (children[i].id !== id) {
        if (children[i].id === parentId) {
          result = true;
        } else {
          result = isDropValid(id, parentId, children[i].elements);
        }
      }
    }
    return result;
  };

  const containsItemWithFieldName = (fieldName: string) => {
    let items: DragItemProps[] = getAllItems(fields);
    let itemNames = getFieldValuesForKey(items, 'fieldName');
    return itemNames.includes(fieldName);
  };

  const getChildrenToRemoveItem = (
    id: string,
    children: DragItemProps[] | undefined
  ): DragItemProps[] | null => {
    if (children === undefined) {
      return null;
    }

    let found = children.findIndex((item) => item.id === id);

    if (found !== -1) {
      return children;
    }

    let result;
    for (let i = 0; result == null && i < children.length; i++) {
      result = getChildrenToRemoveItem(id, children[i].elements);
    }
    return result;
  };

  const moveTab = (dragKey: number, hoverKey: number) => {
    let newFields = [...fields];
    newFields.splice(dragKey, 1);
    newFields.splice(hoverKey, 0, fields[dragKey]);
    setFields(newFields);
    setActivePage(fields[dragKey]);
    setActiveField(fields[dragKey]);
    updateShownDependencies(fields[dragKey]);
  };

  function getAllElementsOfItemTypeFromPage(
    page: DragItemProps | undefined,
    type: ItemTypes
  ) {
    let filteredElements =
      page?.elements && filterByItemType(page.elements, type);
    return filteredElements ? filteredElements : [];
  }

  function filterByItemType(
    elements: DragItemProps[],
    type: ItemTypes
  ): DragItemProps[] {
    let filteredElements: any = [];
    elements.forEach((element) => {
      if (element.itemType === type) {
        filteredElements.push(element);
      }
      if (element.elements) {
        let nestedElements = filterByItemType(element.elements, type);
        nestedElements.forEach((nestedEle) => filteredElements.push(nestedEle));
      }
    });
    return filteredElements;
  }

  //TODO clean function
  const dropDependency = (
    superior: DragItemProps,
    item: DependencyProps,
    dependentId: string
  ) => {
    if (activeField !== undefined) {
      addDependencyToField(superior.id, dependentId);
    }
  };

  const setDependencyColor = (count: number) => {
    if (count > 9) {
      count = count - 10;
    }
    let color = COLORS[count];
    return color;
  };

  const getDependencyColor = (dependencyId: string): string => {
    let color;
    //get dependency by Id from dependencies
    if (dependenciesOfActivePage !== undefined) {
      let theDependency = dependenciesOfActivePage.find(
        (dependency) => dependency.dependencyId === dependencyId
      );
      color = theDependency?.dependencyColor;
    }

    return color;
  };

  const addDependencyToField = (superiorId: string, dependentId: string) => {
    let newFields = [...fields];

    let superiorName = getItemByIDRecursive(
      superiorId,
      newFields
    )?.propConfig?.find((conf) => conf.key === 'fieldName')?.value;
    let dependentName = getItemByIDRecursive(
      dependentId,
      newFields
    )?.propConfig?.find((conf) => conf.key === 'fieldName')?.value;

    // TODO remove hard coded dependency
    let dependency: DependencyProps = {
      dependencyId: Date.now() + '',
      type: ItemTypes.DEPENDENCY,
      dependencyName: '',
      superiorId: superiorId ? superiorId : '',
      superiorName: superiorName ? superiorName : '',
      dependentId: dependentId,
      dependentName: dependentName ? dependentName : '',
      operator: '',
      threshold: '',
      dependencyColor: setDependencyColor(
        dependenciesOfActivePage?.length
          ? dependenciesOfActivePage?.length + 1
          : 0
      ),
    };
    if (activeField !== undefined) {
      let fieldToEdit = getItemByIDRecursive(activeField.id, newFields);
      let dependencies = fieldToEdit?.dependencies
        ? fieldToEdit?.dependencies
        : [];
      dependencies?.push(dependency);

      if (fieldToEdit !== null) {
        fieldToEdit.dependencies = dependencies;
        setActiveField(fieldToEdit);
      }
    }
    //update dependencies menu
    activePage && updateShownDependencies(activePage);
    setActiveDependency(dependency);

    // setDependenciesOfActivePage(foundDependencies);
  };

  const editDependency = (
    dependencyId: string,
    superiorId: string,
    fieldName: string,
    value: string
  ) => {
    // get fields
    let newFields = [...fields];
    if (fieldName === 'superiorId') {
      changeDependencySuperior(newFields, dependencyId, superiorId, value);
    } else {
      let fieldToEdit = getItemByIDRecursive(superiorId, newFields);
      //get dependency by Id from dependencies
      let dependencyToEdit = fieldToEdit?.dependencies?.find(
        (dependency) => dependency.dependencyId === dependencyId
      );
      if (dependencyToEdit !== undefined) {
        dependencyToEdit[fieldName] = value;
        if (fieldName === 'dependentId')
          dependencyToEdit['dependentName'] = getItemByIDRecursive(
            dependencyToEdit['dependentId'],
            newFields
          )?.propConfig?.find((conf) => conf.key === 'fieldName')?.value;
      }
    }
    setFields(newFields);
    activePage && updateShownDependencies(activePage);
  };

  const deleteDependencyByID = (dependencyId: string) => {
    // get fields
    let newFields = [...fields];
    //get dependency by Id from dependencies
    if (dependenciesOfActivePage !== undefined) {
      let dependencyToRemove = dependenciesOfActivePage.find(
        (dependency) => dependency.dependencyId === dependencyId
      );

      //get dependencies of field of dependency
      let fieldOfDependency;
      if (dependencyToRemove !== undefined) {
        fieldOfDependency = getItemByIDRecursive(
          dependencyToRemove.superiorId,
          newFields
        );
      }
      let fieldDependencies = [...fieldOfDependency.dependencies];
      //splice dependencies
      let dependencyIndex;
      if (dependencyToRemove !== undefined) {
        dependencyIndex = fieldDependencies.findIndex(
          (dependency) => dependency.dependencyId === dependencyId
        );
      }
      fieldDependencies.splice(dependencyIndex, 1);

      //save dependencies in field
      if (dependencyToRemove !== undefined) {
        updateDependenciesOfField(
          dependencyToRemove.superiorId,
          fieldDependencies
        );
      }
    }
    if (activePage?.elements !== undefined) {
      updateShownDependencies(activePage);
    }
  };

  const changeDependencySuperior = (
    newFields: DragItemProps[],
    dependencyId: string,
    oldSuperiorId: string,
    newSuperiorId: string
  ) => {
    let oldFieldToEdit = getItemByIDRecursive(oldSuperiorId, newFields);
    let dependencyToEdit = oldFieldToEdit?.dependencies?.find(
      (dependency) => dependency.dependencyId === dependencyId
    );

    let dependencyToEditClone = JSON.parse(JSON.stringify(dependencyToEdit));
    let newFieldToEdit = getItemByIDRecursive(newSuperiorId, newFields);
    let superiorName = newFieldToEdit?.propConfig?.find(
      (conf) => conf.key === 'fieldName'
    )?.value;

    dependencyToEditClone.superiorId = newSuperiorId;
    dependencyToEditClone.superiorName = superiorName;

    deleteDependencyByID(dependencyId);

    let dependencies = newFieldToEdit?.dependencies
      ? newFieldToEdit?.dependencies
      : [];
    dependencies?.push(dependencyToEditClone);

    if (newFieldToEdit !== null) {
      newFieldToEdit.dependencies = dependencies;
      setActiveField(newFieldToEdit);
    }
  };

  const updateDependenciesOfField = (
    fieldId: string,
    newDependencies: DependencyProps[]
  ) => {
    let newFields = [...fields];

    let fieldToEdit = getItemByIDRecursive(fieldId, newFields);

    if (fieldToEdit !== null) {
      fieldToEdit.dependencies = newDependencies;
    }
  };

  const updateShownDependencies = (page: DragItemProps) => {
    let foundDependencies = page.elements && getDependencies(page.elements);

    setDependenciesOfActivePage(foundDependencies);
  };

  function getDependencies(elements: DragItemProps[]): DependencyProps[] {
    let foundDependencies: DependencyProps[] = [];

    elements.forEach((element) => {
      element.dependencies &&
        element.dependencies.forEach((dependency) => {
          foundDependencies.push(dependency);
        });
      if (element.elements) {
        let nested: DependencyProps[] = getDependencies(element.elements);
        nested.forEach((nestedEle) => foundDependencies.push(nestedEle));
      }
    });
    return foundDependencies;
  }

  const setValidationErrorOfActiveField = (key: string, status: boolean) => {
    if (activeField !== undefined) {
      setValidationErrorOfField(key, status, activeField.id);
    }
  };

  const validatePropertyOfActiveField = (key: string, value: any) => {
    if (activeField !== undefined) {
      validatePropertyOfFieldOnBlur(key, value, activeField.id);
    }
  };

  return {
    fields,
    dropField,
    removeField,
    activeField,
    setActiveField: clickField,
    activePage,
    setActivePage,
    updateShownDependencies,
    setPropOfActiveField,
    setValidationPropOfActiveField,
    moveTab,
    dependenciesOfActivePage,
    getAllElementsOfItemTypeFromPage,
    dropDependency,
    deleteDependencyByID,
    editDependency,
    confirmationDisplay,
    setConfirmationDisplay,
    addDependencyToField,
    getDependencyColor,
    containsItemWithFieldName,
    setValidationErrorOfActiveField,
    validatePropertyOfActiveField,
  };
};

export default CanvasContextProvider;
