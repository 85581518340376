// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
(function () {
  function id(x) {
    return x[0];
  }

  const moo = require('moo');

  const lexer = moo.compile({
    NUMBER: /[0-9]+(?:\.[0-9]+)?/,
    COMPARISON_OPERATOR: /(?:<=|>=|<|>|==|!=)/,
    PLUS: '+',
    MINUS: '-',
    DOT: '.',
    COMMA: ',',
    OPERATOR: /[\+\*\-\/]/,
    STRING: /"[^"]*?"/,
    IDENTIFIER: {
      match: /[a-zA-Z0-9_]+/,
      type: moo.keywords({
        BOOLEAN: ['true', 'false'],
        KW: ['and', 'or', 'not'],
      }),
    },
    LPAREN: '(',
    RPAREN: ')',
    LEFT_SQUARE_PAREN: '[',
    RIGHT_SQUARE_PAREN: ']',
    ws: { match: /\s+/, lineBreaks: true },
  });

  var grammar = {
    Lexer: lexer,
    ParserRules: [
      { name: 'main', symbols: ['logicalExpression'], postprocess: id },
      { name: 'main', symbols: ['calculation'], postprocess: id },
      {
        name: 'logicalExpression',
        symbols: ['comparisonExpression', '_', 'OR', '_', 'logicalExpression'],
        postprocess: (d) => ({
          type: 'expression',
          left: d[0],
          operator: d[2],
          right: d[4],
        }),
      },
      {
        name: 'logicalExpression',
        symbols: ['comparisonExpression', '_', 'AND', '_', 'logicalExpression'],
        postprocess: (d) => ({
          type: 'expression',
          left: d[0],
          operator: d[2],
          right: d[4],
        }),
      },
      {
        name: 'logicalExpression',
        symbols: ['comparisonExpression'],
        postprocess: id,
      },
      {
        name: 'comparisonExpression',
        symbols: [
          'calculation',
          '_',
          { type: 'COMPARISON_OPERATOR' },
          '_',
          'calculation',
        ],
        postprocess: (d) => ({
          type: 'expression',
          left: d[0],
          operator: d[2],
          right: d[4],
        }),
      },
      {
        name: 'comparisonExpression',
        symbols: [
          { literal: '(' },
          '_',
          'logicalExpression',
          '_',
          { literal: ')' },
        ],
        postprocess: (d) => d[2],
      },
      {
        name: 'comparisonExpression',
        symbols: [
          'NOT',
          '_',
          { literal: '(' },
          '_',
          'logicalExpression',
          '_',
          { literal: ')' },
        ],
        postprocess: (d) => ({ type: 'negation', value: d[4] }),
      },
      {
        name: 'calculation',
        symbols: ['calculation', '_', 'PLUS', '_', 'product'],
        postprocess: (d) => ({
          type: 'expression',
          left: d[0],
          operator: d[2],
          right: d[4],
        }),
      },
      {
        name: 'calculation',
        symbols: ['calculation', '_', 'MINUS', '_', 'product'],
        postprocess: (d) => ({
          type: 'expression',
          left: d[0],
          operator: d[2],
          right: d[4],
        }),
      },
      { name: 'calculation', symbols: ['product'], postprocess: id },
      {
        name: 'product',
        symbols: ['value', '_', 'TIMES', '_', 'product'],
        postprocess: (d) => ({
          type: 'expression',
          left: d[0],
          operator: d[2],
          right: d[4],
        }),
      },
      {
        name: 'product',
        symbols: ['value', '_', 'DIV', '_', 'product'],
        postprocess: (d) => ({
          type: 'expression',
          left: d[0],
          operator: d[2],
          right: d[4],
        }),
      },
      { name: 'product', symbols: ['value'], postprocess: id },
      { name: 'value', symbols: ['literal'], postprocess: id },
      { name: 'value', symbols: ['functionExpression'], postprocess: id },
      { name: 'value', symbols: ['fieldAccessor'], postprocess: id },
      {
        name: 'value',
        symbols: [{ literal: '(' }, '_', 'calculation', '_', { literal: ')' }],
        postprocess: (d) => d[2],
      },
      { name: 'fieldAccessor', symbols: ['pathExpression'], postprocess: id },
      {
        name: 'functionExpression',
        symbols: [
          'pathExpression',
          { literal: '(' },
          '_',
          'arguments',
          '_',
          { literal: ')' },
        ],
        postprocess: (d) => ({ type: 'call', name: d[0].value, args: d[3] }),
      },
      {
        name: 'arguments',
        symbols: ['calculation', '_', { type: 'COMMA' }, '_', 'arguments'],
        postprocess: (d) => [d[0], d[4]],
      },
      { name: 'arguments', symbols: ['calculation'], postprocess: id },
      { name: 'arguments', symbols: [] },
      {
        name: 'pathExpression',
        symbols: [{ type: 'IDENTIFIER' }, { type: 'DOT' }, 'pathExpression'],
        postprocess: (d) => ({
          type: 'IDENTIFIER',
          value: d.map(({ value }) => value).join(''),
        }),
      },
      {
        name: 'pathExpression',
        symbols: [
          { type: 'IDENTIFIER' },
          { type: 'LEFT_SQUARE_PAREN' },
          'literal',
          { type: 'RIGHT_SQUARE_PAREN' },
          { type: 'DOT' },
          'pathExpression',
        ],
        postprocess: (d) => ({
          type: 'IDENTIFIER',
          value: d.map(({ value }) => value).join(''),
        }),
      },
      {
        name: 'pathExpression',
        symbols: [
          { type: 'IDENTIFIER' },
          { type: 'LEFT_SQUARE_PAREN' },
          'literal',
          { type: 'RIGHT_SQUARE_PAREN' },
        ],
        postprocess: (d) => ({
          type: 'IDENTIFIER',
          value: d.map(({ value }) => value).join(''),
        }),
      },
      {
        name: 'pathExpression',
        symbols: [{ type: 'IDENTIFIER' }],
        postprocess: id,
      },
      {
        name: 'AND',
        symbols: [{ literal: 'and' }],
        postprocess: (d) => ({ type: 'operator', value: 'and' }),
      },
      {
        name: 'OR',
        symbols: [{ literal: 'or' }],
        postprocess: (d) => ({ type: 'operator', value: 'or' }),
      },
      {
        name: 'NOT',
        symbols: [{ literal: 'not' }],
        postprocess: (d) => ({ type: 'operator', value: 'not' }),
      },
      {
        name: 'PLUS',
        symbols: [{ literal: '+' }],
        postprocess: (d) => ({ type: 'operator', value: '+' }),
      },
      {
        name: 'MINUS',
        symbols: [{ literal: '-' }],
        postprocess: (d) => ({ type: 'operator', value: '-' }),
      },
      {
        name: 'TIMES',
        symbols: [{ literal: '*' }],
        postprocess: (d) => ({ type: 'operator', value: '*' }),
      },
      {
        name: 'DIV',
        symbols: [{ literal: '/' }],
        postprocess: (d) => ({ type: 'operator', value: '/' }),
      },
      {
        name: 'literal',
        symbols: [{ type: 'NUMBER' }],
        postprocess: (d) => ({
          type: 'literal',
          value:
            d[0].value.indexOf('.') === -1
              ? parseInt(d[0].value)
              : parseFloat(d[0].value),
        }),
      },
      {
        name: 'literal',
        symbols: [{ type: 'STRING' }],
        postprocess: (d) => ({
          type: 'literal',
          value: d[0].value.slice(1, -1),
        }),
      },
      {
        name: 'literal',
        symbols: [{ literal: 'true' }],
        postprocess: (d) => ({ type: 'literal', value: d[0].value === 'true' }),
      },
      {
        name: 'literal',
        symbols: [{ literal: 'false' }],
        postprocess: (d) => ({ type: 'literal', value: d[0].value === 'true' }),
      },
      { name: '_', symbols: [] },
      { name: '_', symbols: [{ type: 'ws' }] },
    ],
    ParserStart: 'main',
  };
  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = grammar;
  } else {
    window.grammar = grammar;
  }
})();
