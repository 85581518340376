import { Checkbox, Col, Row } from 'antd';
import React, { FunctionComponent, useContext } from 'react';
import { CanvasContext } from '../../../canvasContext';
import { IStrings } from '../../../core/IStrings';
import '../../../styles/ComponentPropertyEditor.css';

interface CheckboxEditorProps {
  label: string;
  placeholder?: string;
  value?: any;
  dataKey: string;
  disabled: boolean;
  options?: { key: string; value: string }[];
}

export const CheckboxEditor: FunctionComponent<CheckboxEditorProps> = ({
  label,
  value,
  dataKey,
  disabled,
  options,
}) => {
  const { setPropOfActiveField } = useContext(CanvasContext);
  return (
    <>
      <label className="checkboxEditor">{label}</label>
      <Checkbox.Group
        className="check"
        style={{ width: '100%' }}
        onChange={(selectValue: any) => {
          setPropOfActiveField(dataKey, selectValue);
        }}
        value={value}
      >
        <Row>
          {options?.map((option) => (
            <Col key={option.key} span={4}>
              <Checkbox key={option.key} value={IStrings[option.value]}>
                {IStrings[option.value]}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </>
  );
};

export default CheckboxEditor;
