import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import LanguageContextProvider from './core/localization/languageContext';
import UserService from './api/auth/UserService';

const renderApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <DndProvider backend={HTML5Backend}>
        <LanguageContextProvider>
          <App />
        </LanguageContextProvider>
      </DndProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

UserService.initKeycloak(renderApp);
