const KC_ADD = 'add';
const KC_DELETE = 'delete';
const KC_EDIT = 'edit';
const KC_RELEASE = 'release';
const KC_ACTIVATE = 'activate';
const KC_APPROVE = 'approve';
const KC_EXPORT = 'export';
const KC_IMPORT = 'import';
const KC_DUPLICATE = 'duplicate';
const KC_VIEW = 'view';

module.exports = {
  KC_ADD,
  KC_DELETE,
  KC_EDIT,
  KC_RELEASE,
  KC_ACTIVATE,
  KC_APPROVE,
  KC_EXPORT,
  KC_IMPORT,
  KC_DUPLICATE,
  KC_VIEW,
};
