import React, { FunctionComponent, useContext } from 'react';
import Xarrow, { useXarrow } from 'react-xarrows';
import { CanvasContext } from '../canvasContext';
import { DependenciesModeContext } from '../core/dependenicesModeContext';
import { DependencyProps } from '../core/IDependency';

interface DependencyArrowProps {
  dependency: DependencyProps;
  verticalOffset: number;
}

export const DependencyArrow: FunctionComponent<DependencyArrowProps> = ({
  dependency,
}) => {
  const { activeField } = useContext(CanvasContext);
  const { activeDependency, setActiveDependency } = useContext(
    DependenciesModeContext
  );

  //TODO check if browser warning 'Leerer String an getElementById() übergeben.' is created here
  const superior = document.getElementById(dependency.superiorId);
  const dependent = document.getElementById(dependency.dependentId);

  function getOffsetForGridPath(xOffsetBias: number) {
    let offset = 50 + xOffsetBias;
    if (superior && dependent) {
      if (
        superior?.getBoundingClientRect().left <
        dependent?.getBoundingClientRect().left
      ) {
        offset =
          calculateMiddle(
            dependent?.getBoundingClientRect().left,
            superior?.getBoundingClientRect().left
          ) + offset;
        return -offset;
      } else if (
        superior?.getBoundingClientRect().left >
        dependent?.getBoundingClientRect().left
      ) {
        offset =
          calculateMiddle(
            superior?.getBoundingClientRect().left,
            dependent?.getBoundingClientRect().left
          ) + offset;
        return -offset;
      } else {
        return -offset;
      }
    }
    return -offset;
  }

  function calculateMiddle(x1: number, x2: number) {
    return (x1 - x2) / 2;
  }

  function xArrowClicked(e: any) {
    setActiveDependency(dependency);
  }

  function updateDashness(
    activeDependency: DependencyProps,
    dependency: DependencyProps
  ) {
    if (activeDependency.dependencyId === dependency.dependencyId) {
      return { animation: 4 };
    } else {
      return false;
    }
  }

  return (
    <>
      {dependency.dependentId && ( // prevents Xarrow from rendering when dependentId is undefined
        // color={activeField?.id === item.id ? 'green' : 'grey'}
        <Xarrow
          color={dependency.dependencyColor}
          zIndex={
            activeDependency?.dependencyId === dependency.dependencyId
              ? 12000
              : activeField?.id === dependency.superiorId
              ? 11000
              : 10000
          }
          startAnchor={'left'}
          endAnchor={'left'}
          // curveness={0.8}
          path={'grid'}
          dashness={
            activeDependency
              ? updateDashness(activeDependency, dependency)
              : false
          } //animates the movement of the dashes by the given velocity
          headShape={'circle'}
          headSize={4}
          showHead={true}
          tailShape={'circle'}
          tailSize={4}
          showTail={true}
          passProps={{
            onClick: () => xArrowClicked(MouseEvent),
            cursor: 'pointer',
          }}
          strokeWidth={4}
          start={dependency.superiorId}
          end={dependency.dependentId}
          _cpx1Offset={
            activeDependency?.dependencyId === dependency.dependencyId
              ? getOffsetForGridPath(5)
              : getOffsetForGridPath(0)
          } // cpx1 is closer to tail
          _cpx2Offset={
            activeDependency?.dependencyId === dependency.dependencyId
              ? getOffsetForGridPath(5)
              : getOffsetForGridPath(0)
          } // cpx2 is closer to head
          _extendSVGcanvas={20} // needs to extend canvas to show the whole arrow when offset is negative
        ></Xarrow>
      )}
    </>
  );
};

export default DependencyArrow;
