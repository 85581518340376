/* eslint-disable array-callback-return */
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Layout, Modal, notification, Row } from 'antd';
import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { getConfig, patchConfig } from '../api/project';
import { IStrings } from '../core/IStrings';
import { LanguageContext } from '../core/localization/languageContext';
import { ReactComponent as GermanFlag } from '../imgs/SVG_ASB__Deutsch.svg';
import { ReactComponent as EnglishFlag } from '../imgs/SVG_ASB__Englisch.svg';
import logo from '../imgs/synLogo.svg';
import { setBackendFields } from '../manageContextFields';
import {
  getAllItems,
  validateConfig,
} from '../services/fieldsValidation/fieldsValidation';
import { ActiveConfig } from '../App';
import { CanvasContext } from '../canvasContext';

const { Header } = Layout;
const { confirm } = Modal;

interface PageHeaderProps {
  setActiveConfig: (item: ActiveConfig | undefined) => void;
  activeConfig?: ActiveConfig;
  selectedProjectName?: string | number;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  activeConfig,
  setActiveConfig,
  selectedProjectName,
}) => {
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  const { setLanguageByCountryCode } = useContext(LanguageContext);
  const { fields } = useContext(CanvasContext);

  function backToStartPage() {
    setActiveConfig(undefined);
    setBackendFields([]);
  }

  function shwoModalSaveChanges() {
    confirm({
      title: IStrings.warning_unsaved_changes_title,
      icon: <ExclamationCircleOutlined />,
      content: IStrings.warning_unsaved_changes_content,
      onOk() {
        backToStartPage();
      },
      onCancel() {
        /* This is intentional */
      },
    });
  }

  const notificationOpen = function (
    statusCode,
    statusText,
    deployment = false
  ) {
    let message = {};
    let description = '';
    if (statusCode >= 200 && statusCode < 300) {
      message = `${IStrings.Save} ${IStrings.was_successful}`;
    } else {
      message = `${IStrings.Save} ${IStrings.failed}`;
      description = `${statusCode} ${statusText}`;
    }

    notification.open({
      message,
      description,
      duration: 4,
    });
  };

  function saveConfig() {
    setSaveDisabled(true);
    patchConfig(
      selectedProjectName,
      activeConfig?.name,
      JSON.stringify(fields)
    ).then((response) => {
      setSaveDisabled(false);
      notificationOpen(response.status, response.statusText, true);
    });
  }

  return (
    <Header className="header" style={{ color: 'white' }}>
      {
        <Row>
          <Col span={8}>
            <ul
              className="headerUL"
              onClick={() => {
                getConfig(selectedProjectName, activeConfig?.name).then(
                  ({ config }) => {
                    getAllItems(fields).length === 0 ||
                    _.isEqual(fields, config.editor_config)
                      ? backToStartPage()
                      : shwoModalSaveChanges();
                  }
                );
              }}
            >
              <li>
                <img style={{ height: 40 }} alt={'Syngenio'} src={logo} />
              </li>
              {
                <li>
                  <p
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '1em',
                      marginTop: '1.2em',
                      lineHeight: '1em',
                    }}
                  >
                    Application process builder <br />
                    <span style={{ fontSize: '0.8em' }}>
                      A solution of Syngenio AG
                    </span>
                  </p>
                </li>
              }
            </ul>
          </Col>
          <Col span={8}>
            <div style={{ display: 'table', margin: '0 auto' }}>
              <div style={{ float: 'right' }}>
                <Button
                  style={{ marginLeft: '4px', marginRight: '4px' }}
                  type="text"
                  icon={<GermanFlag />}
                  onClick={() => setLanguageByCountryCode('de')}
                />
                <Button
                  style={{ marginLeft: '4px', marginRight: '4px' }}
                  type="text"
                  icon={<EnglishFlag />}
                  onClick={() => setLanguageByCountryCode('en')}
                />
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div style={{ float: 'right' }}>
              {activeConfig?.readonly ? (
                IStrings.SaveNotAvailableInReview
              ) : (
                <Button
                  className={'saveIcon'}
                  disabled={saveDisabled || activeConfig?.readonly}
                  onClick={() => {
                    if (validateConfig(fields)) {
                      saveConfig();
                    }
                  }}
                >
                  {IStrings.Save.toUpperCase()}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      }
    </Header>
  );
};

export default PageHeader;
