import { Layout, Tabs } from 'antd';
import React, { useContext } from 'react';
import { DependenciesModeContext } from '../../core/dependenicesModeContext';
import '../../styles/componentsMenu.css';
import getEditorForTenant from '../../tenant';

const { TabPane } = Tabs;
const { Sider } = Layout;

const SiderRight = () => {
  const { dependenciesMode, setDependenciesMode } = useContext(
    DependenciesModeContext
  );
  const onTabClick = (tabKey: string) => {
    if (tabKey === 'dependencies') {
      setDependenciesMode(true);
    } else {
      setDependenciesMode(false);
    }
  };

  return (
    <Sider
      data-testid="sider-right-sider"
      style={{
        width: '100%',
        paddingBottom: '12em',
        overflowY: 'auto',
        height: '96Vh',
        background: '#f4f4f4 0% 0% no-repeat padding-box',
        boxShadow: '-3px 0px 3px #00000029',
        opacity: '1',
        overflowX: 'hidden',
      }}
      width={'29vw'}
    >
      <Tabs
        data-testid="sider-right-tabs"
        activeKey={dependenciesMode ? 'dependencies' : 'properties'}
        defaultActiveKey="properties"
        onTabClick={onTabClick}
      >
        {getEditorForTenant().getTabPanes()}
      </Tabs>
    </Sider>
  );
};

export default SiderRight;
