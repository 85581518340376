import { List } from 'antd';
import React, { FunctionComponent, useContext } from 'react';
import { CanvasContext } from '../../../../canvasContext';
import { NavButtonObject } from './ButtonEditor';
import ButtonListItem from './ButtonListItem';

interface ButtonListProps {
  label: string;
  dataKey: string;
  buttons: NavButtonObject[];
  resetEditor: () => void;
  setListItem: (button: NavButtonObject) => void;
  setEditIndex: (index: number | undefined) => void;
  editIndex: number | undefined;
}

export const ButtonList: FunctionComponent<ButtonListProps> = ({
  label,
  buttons,
  dataKey,
  resetEditor,
  setListItem,
  setEditIndex,
  editIndex,
}) => {
  const { setPropOfActiveField } = useContext(CanvasContext);

  const loadButtonToEdit = (button: NavButtonObject, index: number) => {
    setListItem(button);
    setEditIndex(index);
  };

  const moveButtonUp = (index: number) => {
    buttons.splice(index - 1, 0, buttons.splice(index, 1)[0]);
    setPropOfActiveField(dataKey, buttons);
    resetEditor();
    setEditIndex(undefined);
  };

  const moveButtonDown = (index: number) => {
    buttons.splice(index + 1, 0, buttons.splice(index, 1)[0]);
    setPropOfActiveField(dataKey, buttons);
    resetEditor();
    setEditIndex(undefined);
  };
  const removeButtonItem = (index: number) => {
    buttons.splice(index, 1);
    setPropOfActiveField(dataKey, buttons);
    resetEditor();
    setEditIndex(undefined);
  };
  return (
    <List
      header={<div>{label}</div>}
      bordered
      dataSource={buttons}
      className="listEditor"
      renderItem={(item, index) => (
        <List.Item
          key={index}
          style={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <ButtonListItem
            isSelected={editIndex === index}
            isLastItem={index === buttons.length - 1 ? true : false}
            buttons={buttons}
            index={index}
            dataKey={dataKey}
            item={item}
            removeButtonItem={removeButtonItem}
            moveButtonDown={moveButtonDown}
            moveButtonUp={moveButtonUp}
            loadButtonToEdit={(item, index) => loadButtonToEdit(item, index)}
          />
        </List.Item>
      )}
    />
  );
};
