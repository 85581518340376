import Keycloak from 'keycloak-js';

// @ts-ignore
const _kc = new Keycloak({
  url:
    process.env.REACT_APP_KEYCLOAK_ENVIRONMENT ??
    'https://forms.syngenio.info/auth/',
  realm: process.env.REACT_APP_KEYCLOAK_REALM ?? 'ASB',
  clientId:
    process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? 'Antragsstreckenbuilder',
});

const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: 'check-sso',
      pkceMethod: 'S256',
    })
    .then((authenticated) => {
      onAuthenticatedCallback();
      refresh();
    })
    .catch(console.error);
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.authenticated;

const updateToken = (successCallback) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasAtLeastOneOfRoles = (roles: string[]): boolean =>
  roles.some((role) => _kc.hasRealmRole(role));

//Checks access token every 60 sec and only gets a new one when its outdated
const refresh = () => {
  setTimeout(() => {
    updateToken(() => {
      refresh();
    }).then(() => '');
  }, 60000);
};

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasAtLeastOneOfRoles,
};

export default UserService;
