import {
  KC_ACTIVATE,
  KC_ADD,
  KC_APPROVE,
  KC_DELETE,
  KC_DUPLICATE,
  KC_EDIT,
  KC_EXPORT,
  KC_IMPORT,
  KC_VIEW,
} from '../../../api/constants/keycloakRoles';
import {DEV, PROD, QA} from '../../../core/constants/editorInstance';
import { ConfigurationState } from './configStatus';

const { CS_NEW, CS_EDIT, CS_REVIEW, CS_APPROVED, CS_REJECTED, CS_PROD } = ConfigurationState;

export interface Condition {
  allowedInstances: string[];
  allowedRoles?: string[];
  allowedConfigurationStates?: ConfigurationState[];
}

export interface ConditionsConfig {
  [key: string]: Condition;
}

export const CONDITIONSCONFIG: ConditionsConfig = {
  projectAddButton: {
    allowedInstances: [DEV, QA, PROD],
    allowedRoles: [KC_ADD],
  },
  configAddButton: {
    allowedInstances: [DEV, QA],
    allowedRoles: [KC_ADD],
  },
  configDeleteButton: {
    allowedInstances: [DEV, QA, PROD],
    allowedRoles: [KC_DELETE],
    allowedConfigurationStates: [CS_NEW, CS_EDIT, CS_REVIEW, CS_APPROVED, CS_REJECTED, CS_PROD],
  },
  configDuplicateButton: {
    allowedInstances: [DEV, QA],
    allowedRoles: [KC_DUPLICATE],
    allowedConfigurationStates: [CS_EDIT, CS_REVIEW, CS_APPROVED, CS_REJECTED, CS_PROD],
  },
  configEditButton: {
    allowedInstances: [DEV, QA],
    allowedRoles: [KC_EDIT],
    allowedConfigurationStates: [CS_NEW, CS_EDIT, CS_REJECTED],
  },
  configViewButton: {
    allowedInstances: [DEV, QA, PROD],
    allowedRoles: [KC_EDIT, KC_VIEW],
    allowedConfigurationStates: [CS_REVIEW, CS_APPROVED],
  },
  configApproveButton: { //Button for Review and Approve
    allowedInstances: [DEV, QA],
    allowedRoles: [KC_APPROVE],
    allowedConfigurationStates: [CS_EDIT, CS_REVIEW],
  },
  configRejectButton: {
    allowedInstances: [DEV, QA],
    allowedRoles: [KC_APPROVE],
    allowedConfigurationStates: [CS_REVIEW],
  },
  configActivateButton: {
    allowedInstances: [DEV, QA, PROD],
    allowedRoles: [KC_ACTIVATE],
    allowedConfigurationStates: [CS_EDIT, CS_REVIEW, CS_APPROVED, CS_REJECTED],
  },
  configExportButton: {
    allowedInstances: [DEV, QA],
    allowedRoles: [KC_EXPORT],
    allowedConfigurationStates: [CS_APPROVED],
  },
  configImportButton: {
    allowedInstances: [DEV, QA, PROD],
    allowedRoles: [KC_IMPORT],
  },

  confirmActivateModal: {
    allowedInstances: [DEV, QA, PROD],
  },
};
