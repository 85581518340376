import * as React from 'react';
import { PropConfig } from '../../../core/IDragItem';
import { IStrings } from '../../../core/IStrings';
import getPropertyEditorForASB from '../../asb/propertyEditors';
import DescriptionEditor from './descriptionEditor';
import ListEditor from './listEditor';
import NumberEditor from './numberEditor';
import RoleEditor from './roleEditor';
import StringEditor from './stringEditor';

export const getPropertyEditor = (
  configEntry: PropConfig,
  contextValue: any
) => {
  switch (configEntry.editorType) {
    case 'Role':
      return (
        <RoleEditor
          value={configEntry.value}
          handleChange={contextValue.setPropOfActiveField}
          dataKey={configEntry.key}
          label={IStrings[configEntry.label]}
          disabled={configEntry.disabled || false}
        />
      );
    case 'List':
      return (
        <ListEditor
          value={configEntry.value}
          handleChange={contextValue.setPropOfActiveField}
          dataKey={configEntry.key}
          label={IStrings[configEntry.label]}
          disabled={configEntry.disabled || false}
        />
      );
    case 'String':
      return (
        <StringEditor
          value={configEntry.value}
          handleChange={contextValue.setPropOfActiveField}
          dataKey={configEntry.key}
          placeholder={configEntry.placeholder}
          label={IStrings[configEntry.label]}
          disabled={configEntry.disabled || false}
        />
      );
    case 'Number':
      return (
        <NumberEditor
          value={configEntry.value}
          handleChange={contextValue.setPropOfActiveField}
          dataKey={configEntry.key}
          placeholder={configEntry.placeholder}
          label={IStrings[configEntry.label]}
          disabled={configEntry.disabled || false}
        />
      );
    case 'Description':
      return (
        <DescriptionEditor
          value={configEntry.value}
          handleChange={contextValue.setPropOfActiveField}
          dataKey={configEntry.key}
          label={IStrings[configEntry.label]}
          placeholder={configEntry.placeholder}
          disabled={configEntry.disabled || false}
          validationError={configEntry.validationError || false}
          dependencyError={configEntry.dependencyError || false}
        />
      );
    default:
      return getPropertyEditorForASB(configEntry, contextValue);
  }
};

export default getPropertyEditor;
