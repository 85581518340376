import {DeleteOutlined, DeleteFilled} from '@ant-design/icons';
import React from 'react';
import { deleteConfig } from '../../../api/project';
import { IStrings } from '../../../core/IStrings';
import { TableButton } from '../tableButton';
import {Modal} from "antd";
import {ProjectConfiguration} from "../configurationTable/configurationTable";

interface ConfigDeleteButtonProps {
  projectName?: string | number;
  projectConfig: ProjectConfiguration;
  fetchProjectConfigurations: () => void;
}

const { confirm } = Modal;

export const ConfigDeleteButton: React.FC<ConfigDeleteButtonProps> = ({
  projectName,
  projectConfig,
  fetchProjectConfigurations,
}) => {
  const deleteButtonFunction = (): void => {
    confirm({
      title: IStrings.ConfirmDeleteConfigTitle + " '" + projectConfig.name + "'",
      content: IStrings.ConfirmDeleteConfigText,
      cancelText: IStrings.Cancel,
      okType: 'danger',
      icon: <DeleteFilled />,
      onOk() {
        deleteConfig(projectName, projectConfig.id)
            .then(() => projectName && fetchProjectConfigurations())
            .catch((e)=>console.error(e));
      },
    });
  }

  return (
    <TableButton
      danger
      label={IStrings.Delete}
      icon={<DeleteOutlined />}
      onClick={deleteButtonFunction}
    />
  );
};
