import React, { FunctionComponent, useContext } from 'react';
import { IStrings } from '../../../../core/IStrings';
import ListInputEditor from './validationEditors/listInputEditor';
import { Checkbox } from 'antd';
import { CanvasContext } from '../../../../canvasContext';
import { VALIDATIONNEGATE } from './constants';

interface EnumValidationProps {
  value: Array<string>;
  validationKey: string;
  negate?: boolean;
  disabled?: boolean;
}

export const EnumValidation: FunctionComponent<EnumValidationProps> = ({
  value,
  validationKey,
  negate,
  disabled,
}) => {
  const { setValidationPropOfActiveField } = useContext(CanvasContext);
  return (
    <>
      <label className="radioEditor">{IStrings[validationKey]}</label>
      <Checkbox
        onChange={(event) => {
          setValidationPropOfActiveField(
            'enum',
            event.target.checked,
            VALIDATIONNEGATE
          );
        }}
        checked={negate}
      >
        {IStrings.Validation_negate}
      </Checkbox>
      <ListInputEditor
        value={value}
        validationKey={validationKey}
        placeholder={IStrings.PermittedValue}
        disabled={disabled}
      />
    </>
  );
};
